//  Hooks
import React, { useContext } from "react";

// Functions
import { callPutApi } from "../../../../../../api/axios";

// Components
import { ToastContext } from "../../../../../../context/toastContext";
import Button from "../../../../../../shared/button/button";

// constants
import { COMPRESSED } from "../../../../../../shared/buttonSize";
import { ERROR } from "../../../../../../utils/toastType";

// Styles
import {
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../../../shared/buttonStyles";
import popUpStyles from "../../../../../../shared/popup.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import { ApmContext } from "../../../../../../utils/apmProvider";

function ApproveKycDetailsModal({
  org_id,
  onApprove,
  onClosePopUp,
  message,
}: any) {
  const [kycButtonLoading, setKycButtonLoading] = React.useState(false);
  const dispatch = useContext(ToastContext);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  async function onApprovingKycDetails() {
    setKycButtonLoading(true);
    try {
      const { data }: any = await callPutApi(
        `/organisations/${org_id}?action=org_activate_account`,
        {}
      );
      setKycButtonLoading(false);
      onApprove(data.virtualAccount);
      onClosePopUp();
    } catch (err: any) {
      setKycButtonLoading(false);
      onClosePopUp();
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div className={`${popUpStyles.popUpContent}`} style={{ width: "500px" }}>
        <div className={`p-4 ${sharedStyle.cardHeaderBack}`}>
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>
            Approve KYC Details
          </p>
        </div>
        <div className="p-4">
          <div className="py-2" style={{ width: "400px", margin: "0 auto" }}>
            <p
              className={`mb-0 ${sharedStyle.tableBodyText}`}
              style={{
                whiteSpace: "pre-wrap",
                textAlign: "center",
                lineHeight: 1.8,
              }}
            >
              {message}
            </p>
          </div>
        </div>
        <div
          className="py-3 my-2 d-flex align-items-center justify-content-center"
          style={{ borderTop: "1px solid #ECEEEF" }}
        >
          <div className="px-2">
            <Button
              id="approve-kyc-details-approve"
              size={COMPRESSED}
              style={successButtonStyle}
              hoveredStyle={successButtonHoverStyle}
              onClick={() => onApprovingKycDetails()}
              isLoading={kycButtonLoading}
              disabled={kycButtonLoading}
            >
              Approve
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApproveKycDetailsModal;
