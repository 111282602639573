import React from "react";
import popUpStyles from "../../../../shared/popup.module.scss";
import Styles from "./reassignKamModal.module.scss";
import Button from "../../../../shared/button/button";
import { MEDIUM } from "../../../../shared/buttonSize";
import WarningIllustration from "../../../../assets/images/warning.png";
import {
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../shared/buttonStyles";

function ReassignKamModal({ onClose, listOfAccounts }: any) {
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div className={`${popUpStyles.popUpContent} ${Styles.card}`}>
        <div className="p-4">
          <div className="py-2 text-center">
            <img src={WarningIllustration} alt="warning" />
          </div>
          <div className="py-3 text-center">
            <p className={`mb-0 ${Styles.headingText}`}>
              Replace Account Managers for Existing Accounts
            </p>
            <div className="py-2">
              <p className={`mb-0 ${Styles.secondaryText}`}>
                Customer accounts linked with selected user(s) to be impacted
              </p>
            </div>
          </div>
          <div
            className="py-2 container"
            style={{
              maxHeight: "250px",
              overflow: "auto",
            }}
          >
            {listOfAccounts.map((account: any) => {
              return (
                <div
                  className="row py-2"
                  key={`${account.name} ${account.count}`}
                >
                  <div className="col-6">
                    <div className="d-flex align-items-center pl-5">
                      <div className="px-1">
                        <div
                          className={`${Styles.initialWrapper} d-flex align-items-center justify-content-center`}
                        >
                          <p className={`mb-0 ${Styles.initial}`}>
                            {account.name.substr(0, 1).toUpperCase()}
                          </p>
                        </div>
                      </div>
                      <div className="px-1">
                        <p className={`mb-0 ${Styles.userName}`}>
                          {account.name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <p className={`mb-0 ${Styles.total_accounts}`}>
                      {account.count} Accounts
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="py-3">
            <p className={`mb-0 ${Styles.warningText}`}>
              Please reassign the account(s) before disabling the current
              user(s)
            </p>
          </div>
        </div>
        <div
          className="py-4 my-2 d-flex align-items-center justify-content-center"
          style={{ borderTop: "1px solid #ECEEEF" }}
        >
          <Button
            id="reassign-kam-close"
            hoveredStyle={primaryButtonHoverStyle}
            style={primaryButtonStyle}
            onClick={() => onClose()}
            size={MEDIUM}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ReassignKamModal;
