export function IdsGenerator({
  prefix,
  id,
  sufix,
}: {
  prefix: string;
  id: string;
  sufix: string;
}) {
  const newPrefix = prefix?.toLowerCase().split(" ").join("-");
  const newId =
    id?.split(" ").length > 1
      ? id
          ?.toLowerCase()
          .split(" ")
          .map((word) => {
            if (!isNaN(Number(word))) {
              return word;
            }

            return word.length > 1 ? word : "";
          })
          .join("-")
      : id?.toLowerCase().split(" ").join("-");

  const newSufix = sufix?.toLowerCase().split(" ").join("-");

  if (newPrefix && newId && newSufix) {
    return `${newPrefix}-${newId}-${newSufix}`;
  } else if (newPrefix && newId) {
    return `${newPrefix}-${newId}`;
  } else {
    return `${newId}`;
  }
}
