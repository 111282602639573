// Hooks
import React, { useCallback, useContext, useEffect } from "react";

// Styles
import Styles from "../billing.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";

// constants
import { ACCENTCOLOR } from "../../../../../../shared/colors";
import { ERROR } from "../../../../../../utils/toastType";
import { orderTypes } from "../../../../../../constants/orderTypes";
import { ApmContext } from "../../../../../../utils/apmProvider";

// Functions
import { callGetApi } from "../../../../../../api/axios";
import { monthName } from "../../../../../../utils/getMonth";

// components
import Loading from "../../../../../../shared/loading/loading";
import { ToastContext } from "../../../../../../context/toastContext";

function PlanHistory({ id, getUpdateAccountInfo }: any) {
  const [PlanHistoryLoding, setPlanHistoryLoding] = React.useState(false);
  const dispatch = useContext(ToastContext);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [PlanHistory, setPlanHistory] = React.useState([]);

  const loading = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );
  const emptyState = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <h5 className="mb-0">No Plan History Found</h5>
    </div>
  );

  const getPlanHistory = useCallback(async () => {
    setPlanHistoryLoding(true);
    try {
      const response: any = await callGetApi(
        `/organisations/${id}?data=plan_subscriptions`
      );
      setPlanHistoryLoding(false);
      const mostRecent = response.data.sort(
        (olderPlanHistory: any, mostRecentPlanHistory: any) => {
          return mostRecentPlanHistory.start_date - olderPlanHistory.start_date;
        }
      );
      setPlanHistory(mostRecent);
    } catch (err: any) {
      setPlanHistoryLoding(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }, [id, dispatch]);

  useEffect(() => {
    getPlanHistory();
  }, [getPlanHistory]);

  return (
    <div className={sharedStyle.card}>
      <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
        <div className="d-flex align-items-center">
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>Plan History</p>
        </div>
      </div>
      {PlanHistoryLoding ? (
        loading
      ) : PlanHistory.length > 0 ? (
        <div style={{ overflowX: "auto" }}>
          <table style={{ width: "100%" }}>
            <thead className={sharedStyle.tableHeaderBack}>
              <tr>
                <th className={Styles.particulars}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Plan name
                  </p>
                </th>
                <th className={Styles.particulars}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Plan code
                  </p>
                </th>
                <th className={Styles.type}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Plan type
                  </p>
                </th>
                <th className={Styles.date}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    Start date
                  </p>
                </th>
                <th className={Styles.date}>
                  <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                    End date
                  </p>
                </th>
              </tr>
            </thead>
            <tbody className={Styles.tableWrapper}>
              {PlanHistory.map((Plan: any, index: number) => {
                const start_date = new Date(Number(Plan.start_date));
                const end_date = new Date(Number(Plan.end_date));
                return (
                  <tr
                    key={index}
                    style={
                      index !== PlanHistory.length - 1
                        ? { borderBottom: "1px solid #eceeef" }
                        : { borderBottom: 0 }
                    }
                  >
                    <td className={Styles.planname}>
                      <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                        {Plan?.plan_name}
                      </p>
                    </td>

                    <td className={Styles.planname}>
                      <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                        {Plan?.plan_code}
                      </p>
                    </td>

                    <td className={Styles.type}>
                      <p className={`mb-0  ${sharedStyle.tableBodyText}`}>
                        {
                          orderTypes.find(
                            (orderType) => orderType.key === Plan?.plan_type
                          )?.value
                        }
                      </p>
                    </td>

                    <td className={Styles.date}>
                      <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                        {Plan.start_date
                          ? `${monthName(
                              start_date.getMonth()
                            )} ${start_date.getDate()}, ${start_date.getFullYear()}`
                          : "NA"}
                      </p>
                    </td>

                    <td className={Styles.date}>
                      <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                        {Plan.end_date
                          ? `${monthName(
                              end_date.getMonth()
                            )} ${end_date.getDate()}, ${end_date.getFullYear()}`
                          : "NA"}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        emptyState
      )}
    </div>
  );
}

export default PlanHistory;
