// Hooks
import React, { useContext } from "react";

// Function
import { callPutApi } from "../../../../../../api/axios";

// Conponent
import { ToastContext } from "../../../../../../context/toastContext";
import Button from "../../../../../../shared/button/button";
import Loading from "../../../../../../shared/loading/loading";
import Input from "../../../../../../shared/input/input";
import ErrorMessage from "../../../../../../shared/error-message/errorMessage";
import RadioButton from "../../../../../../shared/radio-button/radioButton";

// Constants
import { MEDIUM } from "../../../../../../shared/buttonSize";
import { ACCENTCOLOR, SUCCESS } from "../../../../../../shared/colors";
import { ERROR } from "../../../../../../utils/toastType";

// Styles
import Styles from "./adjustWalletModal.module.scss";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../../../shared/buttonStyles";
import popUpStyles from "../../../../../../shared/popup.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import { IdsGenerator } from "../../../../../../utils/idsGenerator";

function AdjustWalletModal({
  onCancel,
  id,
  disabled,
  onAdjustWallet,
  is_allowed_to_write,
}: any) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [updateWalletLoading, setUpdateWalletLoading] = React.useState(false);
  const dispatch = useContext(ToastContext);

  const pay_mode = {
    CREDIT: "credit",
    DEBIT: "debit",
  };
  const [walletAdjustmentAmount, setWalletAdjusmentAmount] = React.useState();
  const [walletAdjustmentMode, setWalletAdjustmentMode] = React.useState<any>();
  const [walletAdjustmentReason, setWalletAdjustmentReason] = React.useState();

  async function updateWallet() {
    setIsLoading(true);
    setUpdateWalletLoading(true);
    try {
      const res: any = await callPutApi(
        `organisations/${id}?action=add_custom_payment`,
        {
          payment_type: "WALLET_ADJUSTMENT",
          payment_action: "adjust_wallet_internal",
          amount: walletAdjustmentAmount,
          wallet_action: walletAdjustmentMode,
          reason: walletAdjustmentReason,
        }
      );
      if (res.data === "ok") {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: SUCCESS,
            message: "Manual wallet adjustment successfull.",
          },
        });
      }
      setUpdateWalletLoading(false);
      setIsLoading(false);
      onAdjustWallet();
    } catch (err: any) {
      setIsLoading(false);
      setUpdateWalletLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }

  const loading = (
    <div
      style={{ height: "230px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div className={`${popUpStyles.popUpContent} ${Styles.card}`}>
        {isLoading ? (
          loading
        ) : (
          <div>
            <div className={`p-4 ${sharedStyle.cardHeaderBack}`}>
              <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                Wallet Adjustment
              </p>
            </div>
            <div className="p-4 mx-2">
              <div>
                <Input
                  id={IdsGenerator({
                    prefix: "adjust wallet",
                    id: "input",
                    sufix: "amount",
                  })}
                  no_shadow="true"
                  type="text"
                  name="new_amount_to_update"
                  maxLength={10}
                  autoComplete="off"
                  isValid={false}
                  labelname="Amount"
                  onChange={(event: any) => {
                    setWalletAdjusmentAmount(event.target.value);
                  }}
                />
                <ErrorMessage>
                  {walletAdjustmentAmount === undefined ||
                  walletAdjustmentAmount > 0 ||
                  walletAdjustmentAmount === ""
                    ? "*GST not included"
                    : "Amount can only be a positive number"}
                </ErrorMessage>
              </div>
              <div className="d-flex">
                <div className="col-md-4 py-2 px-0">
                  <RadioButton
                    id={IdsGenerator({
                      prefix: "adjust wallet",
                      id: "select",
                      sufix: "Debit",
                    })}
                    label="Debit"
                    type="radio"
                    checked={walletAdjustmentMode === pay_mode.DEBIT}
                    name="payment_type"
                    onChange={() => setWalletAdjustmentMode(pay_mode.DEBIT)}
                  />
                </div>
                <div className="col-md-4 py-2 px-2">
                  <RadioButton
                    id={IdsGenerator({
                      prefix: "adjust wallet",
                      id: "select",
                      sufix: "Credit",
                    })}
                    label="Credit"
                    type="radio"
                    checked={walletAdjustmentMode === pay_mode.CREDIT}
                    name="payment_type"
                    onChange={() => setWalletAdjustmentMode(pay_mode.CREDIT)}
                  />
                </div>
              </div>
              <div>
                <Input
                  id={IdsGenerator({
                    prefix: "adjust wallet",
                    id: "input",
                    sufix: "reason",
                  })}
                  style={{
                    minHeight: "40px",
                    maxHeight: "100px",
                  }}
                  no_shadow="true"
                  textarea={true}
                  rows={3}
                  name="amount_update_reason"
                  autoComplete="off"
                  labelname="Reason"
                  onChange={(event: any) => {
                    setWalletAdjustmentReason(event.target.value);
                  }}
                />
              </div>
            </div>

            <div
              className="py-4 my-2 d-flex align-items-center justify-content-center"
              style={{ borderTop: "1px solid #ECEEEF" }}
            >
              <div className="px-2">
                <Button
                  id={IdsGenerator({
                    prefix: "adjust wallet",
                    id: "click",
                    sufix: "cancel",
                  })}
                  size={MEDIUM}
                  style={cancelButtonStyle}
                  hoveredStyle={cancelButtonHoverStyle}
                  onClick={() => onCancel()}
                >
                  {disabled ? "Close" : "Cancel"}
                </Button>
              </div>
              {!disabled && is_allowed_to_write ? (
                <div className="px-2">
                  <Button
                    id={IdsGenerator({
                      prefix: "adjust wallet",
                      id: "click",
                      sufix: "update",
                    })}
                    size={MEDIUM}
                    disabled={
                      walletAdjustmentAmount === undefined ||
                      walletAdjustmentAmount <= 0 ||
                      isNaN(walletAdjustmentAmount) ||
                      walletAdjustmentMode === undefined ||
                      walletAdjustmentReason === undefined ||
                      walletAdjustmentReason === ""
                    }
                    style={successButtonStyle}
                    isLoading={updateWalletLoading}
                    hoveredStyle={successButtonHoverStyle}
                    onClick={() => updateWallet()}
                  >
                    Update
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdjustWalletModal;
