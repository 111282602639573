import React, { Component } from "react";
import EmptyState from "../empty-state/emptyState";
import Styles from "./errorBoundary.module.scss";
import emptyStateStyle from "../empty-state/emptyState.module.scss";
import Button from "../button/button";
import { LARGE } from "../buttonSize";
import { primaryButtonHoverStyle, primaryButtonStyle } from "../buttonStyles";
interface IProps {
  hasError?: boolean;
}

interface IState {
  hasError?: boolean;
}

class ErrorBoundary extends Component<IState, IProps> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }
  render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <div
          className={`d-flex justify-content-center align-items-center ${Styles.background}`}
        >
          <EmptyState type="404-not-found">
            <div className={"py-2"}>
              <p className={`${emptyStateStyle.emptyStateHeadingText} mb-0`}>
                Something went wrong
              </p>
            </div>
            <div className="py-2">
              <p className={`mb-0 ${emptyStateStyle.emptyStateBodyText}`}>
                The page you are looking for might have been removed, renamed or
                temporarily unavailable.
              </p>
            </div>
            <div className="py-4 text-center">
              <Button
                id="error-boundary-goto-dashboard"
                size={LARGE}
                style={primaryButtonStyle}
                hoveredStyle={primaryButtonHoverStyle}
                onClick={() => (window.location.pathname = "/application")}
              >
                Go To Dashboard
              </Button>
            </div>
          </EmptyState>
        </div>
      );
    }
    return this.props.children as JSX.Element;
  }
}

export default ErrorBoundary;
