// Hooks
import React, { useContext, useEffect } from "react";

// Components
import Button from "../../../../../../shared/button/button";
import RejectReasonModal from "../reject-reason-modal/rejectReasonModal";
import Checkbox from "../../../../../../shared/checkbox/checkbox";
import ConfirmationModal from "../../../../../../shared/modal/confirmationModal";
import Loading from "../../../../../../shared/loading/loading";
import { ToastContext } from "../../../../../../context/toastContext";

// Styles
import Styles from "../products.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import {
  dangerButtonHoverStyle,
  dangerButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../../../shared/buttonStyles";

// constants
import { COMPRESSED } from "../../../../../../shared/buttonSize";
import { ACCENTCOLOR } from "../../../../../../shared/colors";
import { durationStyles } from "../../../../../../constants/durationStyles";
import { orgStatus } from "../../../../../../constants/orgStatus";

// Functions
import { ERROR } from "../../../../../../utils/toastType";
import { callPutApi } from "../../../../../../api/axios";
import { getDuration } from "../../../../../../utils/getDuration";
import { getDurationStyle } from "../../../../../../utils/getDurationStyle";
import { monthName } from "../../../../../../utils/getMonth";
import { ApmContext } from "../../../../../../utils/apmProvider";
import { IdsGenerator } from "../../../../../../utils/idsGenerator";

function PendingProducts({
  id,
  org_status,
  getUpdateProducts,
  getUnsubscribedProducts,
  pendingProducts: pendingProductsList,
  isLoading,
  is_allowed_to_write,
  is_allowed_to_delete,
}: any) {
  const [pendingProducts, setPendingProducts] = React.useState([]);
  const [selectedProductIds, setSelectedProductIds] = React.useState<String[]>(
    []
  );
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [approveButtonLoading, setApproveButtonLoading] = React.useState(false);
  const [toggleRejectReasonModal, setToggleRejectReasonModal] =
    React.useState(false);

  const [approveProductModal, setApproveProductModal] =
    React.useState<boolean>(false);

  const dispatch = useContext(ToastContext);
  const loading = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );
  const emptyState = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <h5 className="mb-0">No Products Found</h5>
    </div>
  );

  useEffect(() => {
    setPendingProducts(pendingProductsList.length ? pendingProductsList : []);
  }, [pendingProductsList]);

  function addSingleProduct(product: any) {
    if (selectedProductIds?.includes(product.id)) {
      setSelectedProductIds(() =>
        selectedProductIds.filter((p) => {
          return p !== product.id;
        })
      );
      return;
    }
    setSelectedProductIds(() => [...selectedProductIds, product.id]);
    return;
  }

  // callback on reject the product approval.
  const onRejectProduct = async ({
    listOfIds,
    trimmedReason,
    id,
    disableButtonLoading,
  }: any) => {
    try {
      await callPutApi(
        `/organisations/${id}?action=reject_product_subscription`,
        {
          product_subscription_ids: listOfIds,
          reject_reason: trimmedReason,
        }
      );
      removeSelectedProducts();
      getUnsubscribedProducts();
      setToggleRejectReasonModal(false);
    } catch (err: any) {
      disableButtonLoading();
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  };

  function removeSelectedProducts() {
    setPendingProducts(
      pendingProducts.filter(
        (product: any) => !selectedProductIds.includes(product.id)
      )
    );
    setSelectedProductIds([]);
    return;
  }

  async function approveProduct() {
    setApproveButtonLoading(true);
    try {
      await callPutApi(
        `organisations/${id}?action=approve_product_subscription`,
        {
          product_subscription_ids: selectedProductIds,
        }
      );
      removeSelectedProducts();
      getUpdateProducts();
      setApproveButtonLoading(false);
      setApproveProductModal(false);
    } catch (err: any) {
      setApproveButtonLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }
  return (
    <div className={sharedStyle.card}>
      <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
        <div className="d-flex align-items-center">
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>Product Approvals</p>
          {selectedProductIds.length !== 0 ? (
            <div className="d-flex align-items-center ml-auto">
              <div className="px-2">
                <Button
                  id={IdsGenerator({
                    prefix: "pending product",
                    id: "click",
                    sufix: "reject",
                  })}
                  size={COMPRESSED}
                  style={dangerButtonStyle}
                  hoveredStyle={dangerButtonHoverStyle}
                  onClick={() => setToggleRejectReasonModal(true)}
                  disabled={
                    !is_allowed_to_delete || org_status === orgStatus.DISABLED
                  }
                >
                  Reject
                </Button>
              </div>
              <div className="px-3">
                <Button
                  id={IdsGenerator({
                    prefix: "pending product",
                    id: "click",
                    sufix: "approve",
                  })}
                  isLoading={approveButtonLoading}
                  size={COMPRESSED}
                  style={successButtonStyle}
                  hoveredStyle={successButtonHoverStyle}
                  onClick={() => setApproveProductModal(true)}
                  disabled={
                    !is_allowed_to_write || org_status === orgStatus.DISABLED
                  }
                >
                  Approve
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {isLoading ? (
        loading
      ) : (
        <div style={{ overflowX: "auto" }}>
          {pendingProducts.length > 0 ? (
            <table style={{ width: "100%" }}>
              <thead className={sharedStyle.tableHeaderBack}>
                <tr>
                  {
                    <td className={Styles.checkbox_column}>
                      {selectedProductIds.length > 0 && (
                        <Checkbox
                          id={IdsGenerator({
                            prefix: "pending products",
                            id: "select",
                            sufix: "all products",
                          })}
                          disabled={
                            !is_allowed_to_write && !is_allowed_to_delete
                          }
                          checked={selectedProductIds.length > 0}
                          onChange={() => {
                            setSelectedProductIds([]);
                          }}
                        />
                      )}
                    </td>
                  }
                  <th className={Styles.product_name_column}>
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Product Name
                    </p>
                  </th>
                  <th className={Styles.date}>
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Date
                    </p>
                  </th>
                  <th className={Styles.status}>
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Status
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody className={Styles.tableWrapper}>
                {pendingProducts.map((product: any, index: number) => {
                  const date = new Date(Number(product.requested_on));
                  const duration = getDuration(date);
                  const style = getDurationStyle(duration, 7, 3);
                  return (
                    <tr
                      key={index}
                      style={
                        index !== pendingProducts.length - 1
                          ? { borderBottom: "1px solid #eceeef" }
                          : { borderBottom: 0 }
                      }
                    >
                      {
                        <td className={Styles.checkbox_column}>
                          <Checkbox
                            id={IdsGenerator({
                              prefix: "pending product",
                              id: "select",
                              sufix: product.name,
                            })}
                            disabled={
                              !is_allowed_to_write && !is_allowed_to_delete
                            }
                            checked={selectedProductIds?.includes(product.id)}
                            onChange={() => addSingleProduct(product)}
                          />
                        </td>
                      }
                      <td className={Styles.product_name_column}>
                        <p className={`mb-0 ${Styles.productName}`}>
                          {product.name}
                        </p>
                      </td>
                      <td className={Styles.date}>
                        <div
                          className={`mb-0 ${Styles.duration}`}
                          style={
                            style === durationStyles.DANGER
                              ? { color: "#d64747" }
                              : style === durationStyles.WARNING
                              ? { color: "#f4a63b" }
                              : { color: "#31b975" }
                          }
                        >
                          {duration}
                        </div>
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {`${monthName(
                            date.getMonth()
                          )} ${date.getDate()}, ${date.getFullYear()}`}
                        </p>
                      </td>
                      <td className={Styles.status}>
                        <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                          {product.status}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            emptyState
          )}
        </div>
      )}
      {toggleRejectReasonModal && (
        <RejectReasonModal
          title="Reject A Product"
          id={id}
          listOfIds={selectedProductIds}
          onCancel={() => setToggleRejectReasonModal(false)}
          onReject={(data: Object) => {
            onRejectProduct(data);
          }}
        />
      )}
      {approveProductModal && (
        <ConfirmationModal
          title="Approve Products"
          id={`approve-${id}`}
          subTitle="Are you sure you want to approve the products?"
          btnText="Approve"
          isLoading={approveButtonLoading}
          onCancel={() => setApproveProductModal(false)}
          onSuccess={() => {
            approveProduct();
          }}
        />
      )}
    </div>
  );
}

export default PendingProducts;
