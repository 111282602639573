import React, { useEffect, useState, useContext } from "react";
import { callGetApi, callPostApi } from "../../../api/axios";
import sharedStyle from "../../../shared/sharedStyle.module.scss";
import Styles from "./products.module.scss";
import Loading from "../../../shared/loading/loading";
import { ACCENTCOLOR } from "../../../shared/colors";
import ArrowDown from "../../../shared/svg/arrowDown";
import { ERROR } from "../../../utils/toastType";
import { ToastContext } from "../../../context/toastContext";
// import AddNewCategoryModal from "./add-new-category-modal/addNewCategoryModal";
import ProductCard from "./product-card/productCard";

import Switch from "../../../shared/toggle-switch/switch";
import { esignVendors } from "../../../constants/esignVendors";
import StylesDoc from "../editDocs/editDocs.module.scss";
import { deprecatedProductsForProductPage } from "../../../constants/deprecatedProducts";

function Products() {
  const currentUser = JSON.parse(localStorage.getItem("current_user") || "");
  const userEmail = currentUser.email;
  const esignUsers: any = process.env.REACT_APP_ESIGN_USERS;
  const [userInfo, setUserInfo] = useState<any>([]);
  const [isChanged, setIsChanged] = useState(false);
  const [defaultESP, setDefaultESP] = useState("");
  const [orgs, setOrgs] = useState<any>([]);
  const isUserAllowed = esignUsers.split(",").includes(userEmail);
  const [loading, setLoading] = React.useState(true);
  const [categoriesArray, setCategoriesArray] = useState<any>([]);
  const dispatch = useContext(ToastContext);
  const [openProductDetails, setOpenProductDetails] = useState<any>("");

  useEffect(() => {
    async function getInfo() {
      try {
        const [users, defaultESPInfo]: any = await Promise.all([
          callGetApi("/esign"),
          callGetApi("/esign/master"),
        ]);
        setUserInfo(users.data.org_config);
        setDefaultESP(defaultESPInfo.data.esp);
        const promises = [];
        for (const user of users.data.org_config) {
          promises.push(
            callGetApi(`/organisations/${user._id}?data=account_info`)
          );
        }
        const orgsList = await Promise.allSettled(promises);
        setOrgs(orgsList);
      } catch (error) {
        console.log(error);
      }
    }

    if (isUserAllowed) getInfo();
  }, [isChanged]);

  async function handleVendorChange(user: any) {
    try {
      await callPostApi("/esign/update", {
        org_id: user._id,
        active_esp:
          user.active_esp === esignVendors.NSDL
            ? esignVendors.VSIGN
            : esignVendors.NSDL,
      });
      setIsChanged(!isChanged);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleMasterVendorChange(changedESP: string) {
    try {
      await callPostApi("/esign/update/master", {
        esp: changedESP,
      });
      setDefaultESP(changedESP);
      setIsChanged(!isChanged);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function getCategories() {
      try {
        const response: any = await callGetApi(`/products`);

        const map = new Map();
        const arr = new Array();
        response.data.map((item: any) => {
          if (!deprecatedProductsForProductPage.includes(item.arn)) {
            map.set(
              item.category,
              typeof map.get(item.category) === "undefined"
                ? [item]
                : [...map.get(item.category), item]
            );
          }
        });

        map.forEach((value: any, key: string) => {
          arr.push({ name: key, data: value });
        });

        setCategoriesArray(arr);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: err.message,
          },
        });
      }
    }

    getCategories();
  }, []);

  return (
    <>
      {isUserAllowed && openProductDetails === "" && (
        <div className="col-md-12 p-3">
          <div className={`${sharedStyle.card}`}>
            <div className={`px-4 pt-4 ${sharedStyle.cardHeaderBack}`}>
              <div className="d-flex align-items-center justify-content-between pb-3">
                <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                  Existing Users
                </p>
                <div className="d-flex align-items-center">
                  <Switch
                    switchColor={Styles.switch__shadow}
                    positiveLabel="VSIGN"
                    negativeLabel="NSDL"
                    id="master-toggle"
                    checked={defaultESP === esignVendors.VSIGN}
                    onChange={() => {
                      const changeESP =
                        defaultESP === esignVendors.VSIGN
                          ? esignVendors.NSDL
                          : esignVendors.VSIGN;
                      handleMasterVendorChange(changeESP);
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div style={{ overflowX: "auto", minHeight: "450px" }}>
                <table style={{ width: "100%" }}>
                  <thead className={sharedStyle.tableHeaderBack}>
                    <tr>
                      <th className={StylesDoc.accID}>
                        <div className="d-flex align-items-center">
                          <p className={`mb-0 ${StylesDoc.tableHeaderText}`}>
                            Organisation ID
                          </p>
                        </div>
                      </th>
                      <th className={StylesDoc.accID}>
                        <div className="d-flex align-items-center">
                          <p className={`mb-0 ${StylesDoc.tableHeaderText}`}>
                            Organisation Name
                          </p>
                        </div>
                      </th>
                      <th className={StylesDoc.accID}>
                        <div className="d-flex align-items-center">
                          <p className={`mb-0 ${StylesDoc.tableHeaderText}`}>
                            <i className={Styles.categoryHeaderOne}>NSDL</i> /{" "}
                            <i className={Styles.categoryHeaderTwo}>VSIGN</i>
                          </p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userInfo.map((user: any, index: number) => (
                      <tr
                        key={index}
                        style={{
                          borderBottom: "1px solid #eceeef",
                        }}
                      >
                        <td
                          className={`${StylesDoc.accID} ${StylesDoc.rowLink}`}
                          style={{ fontSize: "14px" }}
                          id={`${index}`}
                        >
                          {user._id}
                        </td>
                        <td
                          className={`${StylesDoc.accID} ${StylesDoc.rowLink}`}
                          style={{ fontSize: "14px" }}
                          id={`${index}`}
                        >
                          {orgs[index]?.value?.data?.name ? (
                            orgs[index]?.value?.data?.name
                          ) : orgs[index]?.reason ? (
                            "NA"
                          ) : (
                            <Loading loadingColor={ACCENTCOLOR} />
                          )}
                        </td>
                        <td
                          className={`${StylesDoc.accID} ${StylesDoc.rowLink}`}
                          style={{ fontSize: "14px" }}
                          id={`${index}`}
                        >
                          {user.active_esp ? (
                            <Switch
                              switchColor={Styles.switch__shadow}
                              positiveLabel="VSIGN"
                              negativeLabel="NSDL"
                              id={`user-${user._id}`}
                              checked={user.active_esp === esignVendors.VSIGN}
                              onChange={() => handleVendorChange(user)}
                            />
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {openProductDetails === "" ? (
        <div className="p-3">
          <div className={sharedStyle.card}>
            <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
              <div className="d-flex align-items-center ">
                <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                  Existing Product Categories
                </p>
                <div className="px-2 ml-auto">
                  {/* <Button
                    size={LARGE}
                    style={primaryButtonStyle}
                    hoveredStyle={primaryButtonHoverStyle}
                    onClick={() => setToggleAddNewCategoryModal(true)}
                  >
                    Add new product category
                  </Button> */}
                </div>
              </div>
            </div>
            {loading ? (
              <div
                style={{ height: "350px" }}
                className="d-flex align-items-center justify-content-center"
              >
                <Loading loadingColor={ACCENTCOLOR} />
              </div>
            ) : (
              <>
                <div style={{ overflowX: "auto", minHeight: "300px" }}>
                  <table style={{ width: "100%" }}>
                    <thead className={sharedStyle.tableHeaderBack}>
                      <tr>
                        <th
                          className={Styles.name_column}
                          style={{
                            padding: "15px 15px 15px 25px",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Name
                            </p>
                          </div>
                        </th>
                        <th className={Styles.products_count_column}>
                          <p className={`mb-0 ${Styles.tableHeaderText}`}>
                            Total number of products
                          </p>
                        </th>
                        <th className={Styles.right_arrow_column}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoriesArray.length > 0 ? (
                        categoriesArray.map((category: any, index: number) => {
                          return (
                            <tr
                              id={`products-${category}`}
                              key={index}
                              style={{ borderBottom: "1px solid #eceeef" }}
                              className={Styles.table_row_wrapper}
                              onClick={() => setOpenProductDetails(category)}
                            >
                              <td
                                className={Styles.name_column}
                                style={{ padding: "15px 15px 15px 25px" }}
                              >
                                <p className={`mb-0 ${Styles.tableBodyText}`}>
                                  {category.name} Verification
                                </p>
                              </td>
                              <td className={Styles.products_count_column}>
                                <p className={`mb-0 ${Styles.tableBodyText}`}>
                                  {category.data.length}
                                </p>
                              </td>
                              <td className={Styles.right_arrow_column}>
                                <p
                                  className={`mb-0 ${Styles.tableBodyText} ${Styles.rotateRight}`}
                                >
                                  <ArrowDown />
                                </p>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr
                          style={{ height: "400px" }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <td className="mb-0">No Categories found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
          {/* {toggleAddNewCategoryModal && (
            <AddNewCategoryModal
              onAddNewCategory={(newCategory: any) => {
                setToggleAddNewCategoryModal(false);
                setCategoriesArray([...categoriesArray, newCategory]);
              }}
              onCancel={() => setToggleAddNewCategoryModal(false)}
            />
          )} */}
        </div>
      ) : (
        <ProductCard
          key={`${openProductDetails.name}`}
          product={openProductDetails}
          setProduct={setOpenProductDetails}
          categoriesArray={categoriesArray}
          setCategoriesArray={setCategoriesArray}
        />
      )}
    </>
  );
}

export default Products;
