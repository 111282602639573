// Hooks
import React, { useState } from "react";

// Components
import Button from "../../../../../../shared/button/button";
import KeyValuePair from "../../../../../../shared/key-value-pair/keyValuePair";
import Loading from "../../../../../../shared/loading/loading";
import AssignPlanModal from "../assign-plan-modal/assignPlanModal";
import AdjustWalletModal from "../adjust-wallet/adjustWallet";

// Constants
import RupeeSVG from "../../../../../../shared/svg/rupeeSvg";
import { COMPRESSED } from "../../../../../../shared/buttonSize";
import { ACCENTCOLOR, PRIMARYCOLOR } from "../../../../../../shared/colors";
import { orgStatus } from "../../../../../../constants/orgStatus";

// Functions
import { monthName } from "../../../../../../utils/getMonth";
import {
  getAmountWithGST,
  numberWithCommas,
  roundOfDecimal,
} from "../../../../../../utils/updateAmount";

// Styles
import {
  borderButtonHoverStyle,
  borderButtonStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../../../shared/buttonStyles";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import { IdsGenerator } from "../../../../../../utils/idsGenerator";

function CurrentPlanCard({
  current_plan = {},
  isLoading,
  id,
  org_status,
  alert_balance,
  getUpdatedBillingInfo,
  getUpdateAccountInfo,
  is_allowed_to_write,
}: any) {
  const planTypes = {
    PREPAID: "PREPAID",
    POSTPAID: "POSTPAID",
  };
  const [toggleAssignPlanPopUp, setToggleAssignPlanPopUp] =
    React.useState(false);
  const [toggleAdjustWallet, setToggleAdjustWallet] = React.useState(false);
  const start_date = new Date(Number(current_plan.start_date));
  const end_date = new Date(Number(current_plan.end_date));
  const [disabled, setDisabled] = useState(false);

  return (
    <div className={sharedStyle.card}>
      <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
        <div className="d-flex align-items-center">
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>Current Plan</p>
          <div className="pl-2 ml-auto d-flex">
            {is_allowed_to_write && (
              <div className="px-2">
                <Button
                  id={IdsGenerator({
                    prefix: "current plan card",
                    id: "button",
                    sufix: "Adjust Wallet",
                  })}
                  style={borderButtonStyle}
                  hoveredStyle={borderButtonHoverStyle}
                  size={COMPRESSED}
                  onClick={() => setToggleAdjustWallet(true)}
                  disabled={
                    org_status === orgStatus.DISABLED ||
                    current_plan === null ||
                    current_plan?.status !== "ACTIVE"
                  }
                >
                  Adjust Wallet
                </Button>
              </div>
            )}
            {is_allowed_to_write && (
              <div className="px-2">
                <Button
                  id={IdsGenerator({
                    prefix: "current plan card",
                    id: "button",
                    sufix: "assign plan",
                  })}
                  style={primaryButtonStyle}
                  hoveredStyle={primaryButtonHoverStyle}
                  size={COMPRESSED}
                  onClick={() => setToggleAssignPlanPopUp(true)}
                  disabled={org_status === orgStatus.DISABLED}
                >
                  Assign Plan
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <div
            style={{ height: "250px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : (
          <div className="p-4">
            {current_plan.plan_name ? (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <KeyValuePair
                      id={IdsGenerator({
                        prefix: "current plan card",
                        id: "plan name",
                        sufix: "value",
                      })}
                      className="py-4"
                      label="Plan Name"
                      setDisabled={setDisabled}
                      setToggleAssignPlanPopUp={setToggleAssignPlanPopUp}
                      toggleAssignPlanPopUp={toggleAssignPlanPopUp}
                      value={
                        current_plan.plan_name ? current_plan.plan_name : "NA"
                      }
                    />
                    {/* <p
                        className={`mb-0 px-2 ${Styles.rateCard}`}
                        style={{ fontSize: "12px" }}
                        >
                        View Rate Card
                    </p> */}
                  </div>
                  <div className="col-md-6">
                    <KeyValuePair
                      id={IdsGenerator({
                        prefix: "current plan card",
                        id: "plan type",
                        sufix: "value",
                      })}
                      className="py-4"
                      label="Plan Type"
                      value={
                        current_plan.plan_type
                          ? current_plan.plan_type === planTypes.PREPAID
                            ? "Prepaid"
                            : "postpaid"
                          : "NA"
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <KeyValuePair
                      id={IdsGenerator({
                        prefix: "current plan card",
                        id: "plan validity",
                        sufix: "value",
                      })}
                      className="py-4"
                      label="Plan Validity"
                      value={
                        current_plan.duration
                          ? `${current_plan.duration} Months`
                          : "NA"
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <KeyValuePair
                      id={IdsGenerator({
                        prefix: "current plan card",
                        id: `${
                          current_plan?.plan_type === planTypes.POSTPAID
                            ? "Current Consumption"
                            : "Current Balance"
                        }`,
                        sufix: "value",
                      })}
                      className="py-4"
                      label={
                        current_plan.plan_type === planTypes.POSTPAID
                          ? "Current Consumption"
                          : "Current Balance"
                      }
                      value={
                        current_plan?.wallet_balance ||
                        current_plan?.wallet_balance === 0
                          ? numberWithCommas(
                              roundOfDecimal(
                                getAmountWithGST(current_plan.wallet_balance)
                              )
                            )
                          : "NA"
                      }
                    >
                      <RupeeSVG width="8" height="12" color={PRIMARYCOLOR} />
                    </KeyValuePair>
                  </div>
                  <div className="col-md-6">
                    <KeyValuePair
                      id={IdsGenerator({
                        prefix: "current plan card",
                        id: `${
                          current_plan.plan_type === planTypes.POSTPAID
                            ? "Daily Consumption"
                            : "Balance Alert"
                        }`,
                        sufix: "value",
                      })}
                      className="py-4"
                      label={
                        current_plan.plan_type === planTypes.POSTPAID
                          ? "Daily Consumption"
                          : "Balance Alert"
                      }
                      value={
                        alert_balance || alert_balance === 0
                          ? numberWithCommas(roundOfDecimal(alert_balance))
                          : "0"
                      }
                    >
                      <RupeeSVG width="8" height="12" color={PRIMARYCOLOR} />
                    </KeyValuePair>
                  </div>
                  <div className="col-md-6">
                    <KeyValuePair
                      id={IdsGenerator({
                        prefix: "current plan card",
                        id: "activation date",
                        sufix: "value",
                      })}
                      className="py-4"
                      label="Activation Date"
                      value={
                        current_plan.start_date
                          ? `${monthName(
                              start_date.getMonth()
                            )} ${start_date.getDate()}, ${start_date.getFullYear()}`
                          : "NA"
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <KeyValuePair
                      id={IdsGenerator({
                        prefix: "current plan card",
                        id: "expiry date",
                        sufix: "value",
                      })}
                      className="py-4"
                      label="Expiry Date"
                      value={
                        current_plan.end_date
                          ? `${monthName(
                              end_date.getMonth()
                            )} ${end_date.getDate()}, ${end_date.getFullYear()}`
                          : "NA"
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <KeyValuePair
                      id={IdsGenerator({
                        prefix: "current plan card",
                        id: "status",
                        sufix: "value",
                      })}
                      className="py-4"
                      label="Plan Status"
                      value={current_plan.status ?? "NA"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{ height: "250px" }}
                className="d-flex align-items-center justify-content-center"
              >
                <h5 className="mb-0">No Plan subscribed yet</h5>
              </div>
            )}
          </div>
        )}
      </div>
      {toggleAssignPlanPopUp && (
        <AssignPlanModal
          current_planId={current_plan.plan_id}
          disabled={disabled}
          setDisabled={setDisabled}
          id={id}
          onCancel={() => {
            setDisabled(false);
            setToggleAssignPlanPopUp(false);
          }}
          onAssignPlan={() => {
            setToggleAssignPlanPopUp(false);
            getUpdatedBillingInfo();
          }}
          is_allowed_to_write={is_allowed_to_write}
        />
      )}
      {toggleAdjustWallet && (
        <AdjustWalletModal
          disabled={disabled}
          setDisabled={setDisabled}
          id={id}
          onCancel={() => {
            setDisabled(false);
            setToggleAdjustWallet(false);
          }}
          onAdjustWallet={() => {
            setToggleAdjustWallet(false);
            getUpdateAccountInfo();
            getUpdatedBillingInfo();
          }}
          is_allowed_to_write={is_allowed_to_write}
        />
      )}
    </div>
  );
}

export default CurrentPlanCard;
