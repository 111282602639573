// Hooks
import React, { Fragment, useState } from "react";

// Styles
import Styles from "../../plan-details/planDetails.module.scss";
import sharedStyle from "../../../../../shared/sharedStyle.module.scss";

// constants
import { WHITE } from "../../../../../shared/colors";

// components
import ErrorMessage from "../../../../../shared/error-message/errorMessage";

// svg
import ArrowDown from "../../../../../shared/svg/arrowDown";

// function
import { isObject } from "../../../../../utils/validation";
import { IdsGenerator } from "../../../../../utils/idsGenerator";

export default function PlanRateCard(props: any) {
  const {
    products,
    onUpdatePricing,
    pricingError,
    addonPriceError,
    subAddonPriceError,
  } = props;
  const [pricing, setPricing] = useState(products);

  // use this function to update the pricing of add ons
  function updateAddonPrice(product: any, addon_arn: any, inputValue: any) {
    const updatedPricing: any = pricing.map((prod: any) => {
      if (prod.productArn === product.productArn) {
        return {
          ...prod,
          productPricing: {
            ...prod.productPricing,
            [addon_arn]: isObject(prod.productPricing[addon_arn])
              ? {
                  ...prod.productPricing[addon_arn],
                  unit_price: inputValue ? Number(inputValue) : inputValue,
                }
              : inputValue
              ? Number(inputValue)
              : inputValue,
          },
        };
      }
      return { ...prod };
    });
    return updatedPricing;
  }

  // use this function to update the pricing of add ons
  function updateSubAddonPrice(
    product: any,
    addon_arn: any,
    sub_addon_arn: any,
    inputValue: any
  ) {
    const updatedPricing: any = pricing.map((prod: any) => {
      if (prod.productArn === product.productArn) {
        return {
          ...prod,
          productPricing: {
            ...prod.productPricing,
            [addon_arn]: {
              ...prod.productPricing[addon_arn],
              [sub_addon_arn]: inputValue ? Number(inputValue) : inputValue,
            },
          },
        };
      }
      return { ...prod };
    });
    return updatedPricing;
  }

  // use this function to check whether the product has add on or not
  function updateProductPrice(product: any, inputValue: any) {
    // add a check that update the unit price in case of add ons
    if (Object.keys(product.productPricing).length > 0) {
      const updatedPricing = pricing.map((prod: any) => {
        if (prod.productArn === product.productArn) {
          return {
            ...prod,
            productPricing: {
              ...prod.productPricing,
              unit_price: inputValue ? Number(inputValue) : inputValue,
            },
          };
        }
        return { ...prod };
      });
      return updatedPricing;
    } else {
      const updatedPricing = pricing.map((prod: any) => {
        if (prod.productArn === product.productArn) {
          return {
            ...prod,
            productPricing: inputValue ? Number(inputValue) : inputValue,
          };
        }
        return { ...prod };
      });
      return updatedPricing;
    }
  }

  function checkProductAddonsPricing(
    product: any,
    addon_arn: any,
    inputValue: any
  ) {
    const updatedPricing: any = updateAddonPrice(
      product,
      addon_arn,
      inputValue
    );
    setPricing(updatedPricing);
    onUpdatePricing(updatedPricing);
  }

  function checkProductSubAddonsPricing(
    product: any,
    addon_arn: any,
    sub_addon_arn: any,
    inputValue: any
  ) {
    const updatedPricing: any = updateSubAddonPrice(
      product,
      addon_arn,
      sub_addon_arn,
      inputValue
    );
    setPricing(updatedPricing);
    onUpdatePricing(updatedPricing);
  }

  function checkProductPricing(inputValue: any, product: any) {
    const updatedPricing: any = updateProductPrice(product, inputValue);
    setPricing(updatedPricing);
    onUpdatePricing(updatedPricing);
  }
  return (
    <div className="py-4">
      <div className={sharedStyle.card}>
        <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
          <div className="d-flex align-items-center">
            <p className={`mb-0 ${sharedStyle.cardHeader}`}>Products</p>
          </div>
        </div>
        <div style={{ overflowX: "auto" }}>
          <table style={{ width: "100%" }}>
            <thead className={sharedStyle.tableHeaderBack}>
              <tr>
                <th className={Styles.product_name}>
                  <div className="d-flex align-items-center">
                    <p className={`mb-0 ${Styles.tableHeaderText}`}>
                      Product Name
                    </p>
                  </div>
                </th>
                <th className={Styles.product_name}>
                  <div className="d-flex align-items-center">
                    <p className={`mb-0 ${Styles.tableHeaderText}`}>
                      Price per transaction
                    </p>
                  </div>
                </th>
                <th className={Styles.product_name}>
                  <div className="d-flex align-items-center">
                    <p className={`mb-0 ${Styles.tableHeaderText}`}>Add-ons</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {pricing.map((product: any, index: number) => {
                const product_price =
                  Object.keys(product.productPricing).length > 0
                    ? product.productPricing.unit_price
                    : product.productPricing;
                const price_error = pricingError?.find(
                  (error: any) => error.error_for_product === product.productArn
                );
                return (
                  <Fragment key={product.productArn}>
                    <tr style={{ borderBottom: "1px solid #eceeef" }}>
                      <td className={Styles.product_name}>
                        <p className={`mb-0 ${Styles.tableBodyText}`}>
                          {product.productName}
                        </p>
                      </td>
                      <td className={Styles.product_name}>
                        <div className={`${Styles.inputWrapper} py-1`}>
                          <input
                            id={IdsGenerator({
                              prefix: "plan rate card",
                              id: `input ${product.productName}`,
                              sufix: "price",
                            })}
                            type="number"
                            className={Styles.formControl}
                            defaultValue={product_price}
                            autoComplete="off"
                            maxLength={5}
                            placeholder="Enter Pricing"
                            min={0}
                            onWheel={() => {
                              document
                                ?.getElementById(
                                  `id_${product.productArn}_${index}`
                                )
                                ?.blur();
                            }}
                            onClick={(event: any) => event.target.select()}
                            onChange={(event: any) => {
                              const price_value = event.target.value;
                              checkProductPricing(price_value, product);
                            }}
                          />
                        </div>
                        {price_error.error_for_product ===
                          product.productArn && (
                          <ErrorMessage>
                            {price_error.error_message}
                          </ErrorMessage>
                        )}
                      </td>
                      <td className={Styles.product_name}>
                        <div className="d-inline-flex">
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableBodyText}`}>
                              View
                            </p>
                            {product.addons.length > 0 ? (
                              <div className="pl-2">
                                <ArrowDown color={WHITE} />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </td>
                    </tr>
                    {product.addons.length > 0 ? (
                      <tr style={{ borderBottom: "1px solid #eceeef" }}>
                        <td colSpan={3} className={Styles.longDescription}>
                          {/* Adons table  */}
                          <table
                            style={{
                              width: "100%",
                              border: "1px solid #eceeef",
                              borderRadius: "5px",
                            }}
                          >
                            <thead className={sharedStyle.tableHeaderBack}>
                              <tr>
                                <th className={Styles.product_name}>
                                  <div className="d-flex align-items-center">
                                    <p
                                      className={`mb-0 ${Styles.tableHeaderText}`}
                                    >
                                      Addon Name
                                    </p>
                                  </div>
                                </th>
                                <th className={Styles.product_name}>
                                  <div className="d-flex align-items-center">
                                    <p
                                      className={`mb-0 ${Styles.tableHeaderText}`}
                                    >
                                      Price per transaction
                                    </p>
                                  </div>
                                </th>
                                <th className={Styles.product_name}>
                                  <div className="d-flex align-items-center">
                                    <p
                                      className={`mb-0 ${Styles.tableHeaderText}`}
                                    >
                                      Addon Description
                                    </p>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {product.addons.map(
                                (product_addon: any, index: number) => {
                                  const addon_errors = addonPriceError?.find(
                                    (error: any) =>
                                      error.addon_arn ===
                                      product_addon.addon_key
                                  );

                                  return (
                                    <>
                                      <tr
                                        key={product_addon.addon_key}
                                        style={{
                                          borderBottom: "1px solid #eceeef",
                                        }}
                                      >
                                        <td className={Styles.product_name}>
                                          <p
                                            className={`mb-0 ${Styles.tableBodyText}`}
                                          >
                                            {product_addon.addon_name}
                                          </p>
                                        </td>
                                        <td className={Styles.product_name}>
                                          <>
                                            <div
                                              className={`${Styles.inputWrapper} py-1`}
                                            >
                                              <input
                                                id={IdsGenerator({
                                                  prefix: "plan rate card",
                                                  id: `input addon ${product_addon.addon_name}`,
                                                  sufix: "price",
                                                })}
                                                type="number"
                                                className={Styles.formControl}
                                                autoComplete="off"
                                                min={0}
                                                defaultValue={
                                                  isObject(
                                                    product.productPricing[
                                                      product_addon.addon_key
                                                    ]
                                                  )
                                                    ? product?.productPricing[
                                                        String(
                                                          product_addon?.addon_key
                                                        )
                                                      ]?.unit_price
                                                    : product.productPricing[
                                                        product_addon.addon_key
                                                      ]
                                                }
                                                maxLength={5}
                                                placeholder="Enter Pricing"
                                                onWheel={() => {
                                                  document
                                                    ?.getElementById(
                                                      `addon_id_${product.productArn}_${product_addon.addon_name}_${index}`
                                                    )
                                                    ?.blur();
                                                }}
                                                onClick={(event: any) =>
                                                  event.target.select()
                                                }
                                                onChange={(event: any) => {
                                                  const price_value =
                                                    event.target.value;
                                                  checkProductAddonsPricing(
                                                    product,
                                                    product_addon.addon_key,
                                                    price_value
                                                  );
                                                }}
                                              />
                                            </div>
                                            {addon_errors?.product_arn.includes(
                                              product.productArn
                                            ) &&
                                            addon_errors?.addon_arn.includes(
                                              product_addon.addon_key
                                            ) ? (
                                              <ErrorMessage>
                                                {addon_errors.error_message}
                                              </ErrorMessage>
                                            ) : null}
                                          </>
                                        </td>
                                        <td className={Styles.product_name}>
                                          <div className="d-flex align-items-center">
                                            <p
                                              className={`mb-0 ${Styles.tableBodyText}`}
                                            >
                                              {product_addon.addon_description}
                                            </p>
                                            {product_addon.sub_addons.length >
                                            0 ? (
                                              <div className="pl-2">
                                                <ArrowDown color={WHITE} />
                                              </div>
                                            ) : null}
                                          </div>
                                        </td>
                                      </tr>
                                      {product_addon.sub_addons.length > 0 ? (
                                        <tr
                                          style={{
                                            borderBottom: "1px solid #eceeef",
                                          }}
                                        >
                                          <td
                                            colSpan={3}
                                            className={Styles.longDescription}
                                          >
                                            {/* Adons table  */}
                                            <table
                                              style={{
                                                width: "100%",
                                                border: "1px solid #eceeef",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <thead
                                                className={
                                                  sharedStyle.tableHeaderBack
                                                }
                                              >
                                                <tr>
                                                  <th
                                                    className={
                                                      Styles.product_name
                                                    }
                                                  >
                                                    <div className="d-flex align-items-center">
                                                      <p
                                                        className={`mb-0 ${Styles.tableHeaderText}`}
                                                      >
                                                        Sub Addon Name
                                                      </p>
                                                    </div>
                                                  </th>
                                                  <th
                                                    className={
                                                      Styles.product_name
                                                    }
                                                  >
                                                    <div className="d-flex align-items-center">
                                                      <p
                                                        className={`mb-0 ${Styles.tableHeaderText}`}
                                                      >
                                                        Price per transaction
                                                      </p>
                                                    </div>
                                                  </th>
                                                  <th
                                                    className={
                                                      Styles.product_name
                                                    }
                                                  >
                                                    <div className="d-flex align-items-center">
                                                      <p
                                                        className={`mb-0 ${Styles.tableHeaderText}`}
                                                      >
                                                        Sub Addon Description
                                                      </p>
                                                    </div>
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {product_addon.sub_addons.map(
                                                  (
                                                    sub_addon: any,
                                                    index: number
                                                  ) => {
                                                    const sub_addon_errors =
                                                      subAddonPriceError?.find(
                                                        (error: any) =>
                                                          error.addon_arn ===
                                                            product_addon.addon_key &&
                                                          error.sub_addon_arn ===
                                                            sub_addon.sub_addon_key
                                                      );
                                                    return (
                                                      <tr
                                                        key={
                                                          sub_addon.sub_addon_key
                                                        }
                                                        style={{
                                                          borderBottom:
                                                            "1px solid #eceeef",
                                                        }}
                                                      >
                                                        <td
                                                          className={
                                                            Styles.product_name
                                                          }
                                                        >
                                                          <p
                                                            className={`mb-0 ${Styles.tableBodyText}`}
                                                          >
                                                            {
                                                              sub_addon.sub_addon_name
                                                            }
                                                          </p>
                                                        </td>
                                                        <td
                                                          className={
                                                            Styles.product_name
                                                          }
                                                        >
                                                          <>
                                                            <div
                                                              className={`${Styles.inputWrapper} py-1`}
                                                            >
                                                              <input
                                                                type="number"
                                                                id={`addon_id_${product.productArn}_${sub_addon.sub_addon_name}_${index}`}
                                                                className={
                                                                  Styles.formControl
                                                                }
                                                                autoComplete="off"
                                                                min={0}
                                                                defaultValue={
                                                                  product
                                                                    ?.productPricing[
                                                                    String(
                                                                      product_addon?.addon_key
                                                                    )
                                                                  ][
                                                                    sub_addon
                                                                      .sub_addon_key
                                                                  ]
                                                                }
                                                                maxLength={5}
                                                                placeholder="Enter Pricing"
                                                                onWheel={() => {
                                                                  document
                                                                    ?.getElementById(
                                                                      `addon_id_${product.productArn}_${sub_addon.sub_addon_name}_${index}`
                                                                    )
                                                                    ?.blur();
                                                                }}
                                                                onClick={(
                                                                  event: any
                                                                ) =>
                                                                  event.target.select()
                                                                }
                                                                onChange={(
                                                                  event: any
                                                                ) => {
                                                                  const price_value =
                                                                    event.target
                                                                      .value;
                                                                  checkProductSubAddonsPricing(
                                                                    product,
                                                                    product_addon?.addon_key,
                                                                    sub_addon.sub_addon_key,
                                                                    price_value
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                            {sub_addon_errors?.product_arn.includes(
                                                              product.productArn
                                                            ) &&
                                                            sub_addon_errors?.addon_arn.includes(
                                                              product_addon.addon_key
                                                            ) &&
                                                            sub_addon_errors?.sub_addon_arn.includes(
                                                              sub_addon.sub_addon_key
                                                            ) ? (
                                                              <ErrorMessage>
                                                                {
                                                                  sub_addon_errors.error_message
                                                                }
                                                              </ErrorMessage>
                                                            ) : null}
                                                          </>
                                                        </td>
                                                        <td
                                                          className={
                                                            Styles.product_name
                                                          }
                                                        >
                                                          <p
                                                            className={`mb-0 ${Styles.tableBodyText}`}
                                                          >
                                                            {
                                                              sub_addon.sub_addon_description
                                                            }
                                                          </p>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      ) : null}
                                    </>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ) : null}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
