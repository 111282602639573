import React from "react";

// Styles
import Styles from "./errorMessage.module.scss";

function ErrorMessage({ ...props }: any) {
  return (
    <p className={`${Styles.errorText} p-2 mb-0`} data-testid={props.testId}>
      {props.children}
    </p>
  );
}

export default ErrorMessage;
