// Hooks
import React, { useContext, useEffect, useState } from "react";

// Functions
import { callGetApi, callPutApi } from "../../../../../../api/axios";
import { ERROR } from "../../../../../../utils/toastType";

// Constants
import { COMPRESSED } from "../../../../../../shared/buttonSize";
import { orgStatus } from "../../../../../../constants/orgStatus";
import UpdateSvg from "../../../../../../shared/svg/updateSvg";

// Components
import { ToastContext } from "../../../../../../context/toastContext";
import Button from "../../../../../../shared/button/button";
import Loading from "../../../../../../shared/loading/loading";
import Checkbox from "../../../../../../shared/checkbox/checkbox";

// Sytles
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../../../shared/buttonStyles";
import { ACCENTCOLOR } from "../../../../../../shared/colors";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import { IdsGenerator } from "../../../../../../utils/idsGenerator";

export default function InternalIntegration({
  org_id = "",
  accountInfo = {},
  is_allowed_to_write,
}: any) {
  const [additionalInfo, setAdditionalInfo] = useState({
    zoopsign: false,
    zoopwallet: false,
  });

  const [defaultAdditionalInfo, setDefaultAdditionalInfo] = useState({
    zoopsign: false,
    zoopwallet: false,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [updateAdditionalInfoLoading, setUpdateAdditionalInfoLoading] =
    useState(false);
  const [toggleUpdateAdditionalInfo, setToggleUpdateAdditionalInfo] =
    useState(false);

  const dispatch = useContext(ToastContext);

  useEffect(() => {
    if (org_id) {
      getAdditionalInfo();
    }
    async function getAdditionalInfo() {
      setIsLoading(true);
      try {
        const { data }: any = await callGetApi(
          `organisations/${org_id}?data=additional_info`
        );

        setIsLoading(false);
        setAdditionalInfo({ ...data.internal_integration_apps });
        setDefaultAdditionalInfo({ ...data.internal_integration_apps });
      } catch (err: any) {
        setIsLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: err.response.data.err,
          },
        });
      }
    }
  }, [org_id, dispatch]);

  // function to update additional info
  async function updateAdditionalInfo() {
    setUpdateAdditionalInfoLoading(true);

    try {
      const { data }: any = await callPutApi(
        `/organisations/${org_id}?action=update_additional_info`,
        {
          internal_integration_apps: additionalInfo,
        }
      );
      setAdditionalInfo({ ...data.internal_integration_apps });
      setDefaultAdditionalInfo({ ...data.internal_integration_apps });
      setUpdateAdditionalInfoLoading(false);
      setToggleUpdateAdditionalInfo(false);
    } catch (err: any) {
      setUpdateAdditionalInfoLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }

  const loading = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );

  return (
    <div className={sharedStyle.card}>
      <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
        <div className="d-flex align-items-center">
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>
            Internal Integration
          </p>
          <div className="ml-auto">
            {isLoading ? (
              <div>--</div>
            ) : is_allowed_to_write ? (
              toggleUpdateAdditionalInfo ? (
                <div className="d-flex align-items-center">
                  <div className="px-2">
                    <Button
                      id={IdsGenerator({
                        prefix: "Internal Integration",
                        id: "cancel",
                        sufix: "",
                      })}
                      size={COMPRESSED}
                      style={cancelButtonStyle}
                      disabled={updateAdditionalInfoLoading}
                      hoveredStyle={cancelButtonHoverStyle}
                      onClick={() => {
                        setAdditionalInfo(defaultAdditionalInfo);
                        setToggleUpdateAdditionalInfo(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="px-2">
                    <Button
                      id={IdsGenerator({
                        prefix: "Internal Integration",
                        id: "save",
                        sufix: "",
                      })}
                      size={COMPRESSED}
                      style={successButtonStyle}
                      disabled={updateAdditionalInfoLoading}
                      hoveredStyle={successButtonHoverStyle}
                      onClick={() => updateAdditionalInfo()}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              ) : accountInfo?.status === orgStatus.DISABLED ? null : (
                <UpdateSvg
                  id={IdsGenerator({
                    prefix: "Internal Integration",
                    id: "update",
                    sufix: "svg",
                  })}
                  click={() => {
                    setToggleUpdateAdditionalInfo(true);
                  }}
                />
              )
            ) : null}
          </div>
        </div>
      </div>
      {isLoading || updateAdditionalInfoLoading ? (
        loading
      ) : (
        <div className="p-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="py-2 d-flex">
                  <Checkbox
                    id={IdsGenerator({
                      prefix: "Internal Integration",
                      id: "select",
                      sufix: "zoop sign",
                    })}
                    disabled={!toggleUpdateAdditionalInfo}
                    checked={additionalInfo.zoopsign}
                    onChange={(event: any) => {
                      const { checked } = event.target;
                      setAdditionalInfo((prev) => ({
                        ...prev,
                        zoopsign: checked,
                      }));
                    }}
                  />
                  <label className="mx-4" htmlFor="internalIntergrationSign">
                    Zoop Sign
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="py-2 d-flex">
                  <Checkbox
                    id={IdsGenerator({
                      prefix: "internal integration",
                      id: "select",
                      sufix: "zoop wallet",
                    })}
                    disabled={!toggleUpdateAdditionalInfo}
                    checked={additionalInfo.zoopwallet}
                    onChange={(event: any) => {
                      const { checked } = event.target;
                      setAdditionalInfo((prev) => ({
                        ...prev,
                        zoopwallet: checked,
                      }));
                    }}
                  />
                  <label className="mx-4" htmlFor="internalIntergrationWallet">
                    Zoop Wallet
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
