import React from "react";
import Styles from "./radioButton.module.scss";

function RadioButton({ id, label, disabled, ...props }: any) {
  return (
    <div
      id={id}
      className={`${
        props.checked ? Styles.activeRadioButton : Styles.radioWrapper
      }`}
    >
      <label
        className={`${Styles.container} ${
          disabled ? Styles.disabled : ""
        } mb-0`}
      >
        {label}
        <input disabled={disabled} {...props} />
        <span className={Styles.checkmark}></span>
      </label>
    </div>
  );
}

export default RadioButton;
