// Library Utilities
import validator from "validator";
import parse from "@bany/curl-to-json";

// hooks
import React, { useContext, useEffect, useState } from "react";

// styles
import Styles from "../editDocs.module.scss";
import {
  cancelButtonModernHoverStyle,
  cancelButtonModernStyle,
  dangerButtonHoverStyle,
  dangerButtonStyle,
  primaryButtonHoverModernStyle,
  primaryButtonModernStyle,
} from "../../../../shared/buttonStyles";
import popUpStyles from "../../../../shared/popup.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";

// components
import Button from "../../../../shared/button/button";
import Dropdown from "../../../../shared/dropdown/dropdown";
import { ToastContext } from "../../../../context/toastContext";
import ErrorMessage from "../../../../shared/error-message/errorMessage";
import InputDropdown from "../../../../shared/input-dropdown/inputDropdown";
import Loading from "../../../../shared/loading/loading";

// constants
import { COMPRESSED } from "../../../../shared/buttonSize";
import { ACCENTCOLOR } from "../../../../shared/colors";

// functions
import {
  createParams,
  paramsType,
  updateParams,
  vallidateData,
} from "./functions";
import { ERROR, SUCCESS } from "../../../../utils/toastType";
import { callGetApi, callPostApi } from "../../../../api/axios";

enum requiredOptions {
  Y = "Y",
  N = "N",
}

function EditDocPopupp({
  setList,
  setNameToArn,
  selectedData,
  setSelectedData,
  setShowEditPopup,
  is_allows_to_write,
}: any) {
  const dispatch = useContext(ToastContext);
  const currentUser = JSON.parse(localStorage.getItem("current_user") || "");
  const userEmail = currentUser.email;

  // Used to build OpenAPI objects
  const [doc, setDoc] = useState<any>(null);
  const [curl, setCurl] = useState<string>("");
  const [responses, setResponses] = useState<any>({});
  const [params, setParams] = useState<paramsType[]>([]);
  const [headers, setHeaders] = useState<paramsType[]>([]);
  const [productArn, setProductArn] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [curlData, setCurlData] = useState<parse.ResultJSON>();
  const [productDescription, setProductDescription] = useState<string>("");

  // Helper states
  const [error, setError] = useState<string>("");
  const [tempCode, setTempCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [statusCode, setStatusCode] = useState<string>("");
  const [responseCode, setResponseCode] = useState<string>("");
  const [zoopResponse, setZoopResponse] = useState<string>("");
  const [statusCodes, setStatusCodes] = useState<string[]>([]);
  const [responseCodes, setResponseCodes] = useState<string[]>([]);
  const [addStatusCode, setAddStatusCode] = useState<boolean>(false);
  const [addResponseCode, setAddResponseCode] = useState<boolean>(false);

  useEffect(() => {
    getProductDoc();
  }, []);

  useEffect(() => {
    if (!doc) return;

    if (doc?.arn) {
      setProductArn(doc.arn);
    }

    if (doc?.name) {
      setProductName(doc.name);
    }

    if (doc?.description) {
      setProductDescription(doc.description);
    }

    if (doc?.curl) {
      setCurl(doc.curl);
      setCurlData(parse(doc.curl));
    }

    if (doc?.headers) {
      setHeaders(doc.headers);
    }

    if (doc?.params) {
      setParams(doc.params);
    }

    if (doc?.responses) {
      setResponses(doc.responses);
    }
  }, [doc]);

  useEffect(() => {
    if (responses) {
      const statCodes = Object.keys(responses);
      if (statCodes.length < 1) {
        setStatusCode("");
      } else if (!statusCode || !statCodes.includes(statusCode)) {
        setStatusCode(statCodes[0]);
      }
      setStatusCodes(statCodes);
    } else {
      setStatusCode("");
      setStatusCodes([]);
    }

    if (responses && statusCode) {
      const resCodes = Object.keys(responses[statusCode]);
      if (resCodes.length < 1) {
        setResponseCode("");
      } else if (!responseCode || !resCodes.includes(responseCode)) {
        setResponseCode(resCodes[0]);
      }
      setResponseCodes(resCodes);
    } else {
      setResponseCode("");
      setResponseCodes([]);
    }

    if (responses && statusCode && responseCode) {
      setZoopResponse(
        JSON.stringify(responses[statusCode][responseCode], undefined, 4)
      );
    } else {
      setZoopResponse("");
    }
  }, [responses, statusCode, responseCode]);

  useEffect(() => {
    if (!(curlData as any)?.location || !curlData?.data || !curlData?.header) {
      setError("Invalid cURL");
      return;
    } else {
      setError("");
    }

    if (!doc) {
      const header = createParams(curlData?.header, true);
      const param = createParams(curlData?.data, false);

      setHeaders(header);
      setParams(param);
    } else {
      const header = updateParams(curlData?.header, headers, true);
      const param = updateParams(curlData?.data, params, false);

      setHeaders(header);
      setParams(param);
    }
  }, [curlData]);

  async function getProductDoc() {
    try {
      if (!selectedData?.arn) return;
      setLoading(true);
      const { data }: any = await callGetApi(`/doc/${selectedData.arn}`);
      if (!data) {
        throw new Error();
      }
      setDoc(data);
    } catch (error) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Failed to fetch document!",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  async function setProductDoc() {
    try {
      setError("");
      const updatedDoc = {
        arn: productArn,
        name: productName,
        description: productDescription,
        type: selectedData.type as string,
        category: selectedData.category as string,
        curl: curl,
        headers: headers,
        params: params,
        responses: responses,
        updated_by: userEmail,
      };

      if (vallidateData(updatedDoc, setError)) return;

      const { data }: any = await callPostApi(
        `/doc/${doc ? doc.arn : productArn}`,
        {
          ...updatedDoc,
        }
      );
      if (!data) {
        throw new Error();
      }
      setDoc(data);

      if (!doc) {
        setList((prev: any) => {
          prev.push(productName);
          return prev;
        });
        setNameToArn((prev: any) => {
          prev[`${selectedData.category}_${selectedData.type}_${productName}`] =
            productArn;
          return prev;
        });
      } else {
        setNameToArn((prev: any) => {
          delete prev[
            `${selectedData.category}_${selectedData.type}_${selectedData.product}`
          ];
          prev[`${selectedData.category}_${selectedData.type}_${productName}`] =
            productArn;
          return prev;
        });
        setList((prev: string[]) => {
          const idx: number = prev.indexOf(selectedData.product);
          prev[idx] = productName;
          return prev;
        });
      }
      setSelectedData((prev: any) => {
        delete prev.product;
        delete prev.arn;
        return prev;
      });

      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: SUCCESS,
          message: "Document saved successfully!",
        },
      });
      setShowEditPopup(false);
    } catch (error) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Failed to save document!",
        },
      });
    }
  }

  async function checkArnDuplication() {
    try {
      const { data }: any = await callPostApi(`/doc`, {
        arns: [productArn],
      });

      if (!data) {
        throw new Error();
      }

      // For creating new document
      if (!doc && data.length > 0) {
        setError("ARN already exists");
        return;
      }

      // For existing document
      if (doc && data.length > 1) {
        setError("ARN already exists");
        return;
      }

      setProductDoc();
    } catch (error) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Failed to check arn duplication!",
        },
      });
    }
  }
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div
        className={`${popUpStyles.popUpContent} d-flex flex-row`}
        style={{
          width: "1200px",
          height: "600px",
          minWidth: "600px",
        }}
      >
        {loading ? (
          <div className="w-100">
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : (
          <>
            <div
              className="m-4"
              style={{ overflowY: "scroll", width: "570px" }}
            >
              <div className="d-flex" style={{ gap: "8px" }}>
                <div className="d-flex flex-column w-50">
                  <p className={`${Styles.subText} mb-2 ${Styles.alignLeft}`}>
                    Product Name
                  </p>
                  <input
                    className={Styles.popupInputLarge}
                    value={productName}
                    onChange={(e) => {
                      setError("");
                      setProductName(e.target.value);
                    }}
                  />
                </div>
                <div className="d-flex flex-column w-50">
                  <p className={`${Styles.subText} mb-2 ${Styles.alignLeft}`}>
                    ARN
                  </p>
                  <input
                    className={`${Styles.popupInputLarge}`}
                    value={productArn}
                    onChange={(e: any) => {
                      setError("");
                      const val: string = e.target.value || "";
                      if (val.includes(" ")) {
                        setError("Space is not allowed in ARN");
                      }
                      setProductArn(val);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex flex-column mt-2">
                <div className="d-flex flex-column">
                  <p className={`${Styles.subText} mb-2 ${Styles.alignLeft}`}>
                    Description
                  </p>
                  <textarea
                    className={`${Styles.popupInputLarge}`}
                    value={productDescription}
                    onChange={(e: any) => {
                      setError("");
                      setProductDescription(e.target.value);
                    }}
                  />
                </div>
              </div>
              {headers.length ? (
                <div className="d-flex mt-4 flex-column">
                  <div className="d-flex align-items-center">
                    <p className={Styles.sectionHeader}>Headers</p>
                    <hr className="mb-4 ml-2" />
                  </div>
                  <div className="d-flex">
                    <div className="d-flex flex-column mr-4">
                      <table>
                        <thead>
                          <tr>
                            <th
                              className={`mb-0 ${Styles.subText} ${Styles.alignLeft}`}
                            >
                              Name
                            </th>
                            <th className={`mb-0 ${Styles.subText} `}>
                              Required
                            </th>
                            <th
                              className={`mb-0 ${Styles.subText} ${Styles.alignLeft}`}
                            >
                              Example
                            </th>
                            <th
                              className={`mb-0 ${Styles.subText} ${Styles.alignLeft}`}
                            >
                              Description
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {headers?.map((header: any, index: number) => {
                            return (
                              <tr
                                key={header.name}
                                style={{
                                  borderBottom: "1px solid #eceeef",
                                }}
                              >
                                <td className={Styles.date}>
                                  <p
                                    className={`mb-0 ${sharedStyle.tableBodyTextMini}`}
                                  >
                                    {header.name}
                                  </p>
                                </td>
                                <td className={Styles.particulars}>
                                  <p
                                    className={`mb-0 ${sharedStyle.tableBodyTextMini}`}
                                  >
                                    <div className="mr-2">
                                      <Dropdown
                                        default_value={
                                          header.required
                                            ? requiredOptions.Y
                                            : requiredOptions.N
                                        }
                                        optionsArray={Object.keys(
                                          requiredOptions
                                        )}
                                        click={(value: string) => {
                                          setHeaders((prev) => {
                                            prev[index].required =
                                              value === requiredOptions.Y;
                                            return prev;
                                          });
                                        }}
                                      />
                                    </div>
                                  </p>
                                </td>
                                <td className={Styles.person}>
                                  <p
                                    className={`mb-0 overflow-auto ${sharedStyle.tableBodyTextMini}`}
                                  >
                                    {header.example}
                                  </p>
                                </td>
                                <td className={Styles.person}>
                                  <p
                                    className={`mb-0 ${sharedStyle.tableBodyTextMini}`}
                                  >
                                    <input
                                      defaultValue={header.description}
                                      style={{
                                        backgroundColor: "#fafafa",
                                        borderRadius: "5px",
                                        width: "100%",
                                        paddingLeft: "10px",
                                      }}
                                      onChange={(e) => {
                                        setHeaders((prev) => {
                                          prev[index].description =
                                            e.target.value.trim();
                                          return prev;
                                        });
                                      }}
                                    />
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}
              {params.length ? (
                <div className="d-flex mt-4 flex-column">
                  <div className="d-flex align-items-center">
                    <p className={Styles.sectionHeader}>Params</p>
                    <hr className="mb-4 ml-2" />
                  </div>
                  <div className="d-flex">
                    <div className="d-flex flex-column mr-4">
                      <table>
                        <thead>
                          <tr>
                            <th
                              className={`mb-0 ${Styles.subText} ${Styles.alignLeft}`}
                            >
                              Name
                            </th>
                            <th className={`mb-0 ${Styles.subText} `}>
                              Required
                            </th>
                            <th
                              className={`mb-0 ${Styles.subText} ${Styles.alignLeft}`}
                            >
                              Example
                            </th>
                            <th
                              className={`mb-0 ${Styles.subText} ${Styles.alignLeft}`}
                            >
                              Description
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {params?.map((param: any, index: number) => {
                            return (
                              <tr
                                key={param.name}
                                style={{ borderBottom: "1px solid #eceeef" }}
                              >
                                <td className={Styles.date}>
                                  <p
                                    className={`mb-0 ${sharedStyle.tableBodyTextMini}`}
                                  >
                                    {param.name.slice(
                                      param.name.lastIndexOf(".") + 1
                                    )}
                                  </p>
                                </td>
                                <td className={Styles.particulars}>
                                  <p
                                    className={`mb-0 ${sharedStyle.tableBodyTextMini}`}
                                  >
                                    <div className="mr-2">
                                      <Dropdown
                                        default_value={
                                          param.required
                                            ? requiredOptions.Y
                                            : requiredOptions.N
                                        }
                                        optionsArray={Object.keys(
                                          requiredOptions
                                        )}
                                        click={(value: string) => {
                                          setParams((prev) => {
                                            prev[index].required =
                                              value === requiredOptions.Y;
                                            return prev;
                                          });
                                        }}
                                      />
                                    </div>
                                  </p>
                                </td>
                                <td className={Styles.person}>
                                  <p
                                    className={`mb-0 overflow-auto ${sharedStyle.tableBodyTextMini}`}
                                  >
                                    {param.example}
                                  </p>
                                </td>
                                <td className={Styles.person}>
                                  <p
                                    className={`mb-0 ${sharedStyle.tableBodyTextMini}`}
                                  >
                                    <input
                                      defaultValue={param.description}
                                      style={{
                                        backgroundColor: "#fafafa",
                                        borderRadius: "5px",
                                        width: "100%",
                                        paddingLeft: "10px",
                                      }}
                                      onChange={(e) => {
                                        setParams((prev) => {
                                          prev[index].description =
                                            e.target.value.trim();
                                          return prev;
                                        });
                                      }}
                                    />
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="d-flex mt-4 flex-column">
                <div className="d-flex align-items-center">
                  <p className={Styles.sectionHeader}>cURL</p>
                  <hr className="mb-4 ml-2" />
                </div>
                <div className="d-flex">
                  <div className="d-flex flex-column mr-4">
                    <p className={`${Styles.subText} ${Styles.alignLeft}`}>
                      Paste your production cURL here
                    </p>
                    <textarea
                      className={Styles.popupTextArea}
                      rows={10}
                      cols={40}
                      value={curl || ""}
                      onChange={(e: any) => {
                        setError("");
                        const val = e.target.value;
                        setCurl(val);
                        if (val) {
                          try {
                            const parsedCurl = parse(val);
                            setCurlData(parsedCurl);
                          } catch (error) {
                            setCurlData(undefined);
                          }
                        } else {
                          setCurlData(undefined);
                        }
                      }}
                    />
                  </div>
                  {curlData?.data ? (
                    <div className="d-flex flex-column">
                      <p className={`${Styles.subText} ${Styles.alignLeft}`}>
                        Params Extracted
                      </p>
                      <textarea
                        style={{ cursor: "not-allowed" }}
                        disabled={true}
                        className={Styles.popupTextArea}
                        rows={10}
                        cols={40}
                        value={JSON.stringify(curlData?.data, undefined, 4)}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="mt-2 mr-4 mb-2">
              <div className="d-flex" style={{ gap: "8px" }}>
                <div className="d-flex w-50">
                  {addStatusCode ? (
                    <>
                      <div className="d-flex flex-column w-100 mt-3">
                        <p
                          className={`${Styles.subText} mb-2 ${Styles.alignLeft} ${Styles.addCode}`}
                        >
                          Add Status Code
                        </p>
                        <input
                          className={`${Styles.popupInputLarge}`}
                          onChange={(e: any) => {
                            const val = e.target.value;
                            setTempCode(val);
                          }}
                          placeholder="Status code"
                        />
                      </div>
                      <div className="w-full ml-2 mr-1">
                        <Button
                          id="edit-doc-save-status-code"
                          className={`${Styles.btns}`}
                          style={primaryButtonModernStyle}
                          hoveredStyle={primaryButtonHoverModernStyle}
                          onClick={() => {
                            setResponses((prev: any) => {
                              prev[tempCode] = {};
                              return prev;
                            });
                            setStatusCode(tempCode);
                            setTempCode("");
                            setAddStatusCode(false);
                          }}
                        >
                          Save
                        </Button>
                      </div>
                      <div className="w-full">
                        <Button
                          id="edit-doc-cancel-status-code"
                          className={`${Styles.btns}`}
                          style={cancelButtonModernStyle}
                          hoveredStyle={cancelButtonModernHoverStyle}
                          onClick={() => {
                            setTempCode("");
                            setAddStatusCode(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-100">
                        <InputDropdown
                          id="edit-doc-status-codes"
                          miniLabel={true}
                          labelname="Status Code"
                          optionsArray={statusCodes}
                          defaultValue={statusCode}
                          no_shadow="true"
                          click={(code: string) => {
                            setStatusCode(code);
                          }}
                        />
                      </div>
                      <div className="w-full ml-2 mr-1">
                        <Button
                          id="edit-doc-add-new-status-code"
                          className={`${Styles.btns}`}
                          style={primaryButtonModernStyle}
                          hoveredStyle={primaryButtonHoverModernStyle}
                          onClick={() => setAddStatusCode(true)}
                        >
                          New
                        </Button>
                      </div>
                      {statusCode ? (
                        <div className="w-full">
                          <Button
                            id="edit-doc-delete-status-code"
                            className={`${Styles.btns}`}
                            style={dangerButtonStyle}
                            hoveredStyle={dangerButtonHoverStyle}
                            onClick={() => {
                              setResponses((prev: any) => {
                                delete prev[statusCode];
                                return prev;
                              });
                              const code = Object.keys(responses)[0] || "";
                              setStatusCode(code);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
                <div className="d-flex w-50">
                  {addResponseCode ? (
                    <>
                      <div className="d-flex flex-column w-100 mt-3">
                        <p
                          className={`${Styles.subText} mb-2 ${Styles.alignLeft} ${Styles.addCode}`}
                        >
                          Add Response Code
                        </p>
                        <input
                          className={`${Styles.popupInputLarge}`}
                          onChange={(e: any) => {
                            const val = e.target.value;
                            setTempCode(val);
                          }}
                          placeholder="Response code"
                        />
                      </div>
                      <div className="w-full ml-2 mr-1">
                        <Button
                          id="edit-doc-save-status-code"
                          className={`${Styles.btns}`}
                          style={primaryButtonModernStyle}
                          hoveredStyle={primaryButtonHoverModernStyle}
                          onClick={() => {
                            setResponses((prev: any) => {
                              prev[statusCode][tempCode] = null;
                              return prev;
                            });
                            setResponseCode(tempCode);
                            setTempCode("");
                            setAddResponseCode(false);
                          }}
                        >
                          Save
                        </Button>
                      </div>
                      <div className="w-full">
                        <Button
                          id="edit-doc-cancel-response-code"
                          className={`${Styles.btns}`}
                          style={cancelButtonModernStyle}
                          hoveredStyle={cancelButtonModernHoverStyle}
                          onClick={() => {
                            setTempCode("");
                            setAddResponseCode(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-100">
                        <InputDropdown
                          miniLabel={true}
                          labelname="Response Code"
                          optionsArray={responseCodes}
                          defaultValue={responseCode}
                          no_shadow="true"
                          click={(code: string) => {
                            setResponseCode(code);
                          }}
                        />
                      </div>
                      <div className="w-full ml-2 mr-1">
                        <Button
                          id="edit-doc-new-response-code"
                          className={`${Styles.btns}`}
                          style={primaryButtonModernStyle}
                          hoveredStyle={primaryButtonHoverModernStyle}
                          disabled={!statusCode}
                          onClick={() => setAddResponseCode(true)}
                        >
                          New
                        </Button>
                      </div>
                      {responseCode ? (
                        <div className="w-full">
                          <Button
                            id="edit-doc-delete-response-code"
                            className={`${Styles.btns}`}
                            style={dangerButtonStyle}
                            hoveredStyle={dangerButtonHoverStyle}
                            onClick={() => {
                              setResponses((prev: any) => {
                                delete prev[statusCode][responseCode];
                                return prev;
                              });
                              const code =
                                Object.keys(responses[statusCode])[0] || "";
                              setResponseCode(code);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
              <div className="d-flex mt-4 flex-column">
                <div className="d-flex align-items-center">
                  <p className={Styles.sectionHeader}>Response</p>
                  <hr className="mb-4 ml-2" />
                </div>
                <div className="d-flex">
                  <div className="d-flex flex-column">
                    <p className={`${Styles.subText} ${Styles.alignLeft}`}>
                      Paste sample response here
                    </p>
                    <textarea
                      rows={18}
                      cols={90}
                      value={zoopResponse}
                      disabled={!responseCode}
                      placeholder="No data available!"
                      className={Styles.popupTextArea}
                      onChange={(e: any) => {
                        setError("");
                        const val = e.target.value;
                        setZoopResponse(val);

                        if (!validator.isJSON(val)) {
                          setError("Invalid JSON Response");
                        } else {
                          setResponses((prev: any) => {
                            prev[statusCode][responseCode] = JSON.parse(val);
                            return prev;
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="py-3 d-flex justify-content-end">
                <div className="d-flex">
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                </div>
                <div className="px-2">
                  <Button
                    id="edit-doc-cancel-selected-data"
                    size={COMPRESSED}
                    style={cancelButtonModernStyle}
                    hoveredStyle={cancelButtonModernHoverStyle}
                    onClick={() => {
                      setShowEditPopup(false);
                      setSelectedData((prev: any) => {
                        delete prev.product;
                        delete prev.arn;
                        return prev;
                      });
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    id="edit-doc-save"
                    size={COMPRESSED}
                    style={primaryButtonModernStyle}
                    hoveredStyle={primaryButtonHoverModernStyle}
                    disabled={error || !is_allows_to_write}
                    onClick={() => {
                      checkArnDuplication();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default EditDocPopupp;
