export const BACKGROUNDCOLOR = "#f5f5f5";
export const LINKHOVERBACKGROUND = "#e2f3ff";
export const AUTHSIDEBARGRADIENT = "linear-gradient(to top, #202020, #6a6a6a)";
export const LINKCOLOR = "#202020";
export const WHITE = "#fff";
export const BLACK = "#000";
export const PRIMARYCOLOR = "#202020";
export const SECONDARYCOLOR = "#747474";
export const ACCENTCOLOR = "#0075f3";
export const SUCCESS = "#31b975";
export const DANGER = "#D64747";
export const WARNING = "#ed812f";
export const ACCENTDARKCOLOR = "#004F9F";
export const SUCCESSDARKCOLOR = "#008B47";
export const DANGERDARKCOLOR = "#B6393A";
export const BORDERCOLOR = "rgba(112, 112, 112, 0.2)";
export const BLUEBACKGROUNDCOLOR = "#3272EC";
export const GREYCOLOR = "#DDDCDD";
export const DARKGREENCOLOR = "#0F9D58";
export const REDCOLOR = "#DB4437";
export const DARKGREY = "#3b3b3b";
export const YELLOW = "#ffc922";
export const PURPLE = "#7F00FF";
