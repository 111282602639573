// Hooks
import React, { useContext } from "react";

// Functions
import { callPutApi } from "../../../../../../../api/axios";
import { ToastContext } from "../../../../../../../context/toastContext";

// Constants
import { SECONDARYCOLOR } from "../../../../../../../shared/colors";
import { ERROR } from "../../../../../../../utils/toastType";
import { MEDIUM } from "../../../../../../../shared/buttonSize";

// components
import KeyValuePair from "../../../../../../../shared/key-value-pair/keyValuePair";
import ArrowDown from "../../../../../../../shared/svg/arrowDown";
import RadioButton from "../../../../../../../shared/radio-button/radioButton";
import Input from "../../../../../../../shared/input/input";
import ErrorMessage from "../../../../../../../shared/error-message/errorMessage";
import Button from "../../../../../../../shared/button/button";

// Styles
import Styles from "../addPaymentModal.module.scss";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../../../../shared/buttonStyles";
import { ApmContext } from "../../../../../../../utils/apmProvider";
import { IdsGenerator } from "../../../../../../../utils/idsGenerator";

function ExternalPayment({
  back,
  onCancel,
  onProceed,
  planPaymentPending,
  id,
  country_code,
}: any) {
  const method = {
    NEFT: "NEFT",
    IMPS: "IMPS",
    RTGS: "RTGS",
  };
  const dispatch = useContext(ToastContext);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [referenceNumber, setReferenceNumber] = React.useState("");
  const [referenceNumberError, setReferenceNumberError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const alphaNumericRegexp = /^[A-Z0-9]+$/;
  function checkReferenceNumber() {
    const reference_number = referenceNumber.split(",");
    const isValidReferenceNumber = reference_number.map((number, index) => {
      if (!alphaNumericRegexp.test(number)) {
        setReferenceNumberError(
          "Reference Number should be all caps and numeric"
        );
        return false;
      }
      return reference_number.every((val, key) => {
        if (number === val && index !== key) {
          setReferenceNumberError(
            "Duplicate! Please enter correct reference numbers."
          );
          return false;
        }
        return true;
      });
    });
    return isValidReferenceNumber.every(Boolean);
  }
  async function handleExternalPayment() {
    if (!checkReferenceNumber()) {
      return;
    }
    setLoading(true);
    try {
      await callPutApi(`organisations/${id}?action=add_custom_payment`, {
        order_type: "PREPAID_PLAN_ORDER",
        payment_mode: paymentMethod,
        bank_ref_number: referenceNumber,
      });
      setLoading(false);
      onProceed();
    } catch (err: any) {
      setLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }
  return (
    <div>
      <div className="d-inline-flex py-2">
        <div
          id={IdsGenerator({
            prefix: "external payment",
            id: "click",
            sufix: "back",
          })}
          className="d-flex align-items-center"
          onClick={() => back()}
          style={{ cursor: "pointer" }}
        >
          <div className={`pr-1 ${Styles.rotate}`}>
            <ArrowDown color={SECONDARYCOLOR} />
          </div>
          <p className={`mb-0 ${Styles.back}`}>back</p>
        </div>
      </div>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-md-12 py-3">
            <KeyValuePair
              label="Order Id"
              value={planPaymentPending.order_id}
            />
          </div>
          <div className="col-md-6 py-3">
            <KeyValuePair label="Plan Id" value={planPaymentPending.plan_id} />
          </div>
          <div className="col-md-6 py-3">
            <KeyValuePair
              label="Plan Name"
              value={planPaymentPending.plan_name}
            />
          </div>
        </div>
        <div>
          <div className="container-fluid px-0 py-2">
            <div className="row">
              <div className="col-12">
                <p className={`mb-0 ${Styles.label}`}>Method</p>
              </div>
              <div className="col-md-4 py-2">
                <RadioButton
                  id={IdsGenerator({
                    prefix: "external payment",
                    id: "select",
                    sufix: "neft",
                  })}
                  label="NEFT"
                  disabled={country_code !== "IN"}
                  type="radio"
                  checked={paymentMethod === method.NEFT}
                  name="payment_method"
                  onChange={() => setPaymentMethod(method.NEFT)}
                />
              </div>
              <div className="col-md-4 py-2">
                <RadioButton
                  id={IdsGenerator({
                    prefix: "external payment",
                    id: "select",
                    sufix: "imps",
                  })}
                  label="IMPS"
                  type="radio"
                  checked={paymentMethod === method.IMPS}
                  name="payment_method"
                  onChange={() => setPaymentMethod(method.IMPS)}
                />
              </div>
              <div className="col-md-4 py-2">
                <RadioButton
                  id={IdsGenerator({
                    prefix: "external payment",
                    id: "select",
                    sufix: "rtgs",
                  })}
                  label="RTGS"
                  disabled={country_code !== "IN"}
                  type="radio"
                  checked={paymentMethod === method.RTGS}
                  name="payment_method"
                  onChange={() => setPaymentMethod(method.RTGS)}
                />
              </div>
            </div>
          </div>
          {(paymentMethod === method.NEFT ||
            paymentMethod === method.IMPS ||
            paymentMethod === method.RTGS) && (
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-md-12">
                  <Input
                    id={IdsGenerator({
                      prefix: "external payment",
                      id: `input ${paymentMethod}`,
                      sufix: "reference number",
                    })}
                    type="text"
                    name="reference_number"
                    autoComplete="off"
                    placeholder="Enter Reference Number"
                    labelname="Reference Number"
                    onChange={(event: any) => {
                      setReferenceNumber(event.target.value);
                      setReferenceNumberError("");
                    }}
                    onBlur={checkReferenceNumber}
                  />
                  {referenceNumberError && (
                    <ErrorMessage>{referenceNumberError}</ErrorMessage>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* Footer Section  */}
          <div
            className="py-4 my-2 d-flex align-items-center justify-content-center"
            style={{ borderTop: "1px solid #ECEEEF" }}
          >
            <div className="px-2">
              <Button
                id={IdsGenerator({
                  prefix: "external payment",
                  id: "click",
                  sufix: "cancel",
                })}
                size={MEDIUM}
                style={cancelButtonStyle}
                hoveredStyle={cancelButtonHoverStyle}
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
            </div>
            <div className="px-2">
              <Button
                id={IdsGenerator({
                  prefix: "external payment",
                  id: "click",
                  sufix: "proceed",
                })}
                size={MEDIUM}
                disabled={referenceNumber === "" || referenceNumberError !== ""}
                isLoading={loading}
                style={primaryButtonStyle}
                hoveredStyle={primaryButtonHoverStyle}
                onClick={() => handleExternalPayment()}
              >
                Proceed
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExternalPayment;
