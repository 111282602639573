//  Library utilities
import { Link } from "react-router-dom";

// Hooks
import React, { useContext, useEffect } from "react";

// constant
import {
  ACCENTCOLOR,
  DANGER,
  PRIMARYCOLOR,
  WARNING,
} from "../../../../shared/colors";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import { onboardingStatuses } from "../../../../constants/onboardingStatuses";
import { payment_status } from "../../../../constants/paymentStatus";

// svg
import RupeeSVG from "../../../../shared/svg/rupeeSvg";
import Sort from "../../../../shared/svg/sort";
import ArrowDown from "../../../../shared/svg/arrowDown";
import Close from "../../../../shared/svg/close";

// components
import Loading from "../../../../shared/loading/loading";
import { ToastContext } from "../../../../context/toastContext";
import Pagination from "../../../../shared/pagination/pagination";
import FilterDropdown from "../../../../shared/inTable-filter-dropdown/filterDropdown";

// functions
import { ERROR } from "../../../../utils/toastType";
import {
  getAmountWithGST,
  numberWithCommas,
  roundOfDecimal,
} from "../../../../utils/updateAmount";
import { useQuery } from "../../../../utils/customHooks";
import { ApmContext } from "../../../../utils/apmProvider";

// style
import Styles from "./planCustomer.module.scss";
import { IdsGenerator } from "../../../../utils/idsGenerator";

function PlanCustomer({ customersData }: { customersData: any }) {
  const query = useQuery();
  const filter = query.get("account_manager") || "";
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const sortTypes = {
    ascending: "Ascending",
    descending: "Descending",
  };
  const sortingColumns = {
    id: "id",
    name: "name",
    user_email: "user_email",
    onboarding_status: "onboarding_status",
    payment_status: "payment_status",
    current_balance: "current_balance",
    account_manager: "account_manager",
    business_manager: "business_manager",
  };
  const [filterBasedOnSorting, setFilterBasedOnSorting] = React.useState({
    sortType: "",
    sortingColumn: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [customerList, setCustomerList] = React.useState<any>([]);
  const [accountIdFilter, setAccountIdFilter] = React.useState<any>([]);
  const [accountNameFilter, setAccountNameFilter] = React.useState<any>([]);
  const [paymentStatusFilter, setPaymentStatusFilter] = React.useState<any>([]);
  const [accountManagerFilter, setAccountManagerFilter] = React.useState<any>(
    filter !== "" ? [filter] : []
  );
  const [businessManagerFilter, setBusinessManagerFilter] = React.useState<any>(
    filter !== "" ? [filter] : []
  );
  const [emailFilter, setemailFilter] = React.useState<any>([]);
  const [onboardingFilter, setOnboardingFilter] = React.useState<any>([]);
  const [filters, setFilters] = React.useState<any>([]);
  const [listOfIds, setListOfIds] = React.useState<any>([]);
  const [listOfNames, setListOfNames] = React.useState<any>([]);
  const [listOfEmail, setListOfEmail] = React.useState<any>([]);
  const [listOfOnboardingStatus, setListOfOnboardingStatus] =
    React.useState<any>([]);
  const [listOfPaymentStatus, setListOfPaymentStatus] = React.useState<any>([]);
  const [listOfAccountManager, setListOfAccountManager] = React.useState<any>(
    []
  );
  const [listOfBusinessManager, setListOfBusinessManager] = React.useState<any>(
    []
  );
  const [activePageNumber, setActivePageNumber] = React.useState(1);
  const [postPerPage] = React.useState(20);
  const dispatch = useContext(ToastContext);
  const indicator = (filteredArrayName: string[]) => {
    return (
      <div className="px-1">
        <div
          className={`${Styles.indicator} d-flex align-items-center justify-content-center`}
        >
          <p className={`mb-0 ${Styles.indicatorText}`}>
            {filteredArrayName.length}
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setFilters([
      ...accountIdFilter,
      ...accountNameFilter,
      ...paymentStatusFilter,
      ...accountManagerFilter,
      ...businessManagerFilter,
      ...emailFilter,
      ...onboardingFilter,
    ]);
    setActivePageNumber(1);
  }, [
    accountIdFilter,
    accountNameFilter,
    paymentStatusFilter,
    accountManagerFilter,
    businessManagerFilter,
    emailFilter,
    onboardingFilter,
  ]);

  useEffect(() => {
    function getAllCustomerAccounts() {
      setLoading(true);
      try {
        setLoading(false);
        const list = customersData.map((account: any) => ({
          id: account._id,
          name: account.name,
          env: account.environment,
          onboarding_status: onboardingStatuses.find(
            (status) => status.key === account.onboarding_status
          )?.value,
          payment_status: account?.payment_status
            ? [account.payment_status]
            : [],
          current_balance: roundOfDecimal(
            getAmountWithGST(account.wallet_balance)
          ).toFixed(2),
          account_manager: account.kam.kam_name ?? "Not Assigned",
          business_manager:
            account?.business_manager?.bm_name ?? "Not Assigned",
          user_email: account.user_email || "NA",
        }));
        setCustomerList(list);
      } catch (err: any) {
        setLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              err.response.data.err +
              " TraceId is \n" +
              apmTraceId(apmInstance),
            copy: apmTraceId(apmInstance),
          },
        });
      }
    }

    getAllCustomerAccounts();
  }, [dispatch]);

  const indexOfLastAccount = activePageNumber * postPerPage;
  const indexOfFirstAccount = indexOfLastAccount - postPerPage;

  // filter the accounts list
  const filteredCustomerAccounts = customerList.filter((account: any) => {
    const isStatusPresent = account.payment_status.filter((status: string) => {
      return filters.includes(status);
    });
    return (
      filters.length === 0 ||
      // eslint-disable-next-line
      ((accountIdFilter.length == 0 || filters.includes(account.id)) &&
        // eslint-disable-next-line
        (accountNameFilter.length == 0 || filters.includes(account.name)) &&
        // eslint-disable-next-line
        (accountManagerFilter.length == 0 ||
          filters.includes(account.account_manager)) &&
        // eslint-disable-next-line
        (businessManagerFilter.length == 0 ||
          filters.includes(account.business_manager)) &&
        // eslint-disable-next-line
        (paymentStatusFilter.length == 0 || isStatusPresent.length > 0) &&
        // eslint-disable-next-line
        (emailFilter.length == 0 || filters.includes(account.user_email)) &&
        // eslint-disable-next-line
        (onboardingFilter.length == 0 ||
          filters.includes(account.onboarding_status)))
    );
  });

  // Sort the column in descending order
  function sortDescending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = -1;
    } else if (leftParam < rightParam) {
      comparison = 1;
    }
    return comparison;
  }

  // Sort the column in ascending order
  function sortAscending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = 1;
    } else if (leftParam < rightParam) {
      comparison = -1;
    }
    return comparison;
  }

  // decide type of sort for specific column
  function sort(type: string, columnName: string) {
    const sortedArray = customerList.slice().sort((a: any, b: any) => {
      let leftParam;
      let rightParam;
      if (columnName === sortingColumns.current_balance) {
        leftParam = Number(a[columnName]);
        rightParam = Number(b[columnName]);
      } else {
        leftParam = a[columnName]?.toLowerCase();
        rightParam = b[columnName]?.toLowerCase();
      }
      if (type === sortTypes.ascending) {
        return sortAscending(leftParam, rightParam);
      } else {
        return sortDescending(leftParam, rightParam);
      }
    });
    setCustomerList(sortedArray);
  }

  // decide which sorting to apply based upon column name
  function applySorting(columnName: string) {
    if (
      filterBasedOnSorting.sortType === sortTypes.descending &&
      filterBasedOnSorting.sortingColumn === columnName
    ) {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: "",
        sortingColumn: columnName,
      }));
    } else if (
      filterBasedOnSorting.sortType === sortTypes.ascending &&
      filterBasedOnSorting.sortingColumn === columnName
    ) {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: sortTypes.descending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.descending, columnName);
    } else {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: sortTypes.ascending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.ascending, columnName);
    }
  }

  //only ascending and descending sorting for column, current balance
  function applyCurrentBalanceSorting(columnName: string) {
    if (
      filterBasedOnSorting.sortType === sortTypes.ascending &&
      filterBasedOnSorting.sortingColumn === columnName
    ) {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: sortTypes.descending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.descending, columnName);
    } else {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: sortTypes.ascending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.ascending, columnName);
    }
  }

  // remove particular filter form table filter
  function removeInTableFilter(filter: string) {
    if (accountIdFilter.includes(filter)) {
      setAccountIdFilter(
        accountIdFilter.filter((id: string) => {
          return id !== filter;
        })
      );
    } else if (accountNameFilter.includes(filter)) {
      setAccountNameFilter(
        accountNameFilter.filter((name: string) => {
          return name !== filter;
        })
      );
    } else if (emailFilter.includes(filter)) {
      setemailFilter(
        emailFilter.filter((status: string) => {
          return status !== filter;
        })
      );
    } else if (onboardingFilter.includes(filter)) {
      setOnboardingFilter(
        onboardingFilter.filter((status: string) => {
          return status !== filter;
        })
      );
    } else if (paymentStatusFilter.includes(filter)) {
      setPaymentStatusFilter(
        paymentStatusFilter.filter((status: string) => {
          return status !== filter;
        })
      );
    } else if (accountManagerFilter.includes(filter)) {
      setAccountManagerFilter(
        accountManagerFilter.filter((name: string) => {
          return name !== filter;
        })
      );
    } else if (businessManagerFilter.includes(filter)) {
      setBusinessManagerFilter(
        businessManagerFilter.filter((name: string) => {
          return name !== filter;
        })
      );
    }
  }

  // get all account id from the list
  function getAllAccountId() {
    setListOfIds(customerList.map((account: any) => account.id));
  }

  // get all account names from the list
  function getAllAccountName() {
    const names = customerList.map((account: any) => account.name);
    // Remove duplicate names form the array.
    setListOfNames(
      names.filter(
        (name: string, index: number) => names.indexOf(name) === index
      )
    );
  }

  // get all Email from the list
  function getAllUserEmail() {
    const emails = customerList.map((account: any) => account.user_email);
    setListOfEmail(
      emails.filter(
        (email: string, index: number) => emails.indexOf(email) === index
      )
    );
  }

  // get all onboarding statuses from the list
  function getAllOnboardingStatus() {
    const listOfStatus = customerList.map(
      (account: any) => account.onboarding_status
    );
    setListOfOnboardingStatus(
      listOfStatus.filter(
        (status: string, index: number) =>
          listOfStatus.indexOf(status) === index
      )
    );
  }

  // get all payment statuses from the list
  function getAllPaymentStatus() {
    const statusList: string[] = [];
    customerList.map((account: any) => [
      account.payment_status.map((status: string) => statusList.push(status)),
    ]);
    setListOfPaymentStatus(
      statusList.filter((status, index) => statusList.indexOf(status) === index)
    );
  }

  // get all account manager names from the list
  function getAllAccountManagers() {
    const names = customerList.map((account: any) => account.account_manager);
    setListOfAccountManager(
      names.filter(
        (name: string, index: number) => names.indexOf(name) === index
      )
    );
  }

  // get all business manager names from the list
  function getAllBusinessManagers() {
    const names = customerList.map((account: any) => account.business_manager);
    setListOfBusinessManager(
      names.filter(
        (name: string, index: number) => names.indexOf(name) === index
      )
    );
  }

  return (
    <div>
      <div>
        {/* Customer Accounts List is given below  */}
        <div className="col-md-12 p-2">
          <div className={`${sharedStyle.card}`}>
            <div className={`px-4 pt-4 ${sharedStyle.cardHeaderBack}`}>
              <div className="d-flex align-items-center pb-3">
                <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                  Customer Accounts
                </p>
                {/* <div className="ml-auto px-2">
                  <Button
                    style={primaryButtonStyle}
                    hoveredStyle={primaryButtonHoverStyle}
                    size={COMPRESSED}
                    onClick={handlePopupModalShow}
                    disabled={productFilterBtnDisabled}
                  >
                    Filter By Zoop Products
                  </Button> 
                </div> */}
              </div>
            </div>
            {filters.length !== 0 && (
              <div
                className={`p-2 d-flex align-items-center flex-row-reverse flex-wrap ${Styles.filterBack}`}
              >
                {filters.map((filter: any, index: number) => {
                  return (
                    <div className="p-1" key={index}>
                      <div
                        className={`d-flex align-items-center ${Styles.filterWrapper}`}
                      >
                        <p className={`mb-0 pl-2 ${Styles.filterName}`}>
                          {filter}
                        </p>
                        <div
                          id={IdsGenerator({
                            prefix: "plan customer",
                            id: "remove filter",
                            sufix: `${filter}`,
                          })}
                          className="px-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => removeInTableFilter(filter)}
                        >
                          <Close classes={Styles.close} width="8" height="8" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {loading ? (
              <div
                style={{ height: "50vh" }}
                className="d-flex align-items-center justify-content-center"
              >
                <Loading loadingColor={ACCENTCOLOR} />
              </div>
            ) : (
              <div>
                <div style={{ overflowX: "auto", minHeight: "450px" }}>
                  <table style={{ width: "100%" }}>
                    <thead className={sharedStyle.tableHeaderBack}>
                      <tr>
                        <th className={Styles.accID}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Acc/ID
                            </p>
                            {accountIdFilter.length !== 0 &&
                              indicator(accountIdFilter)}
                            <div className="px-2">
                              <Sort
                                id={IdsGenerator({
                                  prefix: "plan customer",
                                  id: "sort",
                                  sufix: "account name",
                                })}
                                classes={`${Styles.sort} ${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.id
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.id
                                    ? Styles.active
                                    : ""
                                }`}
                                click={() => applySorting(sortingColumns.id)}
                              />
                            </div>
                            <FilterDropdown
                              id={IdsGenerator({
                                prefix: "plan customer",
                                id: "dropdown",
                                sufix: "account name",
                              })}
                              width="300px"
                              optionArray={listOfIds}
                              filters={accountIdFilter}
                              onSelect={(option: any) => {
                                if (accountIdFilter.includes(option)) {
                                  setAccountIdFilter(
                                    accountIdFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setAccountIdFilter([
                                  ...accountIdFilter,
                                  option,
                                ]);
                              }}
                              toggleReset={accountIdFilter.length !== 0}
                              onReset={() => setAccountIdFilter([])}
                            >
                              <div
                                id="plan-customer-get-all-account-id"
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllAccountId()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>
                        <th className={Styles.name}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Name
                            </p>
                            {accountNameFilter.length !== 0 &&
                              indicator(accountNameFilter)}
                            <div className="px-2">
                              <Sort
                                id={IdsGenerator({
                                  prefix: "plan customer",
                                  id: "sort",
                                  sufix: "names",
                                })}
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.name
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.name
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() => applySorting(sortingColumns.name)}
                              />
                            </div>
                            <FilterDropdown
                              id={IdsGenerator({
                                prefix: "plan customer",
                                id: "dropdown",
                                sufix: "names",
                              })}
                              width="300px"
                              optionArray={listOfNames}
                              filters={accountNameFilter}
                              onSelect={(option: any) => {
                                if (accountNameFilter.includes(option)) {
                                  setAccountNameFilter(
                                    accountNameFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setAccountNameFilter([
                                  ...accountNameFilter,
                                  option,
                                ]);
                              }}
                              toggleReset={accountNameFilter.length !== 0}
                              onReset={() => setAccountNameFilter([])}
                            >
                              <div
                                id="plan-customer-get-all-account-name"
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllAccountName()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>
                        <th className={Styles.email}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Email
                            </p>
                            {emailFilter.length !== 0 && indicator(emailFilter)}
                            <div className="px-2">
                              <Sort
                                id={IdsGenerator({
                                  prefix: "plan customer",
                                  id: "sort",
                                  sufix: "emails",
                                })}
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.user_email
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.user_email
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() =>
                                  applySorting(sortingColumns.user_email)
                                }
                              />
                            </div>
                            <FilterDropdown
                              id={IdsGenerator({
                                prefix: "plan customer",
                                id: "dropdown",
                                sufix: "emails",
                              })}
                              width="200px"
                              optionArray={listOfEmail}
                              filters={emailFilter}
                              onSelect={(option: any) => {
                                if (emailFilter.includes(option)) {
                                  setemailFilter(
                                    emailFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setemailFilter([...emailFilter, option]);
                              }}
                              toggleReset={emailFilter.length !== 0}
                              onReset={() => setemailFilter([])}
                            >
                              <div
                                id="plan-customer-get-all-user-email"
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllUserEmail()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>
                        <th className={Styles.onboarding_status}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Onboarding Status
                            </p>
                            {onboardingFilter.length !== 0 &&
                              indicator(onboardingFilter)}
                            <div className="px-2">
                              <Sort
                                id={IdsGenerator({
                                  prefix: "plan customer",
                                  id: "sort",
                                  sufix: "Onboarding Status",
                                })}
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.onboarding_status
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.onboarding_status
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() =>
                                  applySorting(sortingColumns.onboarding_status)
                                }
                              />
                            </div>
                            <FilterDropdown
                              id={IdsGenerator({
                                prefix: "plan customer",
                                id: "dropdown",
                                sufix: "Onboarding Status",
                              })}
                              width="300px"
                              optionArray={listOfOnboardingStatus}
                              filters={onboardingFilter}
                              onSelect={(option: any) => {
                                if (onboardingFilter.includes(option)) {
                                  setOnboardingFilter(
                                    onboardingFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setOnboardingFilter([
                                  ...onboardingFilter,
                                  option,
                                ]);
                              }}
                              toggleReset={onboardingFilter.length !== 0}
                              onReset={() => setOnboardingFilter([])}
                            >
                              <div
                                id="plan-customer-get-all-onboarding-status"
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllOnboardingStatus()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>
                        <th className={Styles.payment_status}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Payment Status
                            </p>
                            {paymentStatusFilter.length !== 0 &&
                              indicator(paymentStatusFilter)}
                            <div className="px-2">
                              <FilterDropdown
                                id={IdsGenerator({
                                  prefix: "plan customer",
                                  id: "dropdown",
                                  sufix: "Payment Status",
                                })}
                                width="300px"
                                optionArray={listOfPaymentStatus}
                                filters={paymentStatusFilter}
                                onSelect={(option: any) => {
                                  if (paymentStatusFilter.includes(option)) {
                                    setPaymentStatusFilter(
                                      paymentStatusFilter.filter(
                                        (data: any) => data !== option
                                      )
                                    );
                                    return;
                                  }
                                  setPaymentStatusFilter([
                                    ...paymentStatusFilter,
                                    option,
                                  ]);
                                }}
                                toggleReset={paymentStatusFilter.length !== 0}
                                onReset={() => setPaymentStatusFilter([])}
                              >
                                <div
                                  id="plan-customer-get-all-payment-status"
                                  className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                  onClick={() => getAllPaymentStatus()}
                                >
                                  <ArrowDown classes={Styles.arrow} />
                                </div>
                              </FilterDropdown>
                            </div>
                          </div>
                        </th>
                        <th className={Styles.amount}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Current Balance
                            </p>
                            <div className="px-2">
                              <Sort
                                id={IdsGenerator({
                                  prefix: "plan customer",
                                  id: "sort",
                                  sufix: "current balance",
                                })}
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.current_balance
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.current_balance
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() =>
                                  applyCurrentBalanceSorting(
                                    sortingColumns.current_balance
                                  )
                                }
                              />
                            </div>
                          </div>
                        </th>
                        <th className={Styles.account_manager}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Account Manager
                            </p>
                            {accountManagerFilter.length !== 0 &&
                              indicator(accountManagerFilter)}
                            <div className="px-2">
                              <Sort
                                id={IdsGenerator({
                                  prefix: "plan customer",
                                  id: "sort",
                                  sufix: "account manager",
                                })}
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.account_manager
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.account_manager
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() =>
                                  applySorting(sortingColumns.account_manager)
                                }
                              />
                            </div>
                            <FilterDropdown
                              id={IdsGenerator({
                                prefix: "plan customer",
                                id: "dropdown",
                                sufix: "account manager",
                              })}
                              width="300px"
                              optionArray={listOfAccountManager}
                              filters={accountManagerFilter}
                              onSelect={(option: any) => {
                                if (accountManagerFilter.includes(option)) {
                                  setAccountManagerFilter(
                                    accountManagerFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setAccountManagerFilter([
                                  ...accountManagerFilter,
                                  option,
                                ]);
                              }}
                              toggleReset={accountManagerFilter.length !== 0}
                              onReset={() => setAccountManagerFilter([])}
                            >
                              <div
                                id="plan-customer-get-all-account-manager"
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllAccountManagers()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>
                        <th className={Styles.account_manager}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Business Manager
                            </p>
                            {businessManagerFilter.length !== 0 &&
                              indicator(businessManagerFilter)}
                            <div className="px-2">
                              <Sort
                                id={IdsGenerator({
                                  prefix: "plan customer",
                                  id: "sort",
                                  sufix: "business manager",
                                })}
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.business_manager
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.business_manager
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() =>
                                  applySorting(sortingColumns.business_manager)
                                }
                              />
                            </div>
                            <FilterDropdown
                              id={IdsGenerator({
                                prefix: "plan customer",
                                id: "dropdown",
                                sufix: "business manager",
                              })}
                              width="300px"
                              optionArray={listOfBusinessManager}
                              filters={businessManagerFilter}
                              onSelect={(option: any) => {
                                if (businessManagerFilter.includes(option)) {
                                  setBusinessManagerFilter(
                                    businessManagerFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setBusinessManagerFilter([
                                  ...businessManagerFilter,
                                  option,
                                ]);
                              }}
                              toggleReset={businessManagerFilter.length !== 0}
                              onReset={() => setBusinessManagerFilter([])}
                            >
                              <div
                                id="plan-customer-get-all-business-manager"
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllBusinessManagers()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredCustomerAccounts.length !== 0 ? (
                        filteredCustomerAccounts
                          .slice(indexOfFirstAccount, indexOfLastAccount)
                          .map((account: any, index: number) => {
                            return (
                              <tr
                                key={index}
                                style={{ borderBottom: "1px solid #eceeef" }}
                              >
                                <td className={Styles.accID}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {account.id}
                                  </p>
                                </td>
                                <td
                                  id={IdsGenerator({
                                    prefix: "plan customer",
                                    id: "click link",
                                    sufix: `${account.name}`,
                                  })}
                                  className={Styles.name}
                                >
                                  <Link
                                    to={`/application/accounts/${account.id}`}
                                    className={`mb-0 ${Styles.accountName}`}
                                  >
                                    {account.name}
                                  </Link>
                                </td>
                                <td className={Styles.email}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {account.user_email}
                                  </p>
                                </td>
                                <td className={Styles.onboarding_status}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {account.onboarding_status}
                                  </p>
                                </td>
                                <td className={Styles.payment_status}>
                                  <div className="d-flex align-items-center flex-wrap">
                                    {account.payment_status.length > 0
                                      ? account.payment_status.map(
                                          (status: string, index: number) => {
                                            return (
                                              <div
                                                className="px-2 py-1 d-inline-flex m-1"
                                                key={index}
                                                style={{
                                                  borderRadius: "36px",
                                                  border: `1px solid ${
                                                    status ===
                                                    (payment_status.low_balance ||
                                                      payment_status.plan_expired)
                                                      ? DANGER
                                                      : WARNING
                                                  }`,
                                                }}
                                              >
                                                <p
                                                  className={`mb-0`}
                                                  style={{
                                                    fontSize: "11px",
                                                    whiteSpace: "nowrap",
                                                    lineHeight: 1.3,
                                                    color:
                                                      status ===
                                                      (payment_status.low_balance ||
                                                        payment_status.plan_expired)
                                                        ? DANGER
                                                        : WARNING,
                                                  }}
                                                >
                                                  {status}
                                                </p>
                                              </div>
                                            );
                                          }
                                        )
                                      : "NA"}
                                  </div>
                                </td>
                                <td className={Styles.amount}>
                                  <div className="d-flex align-items-center">
                                    <div className="pr-2">
                                      <RupeeSVG
                                        width="8"
                                        height="12"
                                        color={PRIMARYCOLOR}
                                      />
                                    </div>
                                    <p
                                      className={`mb-0 ${Styles.tableBodyText}`}
                                    >
                                      {numberWithCommas(
                                        account.current_balance
                                      )}
                                    </p>
                                  </div>
                                </td>
                                <td className={Styles.account_manager}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {account.account_manager}
                                  </p>
                                </td>
                                <td className={Styles.account_manager}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {account.business_manager}
                                  </p>
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr
                          style={{ height: "400px" }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <td className="mb-0">No Accounts found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="px-4 py-3 d-flex align-items-center">
                  <p className={`mb-0 ${Styles.numberOfRows}`}>
                    {
                      filteredCustomerAccounts.slice(
                        indexOfFirstAccount,
                        indexOfLastAccount
                      ).length
                    }{" "}
                    rows per page
                  </p>
                  {filteredCustomerAccounts.length > 0 && (
                    <div className="py-2 ml-auto">
                      <Pagination
                        id={IdsGenerator({
                          prefix: "plan customer",
                          id: "pagination",
                          sufix: "section",
                        })}
                        activePageNumber={activePageNumber}
                        total={filteredCustomerAccounts.length}
                        postPerPage={postPerPage}
                        navigateToPage={(value: number) => {
                          setActivePageNumber(value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanCustomer;
