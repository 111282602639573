// Library Utilities
import { Link } from "react-router-dom";

// Hooks
import React, { useContext, useEffect } from "react";

// functions
import { callGetApi } from "../../../../api/axios";
import { getDuration } from "../../../../utils/getDuration";
import { getDurationStyle } from "../../../../utils/getDurationStyle";
import { monthName } from "../../../../utils/getMonth";
import Sort from "../../../../shared/svg/sort";
import { ApmContext } from "../../../../utils/apmProvider";

// components
import Loading from "../../../../shared/loading/loading";
import { ToastContext } from "../../../../context/toastContext";
import { ERROR } from "../../../../utils/toastType";

// constants
import { ACCENTCOLOR } from "../../../../shared/colors";
import { durationStyles } from "../../../../constants/durationStyles";

// styles
import Styles from "../home.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";

function AccountActivationPendingCard() {
  const sortTypes = {
    ascending: "ascending",
    descending: "descending",
  };

  const { apmInstance, traceId, apmTraceId } = useContext(ApmContext);
  const [pendingAccountActivationLoading, setPendingAccountActivationLoading] =
    React.useState(false);
  const [pendingAccountActivationList, setPendingAccountActivationList] =
    React.useState([]);
  const [defaultPendingActivationList, setDefaultPendingActivationList] =
    React.useState([]);
  const [sortedRequestedAt, setSortedRequestedAt] = React.useState({
    sortType: sortTypes.descending,
  });
  const dispatch = useContext(ToastContext);
  useEffect(() => {
    async function getPendingProductApproval() {
      setPendingAccountActivationLoading(true);
      try {
        const response: any = await callGetApi(
          "/organisations?status=account_activation_requested"
        );
        const pendingAccountActivation = response.data.map((data: any) => ({
          name: data.name,
          country_code: data.country_code,
          kam_name: data?.kam?.kam_name ?? "NA",
          requested_at: data?.activation_requested_at,
          id: data._id,
        }));
        setDefaultPendingActivationList(pendingAccountActivation);
        pendingAccountActivation.sort((a: any, b: any) => {
          return b.requested_at - a.requested_at;
        });
        setPendingAccountActivationList(pendingAccountActivation);
        setPendingAccountActivationLoading(false);
      } catch (error: any) {
        setPendingAccountActivationLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              error.response.data.err +
              " TraceId is \n" +
              apmTraceId(apmInstance),
          },
        });
      }
    }
    getPendingProductApproval();
  }, [dispatch]);
  const sortTheRequestedAtDate = () => {
    if (sortedRequestedAt.sortType === sortTypes.descending) {
      setPendingAccountActivationList((prevState) =>
        prevState.sort((a: any, b: any) => {
          return a.requested_at - b.requested_at;
        })
      );
      setSortedRequestedAt((sortedRequestedAt) => ({
        ...sortedRequestedAt,
        sortType: sortTypes.ascending,
      }));
    } else if (sortedRequestedAt.sortType === sortTypes.ascending) {
      setPendingAccountActivationList([...defaultPendingActivationList]);
      setSortedRequestedAt((sortedRequestedAt) => ({
        ...sortedRequestedAt,
        sortType: "",
      }));
    } else {
      setPendingAccountActivationList((prevState) =>
        prevState.sort((a: any, b: any) => {
          return b.requested_at - a.requested_at;
        })
      );
      setSortedRequestedAt((sortedRequestedAt) => ({
        ...sortedRequestedAt,
        sortType: sortTypes.descending,
      }));
    }
  };
  return (
    <div className="col-lg-6 p-3">
      <div className={sharedStyle.card}>
        <div className={`px-4 pt-4 ${sharedStyle.cardHeaderBack}`}>
          <div className="d-flex align-items-center pb-3">
            <p className={`mb-0 ${sharedStyle.cardHeader}`}>
              Pending Account Activation
            </p>
            {pendingAccountActivationList.length > 15 ? (
              <div className="px-2 ml-auto">
                <Link
                  to={"/application/home/pending-account-activation"}
                  className={`mb-0 ${Styles.link}`}
                >
                  View More
                </Link>
              </div>
            ) : null}
          </div>
        </div>
        {pendingAccountActivationLoading ? (
          <div
            style={{ height: "300px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : pendingAccountActivationList.length > 0 ? (
          <div style={{ overflowX: "auto" }}>
            <table style={{ width: "100%" }}>
              <thead className={sharedStyle.tableHeaderBack}>
                <tr>
                  <th className={Styles.cellPadding}>
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Account Name
                    </p>
                  </th>
                  <th className={Styles.country_code}>
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Country
                    </p>
                  </th>
                  <th className={Styles.cellPadding}>
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Account Manager
                    </p>
                  </th>
                  <th
                    className={Styles.cellPadding}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                      Requested On
                    </p>
                    <Sort
                      id="account-activation-pending-sort"
                      style={{ marginLeft: "4px" }}
                      className={`${Styles.sort} ${
                        sortedRequestedAt.sortType === sortTypes.descending
                          ? Styles.rotate
                          : ""
                      } ${
                        sortedRequestedAt.sortType !== "" ? Styles.active : ""
                      } `}
                      onClick={sortTheRequestedAtDate}
                    />
                  </th>
                </tr>
              </thead>
              <tbody className={Styles.tableWrapper}>
                {pendingAccountActivationList
                  .slice(0, 15)
                  .map((account: any, index: number) => {
                    const date = new Date(Number(account.requested_at));
                    const duration = getDuration(date);
                    const style = getDurationStyle(duration, 7, 3);
                    return (
                      <tr
                        key={index}
                        style={
                          index !== pendingAccountActivationList.length - 1
                            ? { borderBottom: "1px solid #eceeef" }
                            : { borderBottom: 0 }
                        }
                      >
                        <td className={Styles.cellPadding}>
                          <Link
                            to={`/application/accounts/${account.id}`}
                            className={`mb-0 ${Styles.accountName}`}
                          >
                            {account.name}
                          </Link>
                        </td>
                        <td className={Styles.country_code}>
                          <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                            {account.country_code}
                          </p>
                        </td>
                        <td className={Styles.cellPadding}>
                          <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                            {account.kam_name}
                          </p>
                        </td>
                        <td className={Styles.cellPadding}>
                          {account.requested_at ? (
                            <div
                              className={`mb-0 ${Styles.duration}`}
                              style={
                                style === durationStyles.DANGER
                                  ? { color: "#d64747" }
                                  : style === durationStyles.WARNING
                                  ? { color: "#f4a63b" }
                                  : { color: "#31b975" }
                              }
                            >
                              {duration}
                            </div>
                          ) : null}
                          <p className={`mb-0 ${sharedStyle.tableBodyText}`}>
                            {account.requested_at
                              ? `${monthName(
                                  date.getMonth()
                                )} ${date.getDate()}, ${date.getFullYear()}`
                              : "NA"}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            style={{ height: "300px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <p className="mb-0">No Account Found</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default AccountActivationPendingCard;
