// Hooks & Utils
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContext } from "../../../../../context/toastContext";
import { ERROR } from "../../../../../utils/toastType";

// Components
import KeyValuePair from "../../../../../shared/key-value-pair/keyValuePair";
import ModifyAccountManager from "../modify-account-manager/modifyAccountManager";
import Loading from "../../../../../shared/loading/loading";
import BusinessInfo from "./business-info/businessInfo";
import PocDetails from "./poc-details/pocDetails";
import AdditionalInfo from "./additional-info/additionalInfo";
import ModifyBusinessManager from "../modify-business-manager/modifyBusinessManager";
import InternalIntegration from "./internal-integration/internalIntegration";

// constants
import { ACCENTCOLOR } from "../../../../../shared/colors";
import UpdateSvg from "../../../../../shared/svg/updateSvg";
import { orgStatus } from "../../../../../constants/orgStatus";

// Styles
import Styles from "./accountDetails.module.scss";
import sharedStyle from "../../../../../shared/sharedStyle.module.scss";
import { IdsGenerator } from "../../../../../utils/idsGenerator";

function AccountDetails({
  accountDetails,
  isLoading,
  countryCodes,
  categories,
  updateAccountDetails,
  is_allowed_to_write,
}: any) {
  const { id }: any = useParams();
  const dispatch = useContext(ToastContext);
  const [toggleModifyAccountManagerPopUp, setToggleModifyAccountManagerPopUp] =
    React.useState({
      action: "",
      toggle: false,
    });

  const [
    toggleModifyBusinessManagerPopUp,
    setToggleModifyBusinessManagerPopUp,
  ] = React.useState({
    action: "",
    toggle: false,
  });

  const [accountInfo, setAccountInfo] = React.useState<any>();
  const [businessPocInfo, setBusinessPocInfo] = React.useState();
  const [technicalPocInfo, setTechnicalPocInfo] = React.useState();

  const loading = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );

  useEffect(() => {
    setAccountInfo({
      ...accountDetails,
      category_id: categories.find(
        (item: any) => item?._id === accountDetails?.category_id?._id
      ),
    });
  }, [accountDetails]);

  useEffect(() => {
    setBusinessPocInfo(accountInfo?.business_poc);
    setTechnicalPocInfo(accountInfo?.technical_poc);
  }, [accountInfo]);

  return (
    <div>
      <div className="py-3 container-fluid">
        <div className="row">
          {/* BUSINESS INFO SECTION  */}
          <div className="col-lg-6 py-3">
            <BusinessInfo
              isLoading={isLoading}
              accountInfo={accountInfo}
              countryCodes={countryCodes}
              categories={categories}
              is_allowed_to_write={is_allowed_to_write}
              updatedBusinessInfo={(businessInfo: any) => {
                setAccountInfo((accountInfo: any) => ({
                  ...accountInfo,
                  user_email: businessInfo.user_email,
                  name: businessInfo.name,
                  country_code: businessInfo.country_code,
                  category: businessInfo.category,
                  trade_name: businessInfo.trade_name,
                  user_name: businessInfo.user_name,
                  category_id: businessInfo.category_id,
                }));
                updateAccountDetails({
                  user_email: businessInfo.user_email,
                  name: businessInfo.name,
                  country_code: businessInfo.country_code,
                  category: businessInfo.category,
                  trade_name: businessInfo.trade_name,
                  user_name: businessInfo.user_name,
                  category_id: businessInfo.category_id,
                });
              }}
            />
          </div>

          {/* ACCOUNT MANAGER SECTION */}
          <div className="col-lg-6 py-3">
            <div className={sharedStyle.card}>
              <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
                <div className="d-flex align-items-center">
                  <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                    Account Manager
                  </p>
                  <div className="ml-auto">
                    {is_allowed_to_write ? (
                      <div className="ml-auto">
                        {isLoading ? (
                          <div>--</div>
                        ) : accountDetails?.status ===
                          orgStatus.DISABLED ? null : accountInfo?.kam
                            ?.kam_name || accountInfo?.kam?.kam_email ? (
                          <UpdateSvg
                            id={IdsGenerator({
                              prefix: "account manager",
                              id: "update",
                              sufix: "svg",
                            })}
                            click={() => {
                              setToggleModifyAccountManagerPopUp(
                                (toggleModifyAccountManagerPopUp) => ({
                                  ...toggleModifyAccountManagerPopUp,
                                  action: "Change",
                                  toggle: true,
                                })
                              );
                            }}
                          />
                        ) : (
                          <p
                            className={`mb-0 py-2 ${Styles.accountManagerAction}`}
                            id="account-details-assign-account-manager"
                            onClick={
                              () =>
                                accountInfo?.bm?.bm_name ||
                                accountInfo?.bm?.bm_email
                                  ? setToggleModifyAccountManagerPopUp(
                                      (toggleModifyAccountManagerPopUp) => ({
                                        ...toggleModifyAccountManagerPopUp,
                                        action: "Assign",
                                        toggle: true,
                                      })
                                    )
                                  : dispatch({
                                      type: "ADD_TOAST",
                                      payload: {
                                        id: Math.floor(Math.random() * 100),
                                        type: ERROR,
                                        message:
                                          "Business Manager is not Assigned!",
                                      },
                                    }) //toast-check
                            }
                          >
                            Assign
                          </p>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {isLoading ? (
                loading
              ) : (
                <div className="p-4">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <KeyValuePair
                          className="py-4"
                          label="Name"
                          value={
                            accountInfo?.kam?.kam_name
                              ? accountInfo?.kam.kam_name
                              : "NA"
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <KeyValuePair
                          className="py-4"
                          label="Contact"
                          value={
                            accountInfo?.kam?.kam_phone
                              ? accountInfo?.kam.kam_phone
                              : "NA"
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <KeyValuePair
                          className="py-4"
                          label="Email"
                          value={
                            accountInfo?.kam?.kam_email
                              ? accountInfo?.kam.kam_email
                              : "NA"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          {/* Bussiness MANAGER SECTION */}
          <div className="col-lg-6 py-3">
            <div className={sharedStyle.card}>
              <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
                <div className="d-flex align-items-center">
                  <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                    Business Manager
                  </p>
                  <div className="ml-auto">
                    {isLoading ? (
                      <div>--</div>
                    ) : !is_allowed_to_write ? null : accountDetails?.status ===
                      orgStatus.DISABLED ? null : accountInfo?.bm?.bm_name ||
                      accountInfo?.bm?.bm_email ? (
                      <UpdateSvg
                        id={IdsGenerator({
                          prefix: "business manager",
                          id: "update",
                          sufix: "svg",
                        })}
                        click={() => {
                          setToggleModifyBusinessManagerPopUp(
                            (toggleModifyBusinessManagerPopUp) => ({
                              ...toggleModifyBusinessManagerPopUp,
                              action: "Change",
                              toggle: true,
                            })
                          );
                        }}
                      />
                    ) : (
                      <p
                        className={`mb-0 py-2 ${Styles.accountManagerAction}`}
                        id={IdsGenerator({
                          prefix: "business manager",
                          id: "assign",
                          sufix: "svg",
                        })}
                        onClick={() =>
                          setToggleModifyBusinessManagerPopUp(
                            (toggleModifyBusinessManagerPopUp) => ({
                              ...toggleModifyBusinessManagerPopUp,
                              action: "Assign",
                              toggle: true,
                            })
                          )
                        }
                      >
                        Assign
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {isLoading ? (
                loading
              ) : (
                <div className="p-4">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <KeyValuePair
                          className="py-4"
                          label="Name"
                          value={
                            accountInfo?.bm?.bm_name
                              ? accountInfo?.bm.bm_name
                              : "NA"
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <KeyValuePair
                          className="py-4"
                          label="Contact"
                          value={
                            accountInfo?.bm?.bm_phone
                              ? accountInfo?.bm.bm_phone
                              : "NA"
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <KeyValuePair
                          className="py-4"
                          label="Email"
                          value={
                            accountInfo?.bm?.bm_email
                              ? accountInfo?.bm.bm_email
                              : "NA"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* BUSINESS POC SECTION  */}
          <div className="col-lg-6 py-3">
            <PocDetails
              id="bussiness poc"
              pocType="Business"
              pocInfo={businessPocInfo}
              isLoading={isLoading}
              accountInfo={accountInfo}
              apiUrl={`/organisations/${id}?action=update_business_poc`}
              onUpdatePocInfo={(pocInfo: any) => {
                setAccountInfo((accountInfo: any) => ({
                  ...accountInfo,
                  business_poc: pocInfo,
                }));
                updateAccountDetails({
                  business_poc: pocInfo,
                });
              }}
              is_allowed_to_write={is_allowed_to_write}
            />
          </div>
        </div>

        {/* TECHNICAL POC SECTION  */}
        <div className="row">
          <div className="col-lg-12 py-3">
            <PocDetails
              id="technical poc"
              pocType="Technical"
              pocInfo={technicalPocInfo}
              isLoading={isLoading}
              accountInfo={accountInfo}
              apiUrl={`/organisations/${id}?action=update_technical_poc`}
              onUpdatePocInfo={(pocInfo: any) => {
                setAccountInfo((accountInfo: any) => ({
                  ...accountInfo,
                  technical_poc: pocInfo,
                }));
                updateAccountDetails({
                  technical_poc: pocInfo,
                });
              }}
              is_allowed_to_write={is_allowed_to_write}
            />
          </div>
        </div>
        {/*         
          <div className="row">
            <div className="col-lg-12 py-3">
              <CrmInfo
                crm_id={accountInfo?.crm_org_id}
                org_id={accountInfo?._id}
              />
            </div>
          </div> 
        */}
        <div className="row">
          <div className="col-lg-12 py-3">
            <InternalIntegration
              accountInfo={accountInfo}
              org_id={accountInfo?._id}
              is_allowed_to_write={is_allowed_to_write}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 py-3">
            <AdditionalInfo
              accountInfo={accountInfo}
              org_id={accountInfo?._id}
              is_allowed_to_write={is_allowed_to_write}
            />
          </div>
        </div>
      </div>
      {toggleModifyAccountManagerPopUp.toggle && (
        <ModifyAccountManager
          accountInfo={accountInfo}
          action={toggleModifyAccountManagerPopUp.action}
          onCancel={() =>
            setToggleModifyAccountManagerPopUp(
              (toggleModifyAccountManagerPopUp) => ({
                ...toggleModifyAccountManagerPopUp,
                toggle: false,
              })
            )
          }
          onUpdate={(kam: any) => {
            setToggleModifyAccountManagerPopUp(
              (toggleModifyAccountManagerPopUp) => ({
                ...toggleModifyAccountManagerPopUp,
                toggle: false,
              })
            );
            setAccountInfo((accountInfo: any) => ({
              ...accountInfo,
              kam: kam,
            }));
            updateAccountDetails({
              kam: kam,
            });
          }}
          org_id={id}
        />
      )}
      {toggleModifyBusinessManagerPopUp.toggle && (
        <ModifyBusinessManager
          accountInfo={accountInfo}
          action={toggleModifyBusinessManagerPopUp.action}
          onCancel={() =>
            setToggleModifyBusinessManagerPopUp(
              (toggleModifyBusinessManagerPopUp) => ({
                ...toggleModifyBusinessManagerPopUp,
                toggle: false,
              })
            )
          }
          onUpdate={(business_manager: any) => {
            setToggleModifyBusinessManagerPopUp(
              (toggleModifyBusinessManagerPopUp) => ({
                ...toggleModifyBusinessManagerPopUp,
                toggle: false,
              })
            );
            setAccountInfo((accountInfo: any) => ({
              ...accountInfo,
              bm: business_manager,
            }));
            updateAccountDetails({
              bm: business_manager,
            });
          }}
          org_id={id}
        />
      )}
      {/*  {false && <ModifyBusinessManager />} */}
    </div>
  );
}

export default AccountDetails;
