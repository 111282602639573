// Hooks
import React, { useContext } from "react";

// Constants
import { COMPRESSED } from "../../../../../../shared/buttonSize";
import { ERROR } from "../../../../../../utils/toastType";
import { orgStatus } from "../../../../../../constants/orgStatus";

// Styles
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../../../shared/buttonStyles";
import { ACCENTCOLOR } from "../../../../../../shared/colors";

// Components
import Button from "../../../../../../shared/button/button";
import ErrorMessage from "../../../../../../shared/error-message/errorMessage";
import InputDropdown from "../../../../../../shared/input-dropdown/inputDropdown";
import Input from "../../../../../../shared/input/input";
import KeyValuePair from "../../../../../../shared/key-value-pair/keyValuePair";
import Loading from "../../../../../../shared/loading/loading";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import UpdateSvg from "../../../../../../shared/svg/updateSvg";
import { ToastContext } from "../../../../../../context/toastContext";

// Functions
import validator from "validator";
import { callPutApi } from "../../../../../../api/axios";
import {
  checkIsEmpty,
  isValidEmail,
  isValidName,
} from "../../../../../../utils/validation";
import { ApmContext } from "../../../../../../utils/apmProvider";
import { IdsGenerator } from "../../../../../../utils/idsGenerator";

function BusinessInfo({
  isLoading,
  accountInfo = {},
  countryCodes,
  categories,
  updatedBusinessInfo,
  is_allowed_to_write,
}: any) {
  const [toggleUpdateBusinessInfo, setToggleUpdateBusinessInfo] =
    React.useState(false);

  const [tempAccountInfo, setTempAccountInfo] =
    React.useState<any>(accountInfo);
  const [businessInfoLoading, setBusinessInfoLoading] = React.useState(false);
  const dispatch = useContext(ToastContext);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [error, setError] = React.useState({
    name_error: "",
    category_error: "",
    country_code_error: "",
    trader_name: "",
    user_email: "",
    user_name_error: "",
  });

  const [validData, setValidData] = React.useState({
    valid_name: false,
    valid_category: false,
    valid_country_code: false,
    valid_trader_name: false,
    valid_user_email: false,
    valid_user_name: false,
  });

  const loading = (
    <div
      style={{ height: "250px" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loading loadingColor={ACCENTCOLOR} />
    </div>
  );

  function checkRegisteredEmail() {
    if (checkIsEmpty(tempAccountInfo?.user_email)) {
      setError((error) => ({
        ...error,
        user_email: "Email cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        valid_user_email: false,
      }));
      return false;
    }
    if (!isValidEmail(tempAccountInfo?.user_email)) {
      setError((error) => ({
        ...error,
        user_email: "Invalid Email Format",
      }));
      setValidData((validData) => ({
        ...validData,
        valid_user_email: false,
      }));
      return false;
    }
    return true;
  }

  function checkBusinessName() {
    if (checkIsEmpty(tempAccountInfo?.name)) {
      setError((error) => ({
        ...error,
        name_error: "Name cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        valid_name: false,
      }));
      return false;
    }
    if (isValidName(tempAccountInfo?.name, true)) {
      setError((error) => ({
        ...error,
        name_error: "Enter a valid name",
      }));
      setValidData((validData) => ({
        ...validData,
        valid_name: false,
      }));
      return false;
    }
    if (validator.isNumeric(tempAccountInfo?.name[0], { no_symbols: true })) {
      setError((error) => ({
        ...error,
        name_error: "Business name should not start with a number",
      }));
      setValidData((validData) => ({
        ...validData,
        valid_name: false,
      }));
      return false;
    }
    return true;
  }

  function checkUserName() {
    if (checkIsEmpty(tempAccountInfo?.user_name || "")) {
      setError((error) => ({
        ...error,
        user_name_error: "Name cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        valid_user_name: false,
      }));
      return false;
    }
    if (isValidName(tempAccountInfo?.user_name, true)) {
      setError((error) => ({
        ...error,
        user_name_error: "Enter a valid name",
      }));
      setValidData((validData) => ({
        ...validData,
        valid_user_name: false,
      }));
      return false;
    }
    if (
      validator.isNumeric(tempAccountInfo?.user_name[0], { no_symbols: true })
    ) {
      setError((error) => ({
        ...error,
        user_name_error: "user name should not start with a number",
      }));
      setValidData((validData) => ({
        ...validData,
        valid_user_name: false,
      }));
      return false;
    }
    return true;
  }

  function checkTraderName() {
    if (checkIsEmpty(tempAccountInfo?.trade_name || "")) {
      setError((error) => ({
        ...error,
        trader_name: "Trader name cannot be empty",
      }));
      setValidData((validData) => ({
        ...validData,
        valid_trader_name: false,
      }));
      return false;
    }
    if (isValidName(tempAccountInfo?.trade_name, true)) {
      setError((error) => ({
        ...error,
        trader_name: "Enter a valid name",
      }));
      setValidData((validData) => ({
        ...validData,
        valid_trader_name: false,
      }));
      return false;
    }
    if (
      validator.isNumeric(tempAccountInfo?.trade_name[0], { no_symbols: true })
    ) {
      setError((error) => ({
        ...error,
        trader_name: "Business name should not start with a number",
      }));
      setValidData((validData) => ({
        ...validData,
        valid_trader_name: false,
      }));
      return false;
    }
    return true;
  }

  function checkBusinessCategory() {
    if (!tempAccountInfo?.category_id?.name) {
      setError((error) => ({
        ...error,
        category_error: "Please select a category",
      }));
      setValidData((validData) => ({
        ...validData,
        valid_category: false,
      }));
      return false;
    }
    return true;
  }

  function checkBusinessCountryCode() {
    if (!tempAccountInfo?.country_code) {
      setError((error) => ({
        ...error,
        country_code_error: "Please select a Country code",
      }));
      setValidData((validData) => ({
        ...validData,
        valid_country_code: false,
      }));
      return false;
    }
    return true;
  }

  function generatePayload() {
    let payload: any = { user_id: tempAccountInfo?.user_id };
    if (accountInfo.name !== tempAccountInfo.name.trim()) {
      payload = { ...payload, name: tempAccountInfo.name.trim() };
    }
    if (accountInfo.category !== tempAccountInfo.category) {
      payload = { ...payload, category: tempAccountInfo.category };
    }
    if (accountInfo.country_code !== tempAccountInfo.country_code) {
      payload = { ...payload, country_code: tempAccountInfo.country_code };
    }
    if (accountInfo.user_email !== tempAccountInfo.user_email) {
      payload = { ...payload, user_email: tempAccountInfo.user_email };
    }
    if (accountInfo.trade_name !== tempAccountInfo.trade_name) {
      payload = { ...payload, trade_name: tempAccountInfo.trade_name };
    }
    if (accountInfo.user_name !== tempAccountInfo.user_name) {
      payload = { ...payload, user_name: tempAccountInfo.user_name };
    }

    if (
      accountInfo?.category_id?.name !== tempAccountInfo?.category_id?.name ||
      accountInfo?.category_id?.sub_category !==
        tempAccountInfo?.category_id?.sub_category
    ) {
      payload = {
        ...payload,
        category_id: getCategoryId(
          tempAccountInfo?.category_id?.name,
          tempAccountInfo?.category_id?.sub_category
        )?._id,
      };
    }

    return payload;
  }

  async function updateBusinessInfo() {
    const allCheckPasses = [
      checkRegisteredEmail(),
      checkBusinessName(),
      checkBusinessCategory(),
      checkBusinessCountryCode(),
      checkTraderName(),
      checkUserName(),
    ].every(Boolean);
    if (!allCheckPasses) {
      return;
    }
    setBusinessInfoLoading(true);
    const payload = generatePayload();
    try {
      // call api here
      const response: any = await callPutApi(
        `/organisations/${accountInfo?._id}?action=update_business_info`,
        payload
      );
      const {
        name,
        country_code,
        category,
        trade_name,
        user_email,
        user_name,
        category_id,
      } = response.data;

      setBusinessInfoLoading(false);
      setToggleUpdateBusinessInfo(false);
      updatedBusinessInfo({
        name,
        country_code,
        category,
        category_id,
        trade_name,
        user_email,
        user_name,
      });
    } catch (err: any) {
      setBusinessInfoLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }

  const filterBusinessCategory = (category: string) => {
    if (!category) return [];

    return categories
      .filter((item: any) => {
        return item?.name?.toLowerCase() === category.toLowerCase();
      })
      ?.map((item: any) => item?.sub_category)
      ?.filter((item: any) => item);
  };

  const getUniqueCategories = (data: any) => {
    if (!data.length) return [];
    return Array.from(new Set(data.map((item: any) => item.name)));
  };

  const getCategoryId = (category: string, sub_category: string) => {
    if (!categories.length) return null;
    return categories.find(
      (item: any) =>
        item?.name?.toLowerCase() === category?.toLowerCase() &&
        item?.sub_category?.toLowerCase() === sub_category?.toLowerCase()
    );
  };

  return (
    <div>
      <div className={sharedStyle.card}>
        <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
          <div className="d-flex align-items-center">
            <p className={`mb-0 ${sharedStyle.cardHeader}`}>Business Info</p>
            <div className="ml-auto">
              {isLoading ? (
                <div>--</div>
              ) : is_allowed_to_write ? (
                toggleUpdateBusinessInfo ? (
                  <div className="d-flex align-items-center">
                    <div className="px-2">
                      <Button
                        id="business-info-cancel"
                        size={COMPRESSED}
                        style={cancelButtonStyle}
                        disabled={businessInfoLoading}
                        hoveredStyle={cancelButtonHoverStyle}
                        onClick={() => {
                          setTempAccountInfo(accountInfo);
                          setToggleUpdateBusinessInfo(false);
                          setError({
                            name_error: "",
                            category_error: "",
                            country_code_error: "",
                            trader_name: "",
                            user_email: "",
                            user_name_error: "",
                          });
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="px-2">
                      <Button
                        id="business-info-save"
                        size={COMPRESSED}
                        style={successButtonStyle}
                        disabled={businessInfoLoading}
                        hoveredStyle={successButtonHoverStyle}
                        onClick={() => updateBusinessInfo()}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                ) : accountInfo?.status === orgStatus.DISABLED &&
                  is_allowed_to_write ? null : (
                  <UpdateSvg
                    id={IdsGenerator({
                      prefix: "business info",
                      id: "update",
                      sufix: "svg",
                    })}
                    click={() => {
                      setTempAccountInfo(accountInfo);
                      setToggleUpdateBusinessInfo(true);
                    }}
                  />
                )
              ) : null}
            </div>
          </div>
        </div>
        {isLoading ? (
          loading
        ) : (
          <div className="p-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  {toggleUpdateBusinessInfo ? (
                    <div>
                      <Input
                        id={IdsGenerator({
                          prefix: "business info",
                          id: "registered",
                          sufix: "email",
                        })}
                        no_shadow="true"
                        type="email"
                        name="Register Email"
                        maxLength={40}
                        autoComplete="off"
                        value={tempAccountInfo.user_email}
                        hasError={error.user_email}
                        isValid={validData.valid_user_email}
                        placeholder="Enter Registered Email"
                        labelname="Registered Email"
                        onChange={(event: any) => {
                          setTempAccountInfo((accountInfo: any) => ({
                            ...accountInfo,
                            user_email: event.target.value,
                          }));
                          setError((error) => ({
                            ...error,
                            user_email: "",
                          }));
                          setValidData((validData) => ({
                            ...validData,
                            valid_user_email: true,
                          }));
                        }}
                        onBlur={checkRegisteredEmail}
                      />
                      {error.user_email && (
                        <ErrorMessage>{error.user_email}</ErrorMessage>
                      )}
                    </div>
                  ) : (
                    <KeyValuePair
                      className="py-4"
                      label="Registered Email"
                      value={accountInfo.user_email ?? "NA"}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {toggleUpdateBusinessInfo ? (
                    <div>
                      <Input
                        id={IdsGenerator({
                          prefix: "business info",
                          id: "update",
                          sufix: "business name",
                        })}
                        no_shadow="true"
                        type="text"
                        name="business_name"
                        maxLength={40}
                        autoComplete="off"
                        value={tempAccountInfo.name}
                        hasError={error.name_error}
                        isValid={validData.valid_name}
                        placeholder="Enter Business Name"
                        labelname="Business Name"
                        onChange={(event: any) => {
                          setTempAccountInfo((accountInfo: any) => ({
                            ...accountInfo,
                            name: event.target.value,
                          }));
                          setError((error) => ({
                            ...error,
                            name_error: "",
                          }));
                          setValidData((validData) => ({
                            ...validData,
                            valid_name: true,
                          }));
                        }}
                        onBlur={checkBusinessName}
                      />
                      {error.name_error && (
                        <ErrorMessage>{error.name_error}</ErrorMessage>
                      )}
                    </div>
                  ) : (
                    <KeyValuePair
                      className="py-4"
                      label="Business Name"
                      value={accountInfo.name ?? "NA"}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {toggleUpdateBusinessInfo ? (
                    <div>
                      <Input
                        id={IdsGenerator({
                          prefix: "business info",
                          id: "update",
                          sufix: "trade name",
                        })}
                        no_shadow="true"
                        type="text"
                        name="Trader Name"
                        maxLength={40}
                        autoComplete="off"
                        value={tempAccountInfo?.trade_name || ""}
                        hasError={error.trader_name}
                        isValid={validData.valid_trader_name}
                        placeholder="Enter Trader Name"
                        labelname="Trader Name"
                        onChange={(event: any) => {
                          setTempAccountInfo((accountInfo: any) => ({
                            ...accountInfo,
                            trade_name: event.target.value,
                          }));
                          setError((error) => ({
                            ...error,
                            trader_name: "",
                          }));
                          setValidData((validData) => ({
                            ...validData,
                            valid_trader_name: true,
                          }));
                        }}
                        onBlur={checkTraderName}
                      />
                      {error.trader_name && (
                        <ErrorMessage>{error.trader_name}</ErrorMessage>
                      )}
                    </div>
                  ) : (
                    <KeyValuePair
                      className="py-4"
                      label="Trader Name"
                      value={accountInfo.trade_name ?? "NA"}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {toggleUpdateBusinessInfo ? (
                    <div className="py-2">
                      <InputDropdown
                        id={IdsGenerator({
                          prefix: "business info",
                          id: "dropdown",
                          sufix: "country",
                        })}
                        labelname="Country"
                        hasError={error.country_code_error}
                        isValid={validData.valid_country_code}
                        optionsArray={countryCodes}
                        click={(country_code: string) => {
                          const code = country_code.substr(0, 2);
                          setTempAccountInfo((accountInfo: any) => ({
                            ...accountInfo,
                            country_code: code,
                          }));
                          setError((error) => ({
                            ...error,
                            country_code_error: "",
                          }));
                          setValidData((validData) => ({
                            ...validData,
                            valid_country_code: true,
                          }));
                        }}
                        defaultValue={tempAccountInfo.country_code}
                        no_shadow="true"
                      />
                      {error.country_code_error && (
                        <ErrorMessage>{error.country_code_error}</ErrorMessage>
                      )}
                    </div>
                  ) : (
                    <KeyValuePair
                      className="py-4"
                      label="Country"
                      value={accountInfo.country_code ?? "NA"}
                    />
                  )}
                </div>

                <div className="col-md-6">
                  {toggleUpdateBusinessInfo ? (
                    <div className="py-2">
                      <InputDropdown
                        id={IdsGenerator({
                          prefix: "business info",
                          id: "dropdown",
                          sufix: "category",
                        })}
                        labelname="Business Category"
                        hasError={error.category_error}
                        isValid={validData.valid_category}
                        optionsArray={getUniqueCategories(categories)}
                        click={(category: string) => {
                          setTempAccountInfo((accountInfo: any) => ({
                            ...accountInfo,
                            category_id: {
                              ...accountInfo?.category_id,
                              name: category,
                            },
                          }));
                          setError((error) => ({
                            ...error,
                            category_error: "",
                          }));
                          setValidData((validData) => ({
                            ...validData,
                            valid_category: true,
                          }));
                        }}
                        value={tempAccountInfo?.category_id?.name}
                        defaultValue={tempAccountInfo?.category_id?.name}
                        no_shadow="true"
                      />
                      {error.category_error && (
                        <ErrorMessage>{error.category_error}</ErrorMessage>
                      )}
                    </div>
                  ) : (
                    <KeyValuePair
                      className="py-4"
                      label="Business Category"
                      value={accountInfo?.category_id?.name ?? "NA"}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {toggleUpdateBusinessInfo ? (
                    <div className="py-2">
                      <InputDropdown
                        id={IdsGenerator({
                          prefix: "business info",
                          id: "dropdown",
                          sufix: "sub category",
                        })}
                        labelname="Business Sub Category"
                        // hasError={error.category_error}
                        // isValid={validData.valid_category}
                        optionsArray={filterBusinessCategory(
                          tempAccountInfo?.category_id?.name
                        )}
                        click={(sub_category: string) => {
                          setTempAccountInfo((accountInfo: any) => ({
                            ...accountInfo,
                            category_id: {
                              ...accountInfo?.category_id,
                              sub_category: sub_category,
                            },
                          }));
                        }}
                        defaultValue={
                          tempAccountInfo?.category_id?.sub_category
                        }
                        value={tempAccountInfo?.category_id?.sub_category}
                        no_shadow="true"
                      />
                    </div>
                  ) : (
                    <KeyValuePair
                      className="py-4"
                      label="Business Sub Category"
                      value={accountInfo?.category_id?.sub_category ?? "NA"}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {toggleUpdateBusinessInfo ? (
                    <div>
                      <Input
                        id={IdsGenerator({
                          prefix: "business info",
                          id: "update",
                          sufix: "user name",
                        })}
                        no_shadow="true"
                        type="text"
                        name="User Name"
                        maxLength={40}
                        autoComplete="off"
                        defaultValue={tempAccountInfo.user_name}
                        value={tempAccountInfo.user_name}
                        hasError={error.user_name_error}
                        placeholder="Enter User name"
                        labelname="User Name"
                        onChange={(event: any) => {
                          setTempAccountInfo((accountInfo: any) => ({
                            ...accountInfo,
                            user_name: event.target.value,
                          }));
                          setError((error) => ({
                            ...error,
                            user_name_error: "",
                          }));
                          setValidData((validData) => ({
                            ...validData,
                            valid_user_name: true,
                          }));
                        }}
                        onBlur={checkUserName}
                      />
                      {error.user_name_error && (
                        <ErrorMessage>{error.user_name_error}</ErrorMessage>
                      )}
                    </div>
                  ) : (
                    <KeyValuePair
                      className="py-4"
                      label="User Name"
                      value={accountInfo.user_name ?? "NA"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BusinessInfo;
