// Hooks
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

// constants
import {
  ACCENTCOLOR,
  PRIMARYCOLOR,
  SECONDARYCOLOR,
} from "../../../../shared/colors";
import { COMPRESSED } from "../../../../shared/buttonSize";
import { plans } from "../../../../constants/navItems";
import { deprecatedProducts } from "../../../../constants/deprecatedProducts";

// svg
import ArrowDown from "../../../../shared/svg/arrowDown";
import RupeeSVG from "../../../../shared/svg/rupeeSvg";

// functions
import { ToastContext } from "../../../../context/toastContext";
import {
  callDeleteApi,
  callGetApi,
  callPostApi,
  callPutApi,
} from "../../../../api/axios";
import { isObject, numberRegexp } from "../../../../utils/validation";
import { planNames } from "../../../../utils/planNames";
import {
  numberWithCommas,
  roundOfDecimal,
} from "../../../../utils/updateAmount";
import { monthName } from "../../../../utils/getMonth";

// components
import Loading from "../../../../shared/loading/loading";
import Button from "../../../../shared/button/button";
import KeyValuePair from "../../../../shared/key-value-pair/keyValuePair";
import PlanRateCard from "./plan-rate-card/planRateCard";
import Input from "../../../../shared/input/input";
import ErrorMessage from "../../../../shared/error-message/errorMessage";
import InputDropdown from "../../../../shared/input-dropdown/inputDropdown";
import ConfirmationModal from "../../../../shared/modal/confirmationModal";
import { PermissionContext } from "../../../../context/permissionContext";

// styles
import Styles from "./planDetails.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";
import { ERROR, SUCCESS } from "../../../../utils/toastType";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  dangerButtonHoverStyle,
  dangerButtonStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../../shared/buttonStyles";
import { ApmContext } from "../../../../utils/apmProvider";
import { IdsGenerator } from "../../../../utils/idsGenerator";

type Plan = {
  _id: string;
  createdAt: string;
  createdBy: any;
  customer_count: number;
  is_custom_plan: boolean;
  plan_code: string;
  plan_name: any;
  plan_type: string;
  prepaid_recharge_amount: any;
  pricing: [];
  plan_validity: number;
  updatedAt: string;
  status: string;
};

export default function PlanDetails() {
  const currentUser = JSON.parse(localStorage.getItem("current_user") || "");
  const planTypes = {
    PREPAID: "Prepaid",
    POSTPAID: "Postpaid",
  };
  const planValidities = [
    "0 Months",
    "3 Months",
    "6 Months",
    "9 Months",
    "12 Months",
  ];
  const planUpdateTypes = {
    EDIT: "edit",
    CLONE: "clone",
  };
  const history = useHistory();
  const { id }: any = useParams();
  const dispatch = useContext(ToastContext);
  const { assets, permissions: permissionsType } =
    useContext(PermissionContext);
  const grant = assets[plans][plans].grant;
  const permissions = assets[plans][plans].permissions;
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [loading, setLoading] = useState(true);
  const [publishPlanLoading, setPublishPlanLoading] = useState(false);
  const [toggleModifyPlanLoading, setToggleModifyPlanLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState<Plan>();
  const [tempPlanDetails, setTempPlanDetails] = useState<Plan>();
  const [toggleModifyPlan, setToggleModifyPlan] = useState({
    type: "",
    toggle: false,
  });
  const [error, setError] = useState({
    plan_name_error: "",
    plan_amount: "",
    plan_type: "",
    plan_validity: "",
  });
  const [pricingError, setPricingError] = useState<any>([]);
  const [addonPriceError, setAddonPriceError] = useState<any>([]);
  const [subAddonPriceError, setSubAddonPriceError] = useState<any>([]);
  const [approveDeleteModal, setApproveDelelteModal] =
    React.useState<boolean>(false);
  const [deletePlanLoading, setDeletePlanLoading] = React.useState(false);
  const [productPricing, setProductPricing] = useState({});
  // get plan details api call
  useEffect(() => {
    async function getPlanDetails() {
      try {
        const { data }: any = await callGetApi(`/plans/${id}`);
        const filteredProducts = data.pricing.filter(
          (product: any) => !deprecatedProducts.includes(product.productArn)
        );
        setPlanDetails({ ...data, pricing: filteredProducts });
        setTempPlanDetails({ ...data, pricing: filteredProducts });
        filteredProducts.forEach((product: any) => {
          setProductPricing((productPricing) => ({
            ...productPricing,
            [product.productArn]: product.productPricing,
          }));
        });
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              err.response.data.err +
              " TraceId is \n" +
              apmTraceId(apmInstance),
            copy: apmTraceId(apmInstance),
          },
        });
        history.push("/application/plans");
      }
    }

    getPlanDetails();
  }, [id, dispatch]);

  async function onDeletePlan() {
    setDeletePlanLoading(true);
    try {
      const result: any = await callDeleteApi(
        `/plans/delete/${planDetails?._id}`
      );
      if (result) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: SUCCESS,
            message: result?.data?.message,
          },
        });

        setDeletePlanLoading(false);
        setApproveDelelteModal(false);
        history.push("/application/plans");
      }
    } catch (err: any) {
      setDeletePlanLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }

  useEffect(() => {
    setError((prevError: any) => {
      if (
        tempPlanDetails?.plan_type === "POSTPAID" &&
        tempPlanDetails.prepaid_recharge_amount === "0"
      ) {
        return { ...prevError, plan_amount: "" };
      }
      return { ...prevError, plan_amount: "" };
    });
  }, [tempPlanDetails, setPlanDetails]);

  useEffect(() => {
    const addonErrors: any = [];
    const subAddonErrors: any = [];

    const price = tempPlanDetails?.pricing.map((product: any) => {
      if (product.addons.length > 0) {
        product.addons.map((addon: any) => {
          // sub-addons validations
          if (addon?.sub_addons?.length) {
            addon.sub_addons.map((subAddon: any) => {
              if (
                product.productPricing[addon.addon_key][
                  subAddon?.sub_addon_key
                ] < 0 ||
                !String(
                  product.productPricing[addon.addon_key][
                    subAddon?.sub_addon_key
                  ]
                )
              ) {
                subAddonErrors.push({
                  product_arn: product.productArn,
                  addon_arn: addon.addon_key,
                  sub_addon_arn: subAddon?.sub_addon_key,
                  error_message:
                    "Addon price should be greater than or equal to 0",
                });
              }
            });
          }

          if (
            addon.addon_key === "name_match" &&
            product?.productArn !== "in_financial_bav_lite" &&
            (product.productPricing[addon.addon_key] < 0.5 ||
              (!String(product.productPricing[addon.addon_key]) &&
                product.productPricing[addon.addon_key] !== 0))
          ) {
            addonErrors.push({
              product_arn: product.productArn,
              addon_arn: addon.addon_key,
              error_message: "addon amount should be greater than 0.5",
            });

            return addonErrors;
          } else if (
            (isObject(product.productPricing[addon.addon_key])
              ? !String(product.productPricing[addon.addon_key]?.unit_price)
              : !String(product.productPricing[addon.addon_key])) ||
            product.productPricing[addon.addon_key] < 0
          ) {
            addonErrors.push({
              product_arn: product.productArn,
              addon_arn: addon.addon_key,
              error_message: "Addon price should be greater than or equal to 0",
            });
            return addonErrors;
          }
          return null;
        });
      }

      setAddonPriceError(addonErrors);
      setSubAddonPriceError(subAddonErrors);

      tempPlanDetails?.pricing.forEach((product: any) => {
        setProductPricing((productPricing) => ({
          ...productPricing,
          [product.productArn]: product?.productPricing,
        }));
      });
      const product_price =
        Object.keys(product.productPricing).length > 0
          ? product.productPricing.unit_price
          : product.productPricing;

      if (!String(product_price)) {
        return {
          error_for_product: product.productArn,
          error_message: `price should be greater than ${product.productLowerBound}`,
        };
      }

      if (product_price < product?.productLowerBound) {
        return {
          error_for_product: product.productArn,
          error_message: `price should be greater than ${product.productLowerBound}`,
        };
      }

      return {
        error_for_product: product.productArn,
        error_message: "",
      };
    });
    setPricingError(price);
  }, [tempPlanDetails]);

  // function to call the edit plan api

  async function handleEditPlan() {
    const allCheckPassed = [
      checkPlanAmount(),
      checkPlanName(),
      checkPlanType(),
      checkPlanValidity(),
    ].every(Boolean);
    const isErrorPresentForPricing = pricingError.filter(
      (error: any) => error.error_message !== ""
    );
    const isErrorPresentForAddon = addonPriceError.filter(
      (error: any) => error.error_message !== ""
    );
    const isErrorPresentForSubAddon = subAddonPriceError.filter(
      (error: any) => error.error_message !== ""
    );

    if (isErrorPresentForPricing.length > 0) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Error in Price per transaction",
        },
      });
      return;
    }
    if (isErrorPresentForAddon.length > 0) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Error in Addon Price",
        },
      });
      return;
    }

    if (isErrorPresentForSubAddon.length > 0) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Error in SubAddon Price",
        },
      });
      return;
    }

    if (!allCheckPassed) {
      return;
    }
    setToggleModifyPlanLoading(true);
    try {
      const { data }: any = await callPutApi(`/plans/${planDetails?._id}`, {
        plan_name: tempPlanDetails?.plan_name,
        plan_type: tempPlanDetails?.plan_type,
        plan_validity: Number(tempPlanDetails?.plan_validity),
        prepaid_recharge_amount: Number(
          tempPlanDetails?.prepaid_recharge_amount
        ),
        pricing: productPricing,
      });
      const filteredProducts = data.pricing.filter(
        (product: any) => !deprecatedProducts.includes(product.productArn)
      );
      setPlanDetails({ ...data, pricing: filteredProducts });
      setTempPlanDetails({ ...data, pricing: filteredProducts });
      setToggleModifyPlanLoading(false);
      setToggleModifyPlan({
        type: "",
        toggle: false,
      });
    } catch (err: any) {
      setToggleModifyPlanLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }

  // function to call the clone plan api
  async function handleClonePlan() {
    const allCheckPassed = [
      checkPlanAmount(),
      checkPlanName(),
      checkPlanType(),
      checkPlanValidity(),
    ].every(Boolean);

    const isErrorPresentForPricing = pricingError.filter(
      (error: any) => error.error_message !== ""
    );
    const isErrorPresentForAddon = addonPriceError.filter(
      (error: any) => error.error_message !== ""
    );
    const isErrorPresentForSubAddon = subAddonPriceError.filter(
      (error: any) => error.error_message !== ""
    );
    if (isErrorPresentForPricing.length > 0) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Error in Price per transaction",
        },
      });
      return;
    }
    if (isErrorPresentForAddon.length > 0) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Error in Addon Price",
        },
      });
      return;
    }

    if (isErrorPresentForSubAddon.length > 0) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Error in SubAddon Price",
        },
      });
      return;
    }

    if (!allCheckPassed) {
      return;
    }

    setToggleModifyPlanLoading(true);
    try {
      await callPostApi("/plans", {
        reference_plan_id: planDetails?._id,
        plan_name: tempPlanDetails?.plan_name,
        plan_type: tempPlanDetails?.plan_type,
        plan_validity: Number(tempPlanDetails?.plan_validity),
        prepaid_recharge_amount: Number(
          tempPlanDetails?.prepaid_recharge_amount
        ),
        pricing: productPricing,
      });
      setToggleModifyPlanLoading(false);
      setToggleModifyPlan({
        type: "",
        toggle: false,
      });
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: SUCCESS,
          message: "Plan cloned successfully",
        },
      });
      history.replace("/application/plans");
    } catch (err: any) {
      setToggleModifyPlanLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }
  async function handlePublishPlan() {
    const isErrorPresentForPricing = pricingError.filter(
      (error: any) => error.error_message !== ""
    );
    const isErrorPresentForAddon = addonPriceError.filter(
      (error: any) => error.error_message !== ""
    );
    const isErrorPresentForSubAddon = subAddonPriceError.filter(
      (error: any) => error.error_message !== ""
    );
    if (isErrorPresentForPricing.length > 0) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Error in Price per transaction",
        },
      });
      return;
    }
    if (isErrorPresentForAddon.length > 0) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Error in Addon Price",
        },
      });
      return;
    }
    if (isErrorPresentForSubAddon.length > 0) {
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: "Error in SubAddon Price",
        },
      });
      return;
    }

    setPublishPlanLoading(true);
    try {
      await callPutApi(`/plans/${planDetails?._id}?action=publish_plan`, {});
      setPublishPlanLoading(false);
      history.replace("/application/plans");
    } catch (err: any) {
      setPublishPlanLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }

  // use this function to capitalize the first letter of status
  function capitalizeFirstChar(status: any) {
    return status?.charAt(0).toUpperCase() + status?.slice(1);
  }

  // function to check whether a valid plan name is there or not
  function checkPlanName() {
    if (!tempPlanDetails?.plan_name) {
      setError((error) => ({
        ...error,
        plan_name_error: "Please select a plan name",
      }));
      return false;
    }
    return true;
  }

  // function to check whether plan validity is entered or not
  function checkPlanValidity() {
    if (tempPlanDetails?.plan_validity === undefined) {
      setError((error) => ({
        ...error,
        plan_validity: "Please select a plan Validity",
      }));
      return false;
    }
    return true;
  }

  // function to check the plan type
  function checkPlanType() {
    if (!tempPlanDetails?.plan_type) {
      setError((error) => ({
        ...error,
        plan_type: "Please select a type of a plan",
      }));
      return false;
    }
    return true;
  }

  // function to check the plan amount
  function checkPlanAmount() {
    if (!numberRegexp.test(tempPlanDetails?.prepaid_recharge_amount)) {
      setError((error) => ({
        ...error,
        plan_amount: "Enter a valid amount",
      }));
      return false;
    }
    if (Number(tempPlanDetails?.prepaid_recharge_amount) < 0) {
      setError((error) => ({
        ...error,
        plan_amount: "Amount should not be negative",
      }));
      return false;
    }
    if (
      tempPlanDetails?.plan_type === "PREPAID" &&
      Number(tempPlanDetails?.prepaid_recharge_amount) === 0
    ) {
      setError((prevError) => ({
        ...prevError,
        plan_amount: "Amount should not be zero in Prepaid plan",
      }));
      return false;
    }
    return true;
  }
  return (
    <div className="p-3">
      {loading ? (
        <div
          style={{ height: "80vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        <div>
          <div className="d-inline-flex py-2">
            <div
              id={IdsGenerator({
                prefix: "plan details",
                id: "click",
                sufix: "back",
              })}
              className="d-flex align-items-center"
              onClick={() => history.push("/application/plans")}
              style={{ cursor: "pointer" }}
            >
              <div className={Styles.rotate}>
                <ArrowDown color={SECONDARYCOLOR} />
              </div>
              <p className={`mb-0 ${Styles.back}`}>back</p>
            </div>
          </div>
          <div className="py-3">
            <div className={sharedStyle.card}>
              <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
                <div className="d-flex align-items-center">
                  <div>
                    <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                      Plan Details
                    </p>
                    {planDetails?.createdBy && planDetails?.createdAt && (
                      <p
                        style={{
                          fontSize: "12px",
                          color: SECONDARYCOLOR,
                          margin: 0,
                        }}
                      >
                        Created By{" "}
                        <span style={{ color: PRIMARYCOLOR, fontWeight: 500 }}>
                          {planDetails?.createdBy?.name}
                        </span>{" "}
                        on{" "}
                        <span style={{ color: PRIMARYCOLOR, fontWeight: 500 }}>
                          {monthName(
                            new Date(Number(planDetails?.createdAt)).getMonth()
                          )}{" "}
                          {new Date(Number(planDetails?.createdAt)).getDate()},{" "}
                          {new Date(
                            Number(planDetails?.createdAt)
                          ).getFullYear()}
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="pl-2 ml-auto">
                    {!permissions[
                      `${permissionsType.write}_${grant}`
                    ] ? null : (
                      <div className="d-flex align-items-center">
                        {planDetails?.status === "DRAFT" &&
                        planDetails?.createdBy &&
                        planDetails?.createdBy?.user_id !== currentUser.id ? (
                          <div className="px-3">
                            <Button
                              id={IdsGenerator({
                                prefix: "plan details",
                                id: "click",
                                sufix: "public plan",
                              })}
                              style={successButtonStyle}
                              hoveredStyle={successButtonHoverStyle}
                              size={COMPRESSED}
                              isLoading={publishPlanLoading}
                              disabled={
                                publishPlanLoading || toggleModifyPlan.toggle
                              }
                              onClick={() => handlePublishPlan()}
                            >
                              Publish Plan
                            </Button>
                          </div>
                        ) : null}
                        {toggleModifyPlan?.toggle ? (
                          <div className="d-flex align-items-center">
                            <div className="pl-2">
                              <Button
                                id={IdsGenerator({
                                  prefix: "plan details",
                                  id: "click",
                                  sufix: "cancel",
                                })}
                                style={cancelButtonStyle}
                                hoveredStyle={cancelButtonHoverStyle}
                                size={COMPRESSED}
                                disabled={toggleModifyPlanLoading}
                                onClick={() => {
                                  setTempPlanDetails(planDetails);
                                  setToggleModifyPlan({
                                    type: "",
                                    toggle: false,
                                  });
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                            <div className="pl-2">
                              <Button
                                id={IdsGenerator({
                                  prefix: "plan details",
                                  id: "click",
                                  sufix: "save",
                                })}
                                style={primaryButtonStyle}
                                hoveredStyle={primaryButtonHoverStyle}
                                size={COMPRESSED}
                                onClick={() => {
                                  if (
                                    toggleModifyPlan.type ===
                                    planUpdateTypes.EDIT
                                  ) {
                                    handleEditPlan();
                                    return;
                                  }
                                  handleClonePlan();
                                }}
                                disabled={toggleModifyPlanLoading}
                                isLoading={toggleModifyPlanLoading}
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        ) : planDetails?.status === "DRAFT" ? (
                          <>
                            <div style={{ gap: "10px", display: "flex" }}>
                              <Button
                                id={IdsGenerator({
                                  prefix: "plan details",
                                  id: "click",
                                  sufix: "edit plan",
                                })}
                                style={primaryButtonStyle}
                                hoveredStyle={primaryButtonHoverStyle}
                                disabled={publishPlanLoading}
                                size={COMPRESSED}
                                onClick={() =>
                                  setToggleModifyPlan({
                                    type: planUpdateTypes.EDIT,
                                    toggle: true,
                                  })
                                }
                              >
                                Edit Plan
                              </Button>
                              <Button
                                id={IdsGenerator({
                                  prefix: "plan details",
                                  id: "click",
                                  sufix: "delete plan",
                                })}
                                style={dangerButtonStyle}
                                hoveredStyle={dangerButtonHoverStyle}
                                disabled={publishPlanLoading}
                                size={COMPRESSED}
                                onClick={() => setApproveDelelteModal(true)}
                              >
                                Delete Plan
                              </Button>
                            </div>
                          </>
                        ) : (
                          <Button
                            id={IdsGenerator({
                              prefix: "plans details",
                              id: "click",
                              sufix: "clone",
                            })}
                            style={primaryButtonStyle}
                            hoveredStyle={primaryButtonHoverStyle}
                            size={COMPRESSED}
                            onClick={() =>
                              setToggleModifyPlan({
                                type: planUpdateTypes.CLONE,
                                toggle: true,
                              })
                            }
                            disabled={
                              !permissions[`${permissionsType.write}_${grant}`]
                            }
                          >
                            Clone Plan
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="p-4">
                <div className="container-fluid p-0">
                  <div className="row">
                    <div className="col-md-3 col-sm-4">
                      {toggleModifyPlan?.toggle ? (
                        <div className="py-2">
                          <InputDropdown
                            id={IdsGenerator({
                              prefix: "plans details",
                              id: "dropdown",
                              sufix: "plan name",
                            })}
                            labelname="Plan Name"
                            hasError={error.plan_name_error}
                            optionsArray={planNames}
                            click={(plan_name: string) => {
                              setTempPlanDetails((planDetails: any) => ({
                                ...planDetails,
                                plan_name: plan_name,
                              }));
                              setError((error: any) => ({
                                ...error,
                                plan_name_error: "",
                              }));
                            }}
                            defaultValue={tempPlanDetails?.plan_name}
                            no_shadow="true"
                          />
                          {error.plan_name_error && (
                            <ErrorMessage>{error.plan_name_error}</ErrorMessage>
                          )}
                        </div>
                      ) : (
                        <KeyValuePair
                          className="py-2"
                          label="Plan Name"
                          value={planDetails?.plan_name ?? "NA"}
                        />
                      )}
                    </div>
                    <div className="col-md-3 col-sm-4">
                      {toggleModifyPlan?.toggle ? (
                        <div>
                          <Input
                            id={IdsGenerator({
                              prefix: "plans details",
                              id: "input",
                              sufix: "plan amount",
                            })}
                            no_shadow="true"
                            type="text"
                            name="plan_amount"
                            maxLength={10}
                            autoComplete="off"
                            value={tempPlanDetails?.prepaid_recharge_amount}
                            hasError={error.plan_amount}
                            placeholder="Enter Plan Amount"
                            labelname="Plan Amount"
                            onChange={(event: any) => {
                              setTempPlanDetails((planDetails: any) => ({
                                ...planDetails,
                                prepaid_recharge_amount: event.target.value,
                              }));
                              setError((error: any) => ({
                                ...error,
                                plan_amount: "",
                              }));
                            }}
                            onBlur={checkPlanAmount}
                          />
                          {error.plan_amount && (
                            <ErrorMessage>{error.plan_amount}</ErrorMessage>
                          )}
                        </div>
                      ) : (
                        <KeyValuePair
                          className="py-2"
                          label="Plan Value"
                          value={
                            planDetails?.prepaid_recharge_amount
                              ? numberWithCommas(
                                  roundOfDecimal(
                                    planDetails?.prepaid_recharge_amount
                                  )
                                )
                              : "NA"
                          }
                          helperText="Excl Taxes"
                        >
                          <RupeeSVG
                            margin="0 0 3px 0"
                            width="8"
                            height="12"
                            color={PRIMARYCOLOR}
                          />
                        </KeyValuePair>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-4">
                      {toggleModifyPlan?.toggle ? (
                        <div className="py-2">
                          <InputDropdown
                            id={IdsGenerator({
                              prefix: "plans details",
                              id: "dropdown",
                              sufix: "plan type",
                            })}
                            labelname="Plan Type"
                            hasError={error.plan_type}
                            optionsArray={Object.keys(planTypes)}
                            click={(plan_type: string) => {
                              setTempPlanDetails((planDetails: any) => ({
                                ...planDetails,
                                plan_type: plan_type.toUpperCase(),
                              }));
                              setError((error) => ({
                                ...error,
                                plan_type: "",
                              }));
                            }}
                            defaultValue={
                              tempPlanDetails?.plan_type.toLowerCase() ===
                              planTypes.PREPAID.toLowerCase()
                                ? planTypes.PREPAID
                                : planTypes.POSTPAID ?? "NA"
                            }
                            no_shadow="true"
                          />
                          {error.plan_type && (
                            <ErrorMessage>{error.plan_type}</ErrorMessage>
                          )}
                        </div>
                      ) : (
                        <KeyValuePair
                          className="py-2"
                          label="Plan Type"
                          value={
                            planDetails?.plan_type.toLowerCase() ===
                            planTypes.PREPAID.toLowerCase()
                              ? planTypes.PREPAID
                              : planTypes.POSTPAID ?? "NA"
                          }
                        />
                      )}
                    </div>
                    <div className="col-md-3 col-sm-4">
                      {toggleModifyPlan?.toggle ? (
                        <div className="py-2">
                          <InputDropdown
                            id={IdsGenerator({
                              prefix: "plans details",
                              id: "dropdown",
                              sufix: "plan validity",
                            })}
                            labelname="Plan Validity"
                            hasError={error.plan_validity}
                            optionsArray={planValidities}
                            click={(plan_validity: string) => {
                              const validity = plan_validity.split(" ")[0];
                              setTempPlanDetails((planDetails: any) => ({
                                ...planDetails,
                                plan_validity: validity,
                              }));
                              setError((error) => ({
                                ...error,
                                plan_validity: "",
                              }));
                            }}
                            defaultValue={
                              tempPlanDetails?.plan_validity ||
                              tempPlanDetails?.plan_validity === 0
                                ? `${tempPlanDetails?.plan_validity} Months`
                                : "NA"
                            }
                            no_shadow="true"
                          />
                          {error.plan_validity && (
                            <ErrorMessage>{error.plan_validity}</ErrorMessage>
                          )}
                        </div>
                      ) : (
                        <KeyValuePair
                          className="py-2"
                          label="Validity"
                          value={
                            planDetails?.plan_validity ||
                            planDetails?.plan_validity === 0
                              ? `${planDetails?.plan_validity} Months`
                              : "NA"
                          }
                        />
                      )}
                    </div>
                    <div className="col-md-3 col-sm-4">
                      {!toggleModifyPlan?.toggle && (
                        <KeyValuePair
                          className="py-2"
                          label="Plan Code"
                          value={planDetails?.plan_code ?? "NA"}
                        />
                      )}
                    </div>
                    <div className="col-md-3 col-sm-4">
                      {!toggleModifyPlan?.toggle && (
                        <KeyValuePair
                          className="py-2"
                          label="Status"
                          value={
                            capitalizeFirstChar(
                              planDetails?.status.toLowerCase()
                            ) ?? "NA"
                          }
                        />
                      )}
                    </div>
                    <div className="col-md-3 col-sm-4">
                      {!toggleModifyPlan?.toggle && (
                        <KeyValuePair
                          className="py-2"
                          label="Customer Count"
                          value={planDetails?.customer_count ?? "NA"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PlanRateCard
            addonPriceError={addonPriceError}
            subAddonPriceError={subAddonPriceError}
            pricingError={pricingError}
            products={planDetails?.pricing}
            toggleModifyPlan={toggleModifyPlan.toggle}
            onUpdatePricing={(pricing: any) => {
              setTempPlanDetails((planDetails: any) => ({
                ...planDetails,
                pricing: pricing,
              }));
            }}
          />
          {approveDeleteModal && (
            <ConfirmationModal
              title="Delete Plan"
              subTitle="Are you sure you want to delete this plan?"
              btnText="Delete"
              isLoading={deletePlanLoading}
              onCancel={() => setApproveDelelteModal(false)}
              onSuccess={() => {
                onDeletePlan();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}
