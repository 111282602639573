import React, { useContext, useEffect } from "react";
import { callGetApi, callPutApi } from "../../../api/axios";
import { ToastContext } from "../../../context/toastContext";
import Button from "../../../shared/button/button";
import { COMPRESSED } from "../../../shared/buttonSize";
import {
  dangerButtonHoverStyle,
  dangerButtonStyle,
  successButtonHoverStyle,
  successButtonStyle,
} from "../../../shared/buttonStyles";
import Checkbox from "../../../shared/checkbox/checkbox";
import { ACCENTCOLOR } from "../../../shared/colors";
import FilterDropdown from "../../../shared/inTable-filter-dropdown/filterDropdown";
import Loading from "../../../shared/loading/loading";
import Pagination from "../../../shared/pagination/pagination";
import sharedStyle from "../../../shared/sharedStyle.module.scss";
import ArrowDown from "../../../shared/svg/arrowDown";
import Close from "../../../shared/svg/close";
import Sort from "../../../shared/svg/sort";
import UpdateSvg from "../../../shared/svg/updateSvg";
import { ERROR } from "../../../utils/toastType";
import Styles from "./users.module.scss";
import { IdsGenerator } from "../../../utils/idsGenerator";

function UsersList({
  refresh,
  setToggleEditModal,
  setToggleReassignKamModal,
  setListOfAccountsAssigned,
  setToggleViewRolesModal,
  is_allowed_to_delete,
  is_allowed_to_write,
}: any) {
  const sortTypes = {
    ascending: "Ascending",
    descending: "Descending",
  };
  const [selectedUserStatus, setSelectedUserStatus] = React.useState("");
  const [selectedUserIds, setSelectedUserIds] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [userList, setUserList] = React.useState<any>([]);
  const [userNameFilter, setUserNameFilter] = React.useState<String[]>([]);
  const [defaultUserList, setDefaultUserList] = React.useState<any>([]);
  const [listOfUserNames, setListOfUserNames] = React.useState<String[]>([]);
  const [sortType, setSortType] = React.useState("");
  const [activePageNumber, setActivePageNumber] = React.useState(1);
  const [postPerPage] = React.useState(20);
  const dispatch = useContext(ToastContext);
  const indexOfLastAccount = activePageNumber * postPerPage;
  const indexOfFirstAccount = indexOfLastAccount - postPerPage;
  useEffect(() => {
    async function getAllUsers() {
      setLoading(true);
      try {
        const response: any = await callGetApi("/kam");
        const list = response.data.map((data: any, index: number) => ({
          name: data.name ?? `no_name${index}`,
          email: data.email,
          status: data.status,
          role: data.role,
          phone: data.phone,
          joined_on: data.createdAt,
          id: data._id,
        }));
        setLoading(false);
        setUserList(list);
        setDefaultUserList(list);
      } catch (err: any) {
        setLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: err.response.data.err,
          },
        });
      }
    }
    getAllUsers();
  }, [dispatch, refresh]);
  const indicator = (filteredArrayName: String[]) => {
    return (
      <div className="px-1">
        <div
          className={`${Styles.indicator} d-flex align-items-center justify-content-center`}
        >
          <p className={`mb-0 ${Styles.indicatorText}`}>
            {filteredArrayName.length}
          </p>
        </div>
      </div>
    );
  };
  // Sort the column in descending order
  function sortDescending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = -1;
    } else if (leftParam < rightParam) {
      comparison = 1;
    }
    return comparison;
  }
  // Sort the column in ascending order
  function sortAscending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = 1;
    } else if (leftParam < rightParam) {
      comparison = -1;
    }
    return comparison;
  }
  // decide type of sort for specific column
  function sort(type: string) {
    const sortedArray = userList.slice().sort((a: any, b: any) => {
      const leftParam = a.name.toLowerCase();
      const rightParam = b.name.toLowerCase();
      if (type === sortTypes.ascending) {
        return sortAscending(leftParam, rightParam);
      } else {
        return sortDescending(leftParam, rightParam);
      }
    });
    setUserList(sortedArray);
  }
  // decide which sorting to apply based upon column name
  function applySorting() {
    if (sortType === sortTypes.descending) {
      setSortType("");
      setUserList(defaultUserList);
    } else if (sortType === sortTypes.ascending) {
      setSortType(sortTypes.descending);
      sort(sortTypes.descending);
    } else {
      setSortType(sortTypes.ascending);
      sort(sortTypes.ascending);
    }
  }
  // get all the names of the user in the given list
  function getAllUserNames() {
    return setListOfUserNames(userList.map((user: any) => user.name));
  }
  function removeInTableFilter(name: string) {
    return setUserNameFilter(
      userNameFilter.filter((filter) => filter !== name)
    );
  }
  async function enableUser() {
    for (const id of selectedUserIds) {
      setButtonLoading(true);
      try {
        await callPutApi(`/kam/${id}?action=update_kam_status`, {
          status: "enabled",
        });
        setButtonLoading(false);
      } catch (err: any) {
        setButtonLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: err.response.data.err,
          },
        });
      }
    }
    setUserList(
      userList.map((user: any) => {
        if (selectedUserIds.includes(user.id)) {
          return {
            ...user,
            status: "ACTIVE",
          };
        }
        return user;
      })
    );
    setSelectedUserIds([]);
  }
  async function disableUser() {
    const arrayOfIds: string[] = [];
    const listOfAccountsThatAreAssigned: any = [];
    for (const id of selectedUserIds) {
      setButtonLoading(true);
      try {
        await callPutApi(`/kam/${id}?action=update_kam_status`, {
          status: "disabled",
        });
        setButtonLoading(false);
        arrayOfIds.push(id);
      } catch (err: any) {
        setButtonLoading(false);
        const user = userList.find((user: any) => user.id === id);
        listOfAccountsThatAreAssigned.push({
          name: user.name,
          count: err.response.data.err,
        });
      }
    }
    if (listOfAccountsThatAreAssigned.length > 0) {
      setToggleReassignKamModal(true);
      setListOfAccountsAssigned(listOfAccountsThatAreAssigned);
    }
    setUserList(
      userList.map((user: any) => {
        if (arrayOfIds.includes(user.id)) {
          return {
            ...user,
            status: "DISABLED",
          };
        }
        return user;
      })
    );
    setSelectedUserIds([]);
  }
  const filteredUserList = userList.filter(
    (user: any) =>
      // eslint-disable-next-line
      userNameFilter.length == 0 || userNameFilter.includes(user.name)
  );
  // use this function to append 0 if the month number is less than 9
  function checkDate(value: number) {
    if (value < 9) {
      return `0${value}`;
    }
    return value;
  }
  return (
    <div className="py-4">
      <div className={sharedStyle.card}>
        <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
          <div className="d-flex align-items-center">
            <p className={`mb-0 ${sharedStyle.cardHeader}`}>User list</p>
            <div className="px-2 ml-auto">
              <p
                id="user-list-view-roles"
                className={`mb-0 ${Styles.link}`}
                onClick={() => setToggleViewRolesModal(true)}
              >
                View Roles
              </p>
            </div>
            {is_allowed_to_delete &&
            selectedUserStatus === "active" &&
            selectedUserIds.length > 0 ? (
              <div className="px-3">
                <Button
                  id="user-list-disable"
                  size={COMPRESSED}
                  style={dangerButtonStyle}
                  disabled={!is_allowed_to_delete}
                  hoveredStyle={dangerButtonHoverStyle}
                  isLoading={buttonLoading}
                  onClick={disableUser}
                >
                  Disable
                </Button>
              </div>
            ) : is_allowed_to_delete &&
              selectedUserStatus === "disabled" &&
              selectedUserIds.length > 0 ? (
              <div className="px-3">
                <Button
                  id="user-list-enable"
                  size={COMPRESSED}
                  style={successButtonStyle}
                  disabled={!is_allowed_to_delete}
                  hoveredStyle={successButtonHoverStyle}
                  isLoading={buttonLoading}
                  onClick={enableUser}
                >
                  Enable
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        {userNameFilter.length > 0 && (
          <div
            className={`p-2 d-flex align-items-center flex-row-reverse flex-wrap ${Styles.filterBack}`}
          >
            <p
              id="user-list-clear-all"
              className={`px-2 mb-0 ${Styles.clearAll}`}
              onClick={() => setUserNameFilter([])}
            >
              Clear All
            </p>
            {userNameFilter.map((filter: any, index: number) => {
              return (
                <div className="p-1" key={index}>
                  <div
                    className={`d-flex align-items-center ${Styles.filterWrapper}`}
                  >
                    <p className={`mb-0 pl-2 ${Styles.filterName}`}>{filter}</p>
                    <div
                      id={`user-list-close-${filter}`}
                      className="px-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => removeInTableFilter(filter)}
                    >
                      <Close classes={Styles.close} width="8" height="8" />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {loading ? (
          <div
            style={{ height: "350px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loading loadingColor={ACCENTCOLOR} />
          </div>
        ) : (
          <>
            <div style={{ overflowX: "auto", minHeight: "400px" }}>
              <table style={{ width: "100%" }}>
                <thead className={sharedStyle.tableHeaderBack}>
                  <tr>
                    {is_allowed_to_delete && (
                      <th className={Styles.checkBox_column}>
                        {selectedUserIds.length > 0 && (
                          <Checkbox
                            id={IdsGenerator({
                              prefix: "user list",
                              id: "select",
                              sufix: "all users",
                            })}
                            disabled={buttonLoading}
                            checked={selectedUserIds.length > 0}
                            onChange={() => {
                              setSelectedUserIds([]);
                            }}
                          />
                        )}
                      </th>
                    )}
                    <th
                      className={Styles.name_column}
                      style={{ padding: "15px 15px 15px 25px" }}
                    >
                      <div className="d-flex align-items-center">
                        <p className={`mb-0 ${Styles.tableHeaderText}`}>Name</p>
                        {userNameFilter.length !== 0 &&
                          indicator(userNameFilter)}
                        <div className="px-2">
                          <Sort
                            classes={`${Styles.sort} ${
                              sortType === sortTypes.descending
                                ? Styles.rotate
                                : ""
                            } ${sortType !== "" ? Styles.active : ""}`}
                            click={() => applySorting()}
                          />
                        </div>
                        <FilterDropdown
                          width="300px"
                          height="200px"
                          optionArray={listOfUserNames}
                          filters={userNameFilter}
                          onSelect={(option: any) => {
                            setActivePageNumber(1);
                            if (userNameFilter.includes(option)) {
                              setUserNameFilter(
                                userNameFilter.filter(
                                  (data: any) => data !== option
                                )
                              );
                              return;
                            }
                            setUserNameFilter([...userNameFilter, option]);
                          }}
                          toggleReset={userNameFilter.length !== 0}
                          onReset={() => setUserNameFilter([])}
                        >
                          <div
                            id="user-list-get-all-user-names"
                            className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                            onClick={() => getAllUserNames()}
                          >
                            <ArrowDown classes={Styles.arrow} />
                          </div>
                        </FilterDropdown>
                      </div>
                    </th>
                    <th className={Styles.email_column}>
                      <p className={`mb-0 ${Styles.tableHeaderText}`}>Email</p>
                    </th>
                    <th className={Styles.joined_on_column}>
                      <p className={`mb-0 ${Styles.tableHeaderText}`}>
                        Joined On
                      </p>
                    </th>
                    <th className={Styles.role_column}>
                      <p className={`mb-0 ${Styles.tableHeaderText}`}>Role</p>
                    </th>
                    <th className={Styles.status_column}>
                      <p className={`mb-0 ${Styles.tableHeaderText}`}>Status</p>
                    </th>
                    <th className={Styles.edit_column}></th>
                  </tr>
                </thead>
                <tbody className={Styles.tableWrapper}>
                  {filteredUserList.length > 0 ? (
                    filteredUserList
                      .slice(indexOfFirstAccount, indexOfLastAccount)
                      .map((user: any, index: number) => {
                        const date = new Date(user.joined_on);
                        return (
                          <tr
                            key={index}
                            className={`${Styles.table_row_wrapper} ${
                              selectedUserIds.length > 0 &&
                              !selectedUserStatus.includes(
                                user.status.toLowerCase()
                              )
                                ? Styles.fadedRow
                                : ""
                            }`}
                            style={
                              index !== filteredUserList.length - 1
                                ? { borderBottom: "1px solid #eceeef" }
                                : { borderBottom: 0 }
                            }
                          >
                            {is_allowed_to_delete && (
                              <td className={Styles.checkBox_column}>
                                <Checkbox
                                  id={IdsGenerator({
                                    prefix: "user list",
                                    id: "select",
                                    sufix: user.name,
                                  })}
                                  disabled={
                                    buttonLoading ||
                                    (selectedUserIds.length > 0 &&
                                      selectedUserStatus !==
                                        user.status.toLowerCase())
                                  }
                                  checked={selectedUserIds?.includes(user.id)}
                                  onChange={() => {
                                    setSelectedUserStatus(
                                      user.status.toLowerCase()
                                    );
                                    if (selectedUserIds?.includes(user.id)) {
                                      setSelectedUserIds(
                                        selectedUserIds.filter(
                                          (u) => u !== user.id
                                        )
                                      );
                                      return;
                                    }
                                    setSelectedUserIds([
                                      ...selectedUserIds,
                                      user.id,
                                    ]);
                                  }}
                                />
                              </td>
                            )}
                            <td
                              className={Styles.name_column}
                              style={{ padding: "15px 15px 15px 25px" }}
                            >
                              <p className={`mb-0 ${Styles.tableBodyText}`}>
                                {user.name}
                              </p>
                            </td>
                            <td className={Styles.email_column}>
                              <p className={`mb-0 ${Styles.tableBodyText}`}>
                                {user.email}
                              </p>
                            </td>
                            <td className={Styles.joined_on_column}>
                              <p className={`mb-0 ${Styles.tableBodyText}`}>
                                {/* {`${monthName(
                                    date.getMonth()
                                  )} ${date.getDate()}, ${date.getFullYear()}`} */}
                                {`${checkDate(date.getMonth() + 1)}-${checkDate(
                                  date.getDate()
                                )}-${date.getFullYear()}`}
                              </p>
                            </td>
                            <td className={Styles.role_column}>
                              <p className={`mb-0 ${Styles.tableBodyText}`}>
                                {user.role}
                              </p>
                            </td>
                            <td className={Styles.status_column}>
                              <p className={`mb-0 ${Styles.tableBodyText}`}>
                                {user.status}
                              </p>
                            </td>
                            <td className={Styles.edit_column}>
                              {is_allowed_to_write && (
                                <div className={Styles.show_edit}>
                                  <UpdateSvg
                                    wrapper_width="30px"
                                    wrapper_height="30px"
                                    svg_width="15"
                                    svg_height="15"
                                    click={() =>
                                      setToggleEditModal((prev: any) => ({
                                        ...prev,
                                        item: "Users",
                                        toggle: true,
                                        object: user,
                                      }))
                                    }
                                  />
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr
                      style={{ height: "400px" }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <td className="mb-0">No Accounts found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="px-4 py-3 d-flex align-items-center">
              <p className={`mb-0 ${Styles.numberOfRows}`}>
                {
                  filteredUserList.slice(
                    indexOfFirstAccount,
                    indexOfLastAccount
                  ).length
                }
                rows per page
              </p>
              <div className="py-2 ml-auto">
                <Pagination
                  activePageNumber={activePageNumber}
                  total={filteredUserList.length}
                  postPerPage={postPerPage}
                  navigateToPage={(value: number) => {
                    setActivePageNumber(value);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default UsersList;
