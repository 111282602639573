// Hooks
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "../../../utils/customHooks";
import { useDispatch, useSelector } from "react-redux";

// Library Utilities
import { Link } from "react-router-dom";

// constants
import { ACCENTCOLOR, PRIMARYCOLOR } from "../../../shared/colors";
import { onboardingStatuses } from "../../../constants/onboardingStatuses";

// types
import {
  productType,
  appType,
  accountType,
  filterType,
} from "./product-filter/productFilter";

// style
import Styles from "./accountListing.module.scss";
import sharedStyle from "../../../shared/sharedStyle.module.scss";
import tagStyles from "../../../shared/filter-tag/filterTagModal.module.scss";

// components
import RupeeSVG from "../../../shared/svg/rupeeSvg";
import Sort from "../../../shared/svg/sort";
import ArrowDown from "../../../shared/svg/arrowDown";
import FilterDropdown from "../../../shared/inTable-filter-dropdown/filterDropdown";
import Pagination from "../../../shared/pagination/pagination";
import Loading from "../../../shared/loading/loading";
import { ToastContext } from "../../../context/toastContext";
import Close from "../../../shared/svg/close";
import FilterTagModal from "../../../shared/filter-tag/filterTagModal";

// functions
import { ERROR } from "../../../utils/toastType";
import { callPostApi } from "../../../api/axios";
import {
  getAmountWithGST,
  numberWithCommas,
  roundOfDecimal,
} from "../../../utils/updateAmount";

// redux
import { getOrgFilterData } from "../accountListing/redux-slice/accountFilterData";
import { AppDispatch } from "../../../redux/store/store";
import { IdsGenerator } from "../../../utils/idsGenerator";

function AccountListing() {
  const query = useQuery();
  const filter = query.get("account_manager") || "";

  const dispatchAction = useDispatch<AppDispatch>();
  const orgData = useSelector((state: any) => state.data.orgData);
  const loadingFilter = useSelector((state: any) => state.data.loading);

  useEffect(() => {
    if (orgData.length === 0) {
      dispatchAction(getOrgFilterData());
    }
  }, [dispatchAction, orgData]);

  const sortTypes = {
    ascending: "Ascending",
    descending: "Descending",
  };
  const sortingColumns = {
    id: "id",
    name: "name",
    user_email: "user_email",
    onboarding_status: "onboarding_status",
    payment_status: "payment_status",
    current_balance: "current_balance",
    account_manager: "account_manager",
    business_manager: "business_manager",
  };
  const [filterBasedOnSorting, setFilterBasedOnSorting] = React.useState({
    sortType: "",
    sortingColumn: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [customerList, setCustomerList] = React.useState<any>([]);
  const [defaultCustomerList, setDefaultCustomerList] = React.useState<any>([]);
  const [accountIdFilter, setAccountIdFilter] = React.useState<any>([]);
  const [accountNameFilter, setAccountNameFilter] = React.useState<any>([]);
  const [paymentStatusFilter, setPaymentStatusFilter] = React.useState<any>([]);
  const [accountManagerFilter, setAccountManagerFilter] = React.useState<any>(
    filter !== "" ? [filter] : []
  );
  const [businessManagerFilter, setBusinessManagerFilter] = React.useState<any>(
    filter !== "" ? [filter] : []
  );
  const [emailFilter, setemailFilter] = React.useState<any>([]);
  const [onboardingFilter, setOnboardingFilter] = React.useState<any>([]);
  const [filters, setFilters] = React.useState<any>([]);
  const [listOfNames, setListOfNames] = React.useState<any>([]);
  const [listOfEmail, setListOfEmail] = React.useState<any>([]);
  const [listOfOnboardingStatus, setListOfOnboardingStatus] =
    React.useState<any>([]);
  const [filterAccountCheck, setFilterAccountCheck] = useState(false);
  const [listOfAccountManager, setListOfAccountManager] = React.useState<any>(
    []
  );
  const [listOfBusinessManager, setListOfBusinessManager] = React.useState<any>(
    []
  );
  const [allAccountLength, setAllAccountLength] = useState(0);
  const [activePageNumber, setActivePageNumber] = React.useState(1);
  const [postPerPage] = React.useState(20);
  const dispatch = useContext(ToastContext);
  const indicator = (filteredArrayName: string[]) => {
    return (
      <div className="px-1">
        <div
          className={`${Styles.indicator} d-flex align-items-center justify-content-center`}
        >
          <p className={`mb-0 ${Styles.indicatorText}`}>
            {filteredArrayName.length}
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setFilters([
      ...accountIdFilter,
      ...accountNameFilter,
      ...paymentStatusFilter,
      ...accountManagerFilter,
      ...businessManagerFilter,
      ...emailFilter,
      ...onboardingFilter,
    ]);
  }, [
    accountIdFilter,
    accountNameFilter,
    paymentStatusFilter,
    accountManagerFilter,
    businessManagerFilter,
    emailFilter,
    onboardingFilter,
  ]);
  async function getAllCustomerAccounts(pageNumber?: any) {
    setLoading(true);
    try {
      const orgData: any = await callPostApi(
        `/organisations?per_page=${postPerPage}&page_no=${pageNumber}`,
        {
          filter: {
            ...(accountNameFilter &&
              accountNameFilter.length > 0 && { org_name: accountNameFilter }),
            ...(accountIdFilter &&
              accountIdFilter.length > 0 && { org_id: accountIdFilter }),
            ...(emailFilter &&
              emailFilter.length > 0 && { org_email: emailFilter }),
            ...(accountManagerFilter &&
              accountManagerFilter.length > 0 && {
                kam_name: accountManagerFilter,
              }),
            ...(onboardingFilter &&
              onboardingFilter.length > 0 && {
                onboarding_status: onboardingFilter.map(getKeyByValue),
              }),
          },
        }
      );
      const { organisations, totalOrgsLength } = orgData.data;
      const list = organisations.map((account: any) => ({
        id: account._id,
        name: account.name,
        env: account.environment,
        onboarding_status: onboardingStatuses.find(
          (status) => status.key === account.onboarding_status
        )?.value,
        payment_status: account.payment_status ?? [],
        current_balance: roundOfDecimal(
          getAmountWithGST(account.wallet_balance)
        ).toFixed(2),
        account_manager: account.kam.kam_name ?? "Not Assigned",
        business_manager: account?.business_manager?.bm_name ?? "Not Assigned",
        user_email: account.user_email || "NA",
      }));
      setCustomerList(list);
      setAllAccountLength(totalOrgsLength);
      setDefaultCustomerList(list);
      setFilterAccountCheck(true);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message: err.response.data.err,
        },
      });
    }
  }
  useEffect(() => {
    getAllCustomerAccounts(1);
  }, [dispatch]);

  const getKeyByValue = (value: any) => {
    const status = onboardingStatuses.find((status) => status.value === value);
    return status ? status.key : null;
  };

  useEffect(() => {
    if (
      accountIdFilter.length > 0 ||
      accountNameFilter.length > 0 ||
      emailFilter.length > 0 ||
      accountManagerFilter.length > 0 ||
      onboardingFilter.length > 0
    ) {
      getAllCustomerAccounts(1);
      setActivePageNumber(1);
    }
  }, [
    accountIdFilter,
    accountNameFilter,
    emailFilter,
    accountManagerFilter,
    onboardingFilter,
  ]);

  // filter the accounts list
  const filteredCustomerAccounts = customerList;

  // Sort the column in descending order
  function sortDescending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = -1;
    } else if (leftParam < rightParam) {
      comparison = 1;
    }
    return comparison;
  }
  // Sort the column in ascending order
  function sortAscending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = 1;
    } else if (leftParam < rightParam) {
      comparison = -1;
    }
    return comparison;
  }

  function sort(type: string, columnName: string) {
    const sortedArray = customerList.slice().sort((a: any, b: any) => {
      let leftParam;
      let rightParam;
      if (columnName === sortingColumns.current_balance) {
        leftParam = Number(a[columnName]);
        rightParam = Number(b[columnName]);
      } else {
        leftParam = a[columnName]?.toLowerCase();
        rightParam = b[columnName]?.toLowerCase();
      }
      if (type === sortTypes.ascending) {
        return sortAscending(leftParam, rightParam);
      } else {
        return sortDescending(leftParam, rightParam);
      }
    });
    setCustomerList(sortedArray);
  }
  // decide which sorting to apply based upon column name
  function applySorting(columnName: string) {
    if (
      filterBasedOnSorting.sortType === sortTypes.descending &&
      filterBasedOnSorting.sortingColumn === columnName
    ) {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: "",
        sortingColumn: columnName,
      }));
      /* setCustomerList(defaultCustomerList); */
      if (selectedFilters.length === 0) {
        setCustomerList(defaultCustomerList);
      } else {
        setCustomerList(productWiseFilteredAccounts);
      }
    } else if (
      filterBasedOnSorting.sortType === sortTypes.ascending &&
      filterBasedOnSorting.sortingColumn === columnName
    ) {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: sortTypes.descending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.descending, columnName);
    } else {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: sortTypes.ascending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.ascending, columnName);
    }
  }

  //only ascending and descending sorting for column, current balance
  function applyCurrentBalanceSorting(columnName: string) {
    if (
      filterBasedOnSorting.sortType === sortTypes.ascending &&
      filterBasedOnSorting.sortingColumn === columnName
    ) {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: sortTypes.descending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.descending, columnName);
    } else {
      setFilterBasedOnSorting((filterBasedOnSorting) => ({
        ...filterBasedOnSorting,
        sortType: sortTypes.ascending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.ascending, columnName);
    }
  }

  function clearAllFilters() {
    setAccountIdFilter([]);
    setAccountNameFilter([]);
    setPaymentStatusFilter([]);
    setAccountManagerFilter([]);
    setBusinessManagerFilter([]);
    setemailFilter([]);
    setOnboardingFilter([]);
  }

  useEffect(() => {
    if (filterAccountCheck) {
      if (
        accountIdFilter.length === 0 &&
        accountNameFilter.length === 0 &&
        accountManagerFilter.length === 0 &&
        emailFilter.length === 0 &&
        onboardingFilter.length === 0
      ) {
        getAllCustomerAccounts(1);
        setFilterAccountCheck(false);
      }
    }
  }, [
    accountIdFilter,
    accountNameFilter,
    accountManagerFilter,
    emailFilter,
    onboardingFilter,
    // filterAccountCheck,
  ]);

  function removeInTableFilter(filter: string) {
    if (accountIdFilter.includes(filter)) {
      setAccountIdFilter(
        accountIdFilter.filter((id: string) => {
          return id !== filter;
        })
      );
    } else if (accountNameFilter.includes(filter)) {
      setAccountNameFilter(
        accountNameFilter.filter((name: string) => {
          return name !== filter;
        })
      );
    } else if (emailFilter.includes(filter)) {
      setemailFilter(
        emailFilter.filter((status: string) => {
          return status !== filter;
        })
      );
    } else if (onboardingFilter.includes(filter)) {
      setOnboardingFilter(
        onboardingFilter.filter((status: string) => {
          return status !== filter;
        })
      );
    } else if (paymentStatusFilter.includes(filter)) {
      setPaymentStatusFilter(
        paymentStatusFilter.filter((status: string) => {
          return status !== filter;
        })
      );
    } else if (accountManagerFilter.includes(filter)) {
      setAccountManagerFilter(
        accountManagerFilter.filter((name: string) => {
          return name !== filter;
        })
      );
    } else if (businessManagerFilter.includes(filter)) {
      setBusinessManagerFilter(
        businessManagerFilter.filter((name: string) => {
          return name !== filter;
        })
      );
    }
  }

  // get all account names from the list
  function getAllAccountName() {
    const names = customerList.map((account: any) => account.name);
    // Remove duplicate names form the array.
    return setListOfNames(
      names.filter(
        (name: string, index: number) => names.indexOf(name) === index
      )
    );
  }
  // get all Email from the list
  function getAllUserEmail() {
    const uniqueEmails = [
      ...new Set(orgData.map((account: any) => account.user_email || "")),
    ];
    return setListOfEmail(uniqueEmails.filter(Boolean));
  }

  useEffect(() => {
    getAllUserEmail();
    getAllOnboardingStatus();
    getAllAccountManagers();
  }, [orgData]);
  // get all onboarding statuses from the list
  function getAllOnboardingStatus() {
    const listOfStatus = orgData.map(
      (account: any) =>
        onboardingStatuses.find(
          (status) => status.key === account.onboarding_status
        )?.value
    );
    setListOfOnboardingStatus(
      listOfStatus.filter(
        (status: string, index: number) =>
          listOfStatus.indexOf(status) === index
      )
    );
  }

  // get all account manager names from the list
  function getAllAccountManagers() {
    const names = orgData
      .map((account: any) => account.kam.kam_name)
      .filter(
        (kam_name: string | null | undefined) =>
          kam_name !== null && kam_name !== undefined
      );

    setListOfAccountManager(
      names.filter(
        (name: string, index: number) => names.indexOf(name) === index
      )
    );
  }

  // get all business manager names from the list
  function getAllBusinessManagers() {
    const names = customerList.map((account: any) => account.business_manager);
    setListOfBusinessManager(
      names.filter(
        (name: string, index: number) => names.indexOf(name) === index
      )
    );
  }

  const [productFilterBtnDisabled, setProductFilterBtnDisabled] =
    useState<boolean>(true);
  const [selectedFilters, setSelectedFilters] = useState<filterType[]>([]);
  const [productWiseFilteredAccounts, setProductWiseFilteredAccounts] =
    useState<accountType[]>([]);

  //get all filters selected in productFilter component (child) and set the same state here (parent)
  function getAllSelectedFilters(allSelectedFilters: filterType[]) {
    setSelectedFilters(allSelectedFilters);
  }

  //get list of filtered accounts and set state for display in rows
  function getFilteredAccountsByProduct(filteredAccounts: accountType[]) {
    setCustomerList(filteredAccounts);
    setProductWiseFilteredAccounts(filteredAccounts);
  }

  function removeProductFilterTag(filterToBeRemoved: filterType) {
    const updatedListOfFilters: filterType[] = selectedFilters.filter(
      (filterItem: filterType) => {
        return (
          Object.entries(filterItem).toString() !==
          Object.entries(filterToBeRemoved).toString() //change this inequality check if filter-object:filterType becomes nested in future
        );
      }
    );

    const allAccounts: accountType[] = defaultCustomerList;

    //re-run filtera-tion logic as selected filters have been updated
    const allFilteredAccounts: accountType[] = allAccounts.filter(
      (account: accountType) => {
        let numFiltersPassedByAccount = 0;
        for (const currentFilter of updatedListOfFilters) {
          //isAccountValid indicates weather the account we are iterating , qualifies the filter we are iterating currently
          let isAccountValid: boolean = false;
          switch (true) {
            case Object.hasOwn(currentFilter, "is_none_trial_live"):
              isAccountValid = neitherFilterLogic(account, currentFilter);
              break;
            case Object.hasOwn(currentFilter, "is_either_trial_live"):
              isAccountValid = eitherFilterLogic(account, currentFilter);
              break;
            case Object.hasOwn(currentFilter, "is_both_trial_live"):
              isAccountValid = bothFilterLogic(account, currentFilter);
              break;
            default:
              isAccountValid = testLiveFilterLogic(account, currentFilter);
          }
          numFiltersPassedByAccount = isAccountValid
            ? numFiltersPassedByAccount + 1
            : numFiltersPassedByAccount;
        }
        return numFiltersPassedByAccount === updatedListOfFilters.length
          ? true
          : false;
      }
    );

    setProductWiseFilteredAccounts(allFilteredAccounts);
    setCustomerList(allFilteredAccounts);
    setSelectedFilters(updatedListOfFilters);
  }

  function neitherFilterLogic(
    account: accountType,
    currentFilter: filterType
  ): boolean {
    const productsAddedToAccount: productType[] = account.apps
      .map((app: appType) => app.products_added)
      .reduce((prev, next) => {
        next = next.filter(
          (prod: productType) =>
            prod !== null && prod !== undefined && Object.keys(prod).length > 0
        );
        return prev.concat(next);
      }, []);

    const isCurrentFilterProductAddedToNoApps: boolean =
      productsAddedToAccount.every((product: productType) => {
        return product.arn !== currentFilter.productArn;
      });

    return isCurrentFilterProductAddedToNoApps;
  }

  function eitherFilterLogic(
    account: accountType,
    currentFilter: filterType
  ): boolean {
    const productsAddedToAccount: productType[] = account.apps
      .map((app: appType) => app.products_added)
      .reduce((prev, next) => {
        next = next.filter(
          (prod: productType) =>
            prod !== null && prod !== undefined && Object.keys(prod).length > 0
        );
        return prev.concat(next);
      }, []);

    const isCurrentFilterProductAddedEitherApps: boolean =
      productsAddedToAccount.some((product: productType) => {
        return product.arn === currentFilter.productArn;
      });

    return isCurrentFilterProductAddedEitherApps;
  }

  function bothFilterLogic(
    account: accountType,
    currentFilter: filterType
  ): boolean {
    const productsAddedToTestAppOfAccount: productType[] = account.apps
      .filter((app: appType) => {
        return app.is_test_app === true;
      })
      .map((app: appType) => app.products_added)
      .reduce((prev, next) => {
        next = next.filter(
          (prod: productType) =>
            prod !== null && prod !== undefined && Object.keys(prod).length > 0
        );
        return prev.concat(next);
      }, []);

    const productsAddedToLiveAppsOfAccount: productType[] = account.apps
      .filter((app: appType) => {
        return app.is_test_app === false;
      })
      .map((app: appType) => app.products_added)
      .reduce((prev, next) => {
        next = next.filter(
          (prod: productType) =>
            prod !== null && prod !== undefined && Object.keys(prod).length > 0
        );
        return prev.concat(next);
      }, []);

    const isCurrentFilterProductAddedToBothTestAndLiveApps: boolean =
      productsAddedToTestAppOfAccount.some((product: productType) => {
        return product.arn === currentFilter.productArn;
      }) &&
      productsAddedToLiveAppsOfAccount.some((product: productType) => {
        return product.arn === currentFilter.productArn;
      });

    return isCurrentFilterProductAddedToBothTestAndLiveApps;
  }

  function testLiveFilterLogic(
    account: accountType,
    currentFilter: filterType
  ): boolean {
    const productsAddedToAccount: productType[] = account.apps
      .filter((app: appType) => {
        return app.is_test_app === currentFilter.is_test_app;
      })
      .map((app: appType) => app.products_added)
      .reduce((prev, next) => {
        next = next.filter(
          (prod: productType) =>
            prod !== null && prod !== undefined && Object.keys(prod).length > 0
        );
        return prev.concat(next);
      }, []);

    const isCurrentFilterProductAddedToAnyApps: boolean =
      productsAddedToAccount.some((product: productType) => {
        return product.arn === currentFilter.productArn;
      });
    return isCurrentFilterProductAddedToAnyApps;
  }

  function clearAllProductFilters() {
    setCustomerList(defaultCustomerList);

    setProductWiseFilteredAccounts([]);
    setSelectedFilters([]);
    clearAllFilters(); // for clearing column-heading filter tags along with product type tags
  }

  function extractTagTitle(filter: filterType) {
    //return `${filter.productName} in ${filter.appType}`;
    return (
      <p className={`mb-0 px-2 ${tagStyles.tagTitle}`}>
        <span style={{ fontSize: "12px" }}>{`${filter.productName} in `}</span>
        <span
          style={
            filter.appType === "Live App"
              ? { ...{ color: "red", fontWeight: "bold", fontSize: "12px" } }
              : filter.appType === "Both App"
              ? {
                  ...{ color: "#E5B42F", fontWeight: "bold", fontSize: "12px" },
                }
              : filter.appType === "Either App"
              ? {
                  ...{
                    color: "transparent",
                    fontWeight: "bold",
                    fontSize: "12px",
                    backgroundImage:
                      "linear-gradient(to left, mediumblue, royalblue, deepskyblue )",
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                  },
                }
              : filter.appType === "Test App"
              ? { ...{ color: "green", fontWeight: "bold", fontSize: "12px" } }
              : { ...{ color: "black", fontWeight: "bold", fontSize: "12px" } } //for neither app option
          }
        >
          {`${filter.appType}`}
        </span>
      </p>
    );
  }

  return (
    <div>
      <div>
        <div className="col-md-12 p-3">
          <div className={`${sharedStyle.card}`}>
            <div className={`px-4 pt-4 ${sharedStyle.cardHeaderBack}`}>
              <div className="d-flex align-items-center pb-3">
                <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                  Customer Accounts
                </p>
              </div>
            </div>
            {(filters.length !== 0 || selectedFilters.length > 0) && (
              <div
                className={`p-2 d-flex align-items-center flex-row-reverse flex-wrap ${Styles.filterBack}`}
              >
                <FilterTagModal
                  allFilters={selectedFilters}
                  extractTagTitleHandler={extractTagTitle}
                  clearAllFiltersHandler={clearAllProductFilters}
                  removeFilterTagHandler={removeProductFilterTag}
                />

                {filters.map((filter: any, index: number) => {
                  return (
                    <div className="p-1" key={index}>
                      <div
                        className={`d-flex align-items-center ${Styles.filterWrapper}`}
                      >
                        <p className={`mb-0 pl-2 ${Styles.filterName}`}>
                          {filter}
                        </p>
                        <div
                          id="account-listing-close"
                          className="px-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => removeInTableFilter(filter)}
                        >
                          <Close classes={Styles.close} width="8" height="8" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {loading ? (
              <div
                style={{ height: "50vh" }}
                className="d-flex align-items-center justify-content-center"
              >
                <Loading loadingColor={ACCENTCOLOR} />
              </div>
            ) : (
              <div>
                <div style={{ overflowX: "auto", minHeight: "450px" }}>
                  <table style={{ width: "100%" }}>
                    <thead className={sharedStyle.tableHeaderBack}>
                      <tr>
                        <th className={Styles.accID}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Acc/ID
                            </p>
                            {accountIdFilter.length !== 0 &&
                              indicator(accountIdFilter)}
                            <div className="px-2">
                              <Sort
                                id={IdsGenerator({
                                  prefix: "account listing",
                                  id: "account id",
                                  sufix: "sort",
                                })}
                                classes={`${Styles.sort} ${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.id
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.id
                                    ? Styles.active
                                    : ""
                                }`}
                                click={() => applySorting(sortingColumns.id)}
                              />
                            </div>
                            <FilterDropdown
                              id={IdsGenerator({
                                prefix: "account listing",
                                id: "filter",
                                sufix: "account id",
                              })}
                              width="300px"
                              optionArray={orgData?.map((orgs: any) => {
                                return orgs._id;
                              })}
                              filters={accountIdFilter}
                              onSelect={(option: any) => {
                                if (accountIdFilter.includes(option)) {
                                  setAccountIdFilter(
                                    accountIdFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setAccountIdFilter([
                                  ...accountIdFilter,
                                  option,
                                ]);
                              }}
                              loading={loadingFilter}
                              toggleReset={accountIdFilter.length !== 0}
                              onReset={() => setAccountIdFilter([])}
                            >
                              <div
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                // onClick={() => getAllAccountId()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>
                        <th className={Styles.name}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Name
                            </p>
                            {accountNameFilter.length !== 0 &&
                              indicator(accountNameFilter)}
                            <div className="px-2">
                              <Sort
                                id={IdsGenerator({
                                  prefix: "account listing",
                                  id: "name",
                                  sufix: "sort",
                                })}
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.name
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.name
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() => applySorting(sortingColumns.name)}
                              />
                            </div>
                            <FilterDropdown
                              id={IdsGenerator({
                                prefix: "account listing",
                                id: "filter",
                                sufix: "name",
                              })}
                              width="300px"
                              optionArray={orgData?.map((orgs: any) => {
                                return orgs.name;
                              })}
                              filters={accountNameFilter}
                              onSelect={(option: any) => {
                                if (accountNameFilter.includes(option)) {
                                  setAccountNameFilter(
                                    accountNameFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setAccountNameFilter([
                                  ...accountNameFilter,
                                  option,
                                ]);
                              }}
                              loading={loadingFilter}
                              toggleReset={accountNameFilter.length !== 0}
                              onReset={() => setAccountNameFilter([])}
                            >
                              <div
                                id="account-listing-get-all-account-name"
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllAccountName()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>
                        <th className={Styles.email}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Email
                            </p>
                            {emailFilter.length !== 0 && indicator(emailFilter)}
                            <div className="px-2">
                              <Sort
                                id={IdsGenerator({
                                  prefix: "account listing",
                                  id: "email",
                                  sufix: "sort",
                                })}
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.user_email
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.user_email
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() =>
                                  applySorting(sortingColumns.user_email)
                                }
                              />
                            </div>
                            <FilterDropdown
                              id={IdsGenerator({
                                prefix: "account listing",
                                id: "filter",
                                sufix: "email",
                              })}
                              width="200px"
                              optionArray={listOfEmail}
                              filters={emailFilter}
                              onSelect={(option: any) => {
                                if (emailFilter.includes(option)) {
                                  setemailFilter(
                                    emailFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setemailFilter([...emailFilter, option]);
                              }}
                              toggleReset={emailFilter.length !== 0}
                              loading={loadingFilter}
                              onReset={() => setemailFilter([])}
                            >
                              <div
                                id="account-listing-get-all-user-email"
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllUserEmail()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>
                        <th className={Styles.onboarding_status}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Onboarding Status
                            </p>
                            {onboardingFilter.length !== 0 &&
                              indicator(onboardingFilter)}
                            <div className="px-2">
                              <Sort
                                id={IdsGenerator({
                                  prefix: "account listing",
                                  id: "onboarding status",
                                  sufix: "sort",
                                })}
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.onboarding_status
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.onboarding_status
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() =>
                                  applySorting(sortingColumns.onboarding_status)
                                }
                              />
                            </div>
                            <FilterDropdown
                              id={IdsGenerator({
                                prefix: "account listing",
                                id: "filter",
                                sufix: "onboarding status",
                              })}
                              width="300px"
                              optionArray={listOfOnboardingStatus}
                              filters={onboardingFilter}
                              onSelect={(option: any) => {
                                if (onboardingFilter.includes(option)) {
                                  setOnboardingFilter(
                                    onboardingFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setOnboardingFilter([
                                  ...onboardingFilter,
                                  option,
                                ]);
                              }}
                              toggleReset={onboardingFilter.length !== 0}
                              loading={loadingFilter}
                              onReset={() => setOnboardingFilter([])}
                            >
                              <div
                                id="account-listing-get-all-onboarding-status"
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllOnboardingStatus()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>
                        <th className={Styles.amount}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Current Balance
                            </p>
                            <div className="px-2">
                              <Sort
                                id={IdsGenerator({
                                  prefix: "account listing",
                                  id: "current balance",
                                  sufix: "sort",
                                })}
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.current_balance
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.current_balance
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() =>
                                  applyCurrentBalanceSorting(
                                    sortingColumns.current_balance
                                  )
                                }
                              />
                            </div>
                          </div>
                        </th>
                        <th className={Styles.account_manager}>
                          <div className="d-flex align-items-center">
                            <p className={`mb-0 ${Styles.tableHeaderText}`}>
                              Account Manager
                            </p>
                            {accountManagerFilter.length !== 0 &&
                              indicator(accountManagerFilter)}
                            <div className="px-2">
                              <Sort
                                id={IdsGenerator({
                                  prefix: "account listing",
                                  id: "account manager",
                                  sufix: "sort",
                                })}
                                classes={`${
                                  filterBasedOnSorting.sortType ===
                                    sortTypes.descending &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.account_manager
                                    ? Styles.rotate
                                    : ""
                                } ${
                                  filterBasedOnSorting.sortType !== "" &&
                                  filterBasedOnSorting.sortingColumn ===
                                    sortingColumns.account_manager
                                    ? Styles.active
                                    : Styles.sort
                                }`}
                                click={() =>
                                  applySorting(sortingColumns.account_manager)
                                }
                              />
                            </div>
                            <FilterDropdown
                              id={IdsGenerator({
                                prefix: "account listing",
                                id: "filter",
                                sufix: "account manager",
                              })}
                              width="300px"
                              optionArray={listOfAccountManager}
                              filters={accountManagerFilter}
                              onSelect={(option: any) => {
                                if (accountManagerFilter.includes(option)) {
                                  setAccountManagerFilter(
                                    accountManagerFilter.filter(
                                      (data: any) => data !== option
                                    )
                                  );
                                  return;
                                }
                                setAccountManagerFilter([
                                  ...accountManagerFilter,
                                  option,
                                ]);
                              }}
                              toggleReset={accountManagerFilter.length !== 0}
                              loading={loadingFilter}
                              onReset={() => setAccountManagerFilter([])}
                            >
                              <div
                                id="account-listing-get-all-account-manager"
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                                onClick={() => getAllAccountManagers()}
                              >
                                <ArrowDown classes={Styles.arrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredCustomerAccounts.length !== 0 ? (
                        filteredCustomerAccounts.map(
                          (account: any, index: number) => {
                            return (
                              <tr
                                key={index}
                                style={{ borderBottom: "1px solid #eceeef" }}
                              >
                                <td className={Styles.accID}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {account.id}
                                  </p>
                                </td>
                                <td className={Styles.name}>
                                  <Link
                                    id={IdsGenerator({
                                      prefix: "account listing",
                                      id: "link to",
                                      sufix: `account ${account.name}`,
                                    })}
                                    to={`/application/accounts/${account.id}`}
                                    className={`mb-0 ${Styles.accountName}`}
                                  >
                                    {account.name}
                                  </Link>
                                </td>
                                <td className={Styles.email}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {account.user_email}
                                  </p>
                                </td>
                                <td className={Styles.onboarding_status}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {account.onboarding_status}
                                  </p>
                                </td>
                                <td className={Styles.amount}>
                                  <div className="d-flex align-items-center">
                                    <div className="pr-2">
                                      <RupeeSVG
                                        width="8"
                                        height="12"
                                        color={PRIMARYCOLOR}
                                      />
                                    </div>
                                    <p
                                      className={`mb-0 ${Styles.tableBodyText}`}
                                    >
                                      {numberWithCommas(
                                        account.current_balance
                                      )}
                                    </p>
                                  </div>
                                </td>
                                <td className={Styles.account_manager}>
                                  <p className={`mb-0 ${Styles.tableBodyText}`}>
                                    {account.account_manager}
                                  </p>
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr
                          style={{ height: "400px" }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <td className="mb-0">No Accounts found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="px-4 py-3 d-flex align-items-center">
                  <p className={`mb-0 ${Styles.numberOfRows}`}>
                    {customerList?.length} rows per page
                  </p>
                  <div className="py-2 ml-auto">
                    <Pagination
                      activePageNumber={activePageNumber}
                      total={allAccountLength}
                      postPerPage={postPerPage}
                      navigateToPage={(value: number) => {
                        setActivePageNumber(value);
                        getAllCustomerAccounts(value);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountListing;
