// Hooks
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";

// Library Utilities
import { Link } from "react-router-dom";

// Components
import FilterDropdown from "../../../../shared/inTable-filter-dropdown/filterDropdown";
import Loading from "../../../../shared/loading/loading";
import Pagination from "../../../../shared/pagination/pagination";
import { ToastContext } from "../../../../context/toastContext";

// svg & style
import ArrowDown from "../../../../shared/svg/arrowDown";
import Close from "../../../../shared/svg/close";
import Sort from "../../../../shared/svg/sort";
import Styles from "../home.module.scss";
import sharedStyle from "../../../../shared/sharedStyle.module.scss";

// functions
import { getDuration } from "../../../../utils/getDuration";
import { getDurationStyle } from "../../../../utils/getDurationStyle";
import { monthName } from "../../../../utils/getMonth";
import { callGetApi } from "../../../../api/axios";

// constants
import { durationStyles } from "../../../../constants/durationStyles";
import { ACCENTCOLOR, SECONDARYCOLOR } from "../../../../shared/colors";
import { ERROR } from "../../../../utils/toastType";
import { ApmContext } from "../../../../utils/apmProvider";

type PendingList = {
  id: string;
  requested_at: string;
  kam_name: string;
  country_code: string;
  name: string;
};

const sortTypes = {
  ascending: "ascending",
  descending: "descending",
};

const sortingColumns = {
  account_name: "name",
  account_manager: "kam_name",
  requested_at: "requested_at",
};

const AccountActivationPendingPage = () => {
  const history = useHistory();
  const dispatch = useContext(ToastContext);
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [pendingActivationList, setPendingActivationList] = useState<
    PendingList[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [sortedRequestedAt, setSortedRequestedAt] = React.useState({
    sortType: sortTypes.descending,
    sortingColumn: "",
  });
  const [filtersList, setFilters] = useState<any[]>([]);
  const [accNameFilter, setAccNameFilter] = useState<String[]>([]);
  const [accManagerFilter, setAccManagerFilter] = useState<String[]>([]);
  const [listOfAccName, setListOfAccName] = useState<String[]>([]);
  const [listOfAccManager, setListOfAccManager] = useState<String[]>([]);

  // pagination states
  const [postPerPage] = useState(20);
  const [activePageNumber, setActivePageNumber] = useState(1);
  const indexOfLastAccount = activePageNumber * postPerPage;
  const indexOfFirstAccount = indexOfLastAccount - postPerPage;

  useEffect(() => {
    setFilters([...accNameFilter, ...accManagerFilter]);
    setActivePageNumber(1);
  }, [accNameFilter, accManagerFilter]);

  useEffect(() => {
    const _getActivationPendingList = async () => {
      try {
        const { data }: any = await callGetApi(
          "/organisations?status=account_activation_requested"
        );
        setLoading(false);
        if (data && data?.length) {
          const list = data
            .map((data: any) => ({
              name: data.name,
              country_code: data.country_code,
              kam_name: data?.kam?.kam_name ?? "NA",
              requested_at: data?.activation_requested_at,
              id: data._id,
            }))
            .sort((a: any, b: any) => {
              return b.requested_at - a.requested_at;
            });

          setPendingActivationList(list);
          getAllAccName(list);
          getAllManagerName(list);
          return;
        }
      } catch (error: any) {
        setLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message:
              error?.response?.data?.err +
              " TraceId is \n" +
              apmTraceId(apmInstance),
          },
        });
      }
    };
    setLoading(true);
    _getActivationPendingList();
  }, [dispatch]);

  const indicator = (filteredArrayName: String[]) => {
    return (
      <div className="px-1">
        <div
          className={`${sharedStyle.indicator} d-flex align-items-center justify-content-center`}
        >
          <p className={`mb-0 ${sharedStyle.indicatorText}`}>
            {filteredArrayName.length}
          </p>
        </div>
      </div>
    );
  };

  // get all acc name
  function getAllAccName(list: Array<Object>) {
    setListOfAccName(list.map((data: any) => data.name));
  }

  // get all acc name
  function getAllManagerName(list: Array<Object>) {
    const filterdArr = list.map((data: any) => data.kam_name);
    setListOfAccManager([...new Set(filterdArr)]);
  }

  // Set filter acc name
  const setAccFilterName = (option: any) => {
    setActivePageNumber(1);
    if (accNameFilter.includes(option)) {
      setAccNameFilter(accNameFilter.filter((data: any) => data !== option));
      return;
    }
    setAccNameFilter([...accNameFilter, option]);
  };

  // Set filter acc manager
  const setAccManagerNameFilter = (option: any) => {
    setActivePageNumber(1);
    if (accManagerFilter.includes(option)) {
      setAccManagerFilter(
        accManagerFilter.filter((data: any) => data !== option)
      );
      return;
    }
    setAccManagerFilter([...accManagerFilter, option]);
  };

  function removeInTableFilter(value: any) {
    if (accNameFilter.includes(value)) {
      return setAccNameFilter(
        accNameFilter.filter((filter) => filter !== value)
      );
    }
    if (accManagerFilter.includes(value)) {
      return setAccManagerFilter(
        accManagerFilter.filter((filter) => filter !== value)
      );
    }
  }

  function clearAllFilters() {
    setAccNameFilter([]);
    setAccManagerFilter([]);
  }

  // sort table on request at date click
  const sortTheRequestedAtDate = (sortType: string) => {
    if (
      sortedRequestedAt.sortType === sortTypes.descending ||
      sortType === sortTypes.descending
    ) {
      setPendingActivationList((prevState) =>
        prevState.sort((a: any, b: any) => {
          return a.requested_at - b.requested_at;
        })
      );
      setSortedRequestedAt((sortedRequestedAt) => ({
        ...sortedRequestedAt,
        sortType: sortTypes.ascending,
        sortingColumn: sortingColumns.requested_at,
      }));
    } else if (
      sortedRequestedAt.sortType === sortTypes.ascending ||
      sortType === sortTypes.descending
    ) {
      setPendingActivationList((prevState) =>
        prevState.sort((a: any, b: any) => {
          return b.requested_at - a.requested_at;
        })
      );
      setSortedRequestedAt((sortedRequestedAt) => ({
        ...sortedRequestedAt,
        sortType: sortTypes.descending,
        sortingColumn: sortingColumns.requested_at,
      }));
    }
  };

  // Sort the column in descending order
  function sortDescending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = -1;
    } else if (leftParam < rightParam) {
      comparison = 1;
    }
    return comparison;
  }

  // Sort the column in ascending order
  function sortAscending(leftParam: any, rightParam: any) {
    let comparison = 0;
    if (leftParam > rightParam) {
      comparison = 1;
    } else if (leftParam < rightParam) {
      comparison = -1;
    }
    return comparison;
  }

  // decide type of sort for specific column
  function sort(type: string, columnName: string) {
    const sortedArray = pendingActivationList.slice().sort((a: any, b: any) => {
      const leftParam = a[columnName]?.toLowerCase();
      const rightParam = b[columnName]?.toLowerCase();

      if (type === sortTypes.ascending) {
        return sortAscending(leftParam, rightParam);
      } else {
        return sortDescending(leftParam, rightParam);
      }
    });

    setPendingActivationList(sortedArray);
  }

  // decide which sorting to apply based upon column name
  function applySorting(columnName: string) {
    if (
      sortedRequestedAt.sortType === sortTypes.descending &&
      sortedRequestedAt.sortingColumn === columnName
    ) {
      setSortedRequestedAt((sortedRequestedAt) => ({
        ...sortedRequestedAt,
        sortType: "",
        sortingColumn: columnName,
      }));
      setPendingActivationList(pendingActivationList);
    } else if (
      sortedRequestedAt.sortType === sortTypes.ascending &&
      sortedRequestedAt.sortingColumn === columnName
    ) {
      setSortedRequestedAt((sortedRequestedAt) => ({
        ...sortedRequestedAt,
        sortType: sortTypes.descending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.descending, columnName);
    } else {
      setSortedRequestedAt((sortedRequestedAt) => ({
        ...sortedRequestedAt,
        sortType: sortTypes.ascending,
        sortingColumn: columnName,
      }));
      sort(sortTypes.ascending, columnName);
    }
  }

  // return data as per filter select
  const filteredAccNameList = pendingActivationList.filter((data: any) => {
    return (
      filtersList.length === 0 ||
      // eslint-disable-next-line
      ((accNameFilter.length === 0 || accNameFilter.includes(data.name)) &&
        (accManagerFilter.length === 0 ||
          accManagerFilter.includes(data.kam_name)))
    );
  });

  return (
    <div className="p-3">
      <div className="pb-2 d-flex align-items-center">
        <div
          id="account-activation-page-goto-home"
          className="d-flex align-items-center"
          onClick={() => history.push("/application/home")}
          style={{ cursor: "pointer" }}
        >
          <div className={Styles.rotate90}>
            <ArrowDown color={SECONDARYCOLOR} />
          </div>
          <p className={`mb-0 ${Styles.back}`}>back</p>
        </div>
      </div>
      <div className="py-4">
        <div className={sharedStyle.card}>
          <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
            <div className="d-flex align-items-center">
              <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                Pending Account Activation
              </p>
            </div>
          </div>
          {filtersList.length > 0 && (
            <div
              className={`p-2 d-flex align-items-center flex-row-reverse flex-wrap ${sharedStyle.filterBack}`}
            >
              <p
                id="account-activation-page-clear-all-filters"
                className={`px-2 mb-0 ${sharedStyle.clearAll}`}
                onClick={() => clearAllFilters()}
              >
                Clear All
              </p>
              {filtersList.map((filter: any, index: number) => {
                return (
                  <div className="p-1" key={index}>
                    <div
                      className={`d-flex align-items-center ${sharedStyle.filterWrapper}`}
                    >
                      <p className={`mb-0 pl-2 ${sharedStyle.filterName}`}>
                        {filter}
                      </p>
                      <div
                        id="account-activation-page-remove-filter"
                        className="px-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeInTableFilter(filter)}
                      >
                        <Close
                          classes={sharedStyle.close}
                          width="8"
                          height="8"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {loading ? (
            <div
              style={{ height: "350px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <Loading loadingColor={ACCENTCOLOR} />
            </div>
          ) : (
            <>
              <div style={{ overflowX: "auto", minHeight: "400px" }}>
                <table style={{ width: "100%" }}>
                  <thead className={sharedStyle.tableHeaderBack}>
                    <tr>
                      <th
                        className={Styles.account_name_column}
                        style={{ padding: "15px 15px 15px 25px" }}
                      >
                        <div className="d-flex align-items-center">
                          <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                            Account Name
                          </p>
                          {accNameFilter.length !== 0 &&
                            indicator(accNameFilter)}
                          <div className="px-2 mb-0">
                            <Sort
                              classes={`${
                                sortedRequestedAt.sortType ===
                                  sortTypes.descending &&
                                sortedRequestedAt.sortingColumn ===
                                  sortingColumns.account_name
                                  ? Styles.rotate
                                  : ""
                              } ${
                                sortedRequestedAt.sortType !== "" &&
                                sortedRequestedAt.sortingColumn ===
                                  sortingColumns.account_name
                                  ? Styles.active
                                  : Styles.sort
                              }`}
                              click={() =>
                                applySorting(sortingColumns.account_name)
                              }
                            />
                          </div>

                          <div className="px-2">
                            <FilterDropdown
                              width="300px"
                              height="200px"
                              optionArray={listOfAccName}
                              filters={accNameFilter}
                              onSelect={(option: any) => {
                                setAccFilterName(option);
                              }}
                              toggleReset={accNameFilter.length !== 0}
                              onReset={() => setAccNameFilter([])}
                            >
                              <div
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                              >
                                <ArrowDown classes={sharedStyle.filterArrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </div>
                      </th>

                      <th
                        className={Styles.country_code}
                        style={{ padding: "15px 15px 15px 25px" }}
                      >
                        <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                          Country
                        </p>
                      </th>
                      <th
                        className={Styles.account_manager_column}
                        style={{ padding: "15px 15px 15px 25px" }}
                      >
                        <div className="d-flex align-items-center">
                          <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                            Account Manager
                          </p>
                          {accManagerFilter.length !== 0 &&
                            indicator(accManagerFilter)}
                          <div className="px-2 mb-0">
                            <Sort
                              classes={`${
                                sortedRequestedAt.sortType ===
                                  sortTypes.descending &&
                                sortedRequestedAt.sortingColumn ===
                                  sortingColumns.account_manager
                                  ? Styles.rotate
                                  : ""
                              } ${
                                sortedRequestedAt.sortType !== "" &&
                                sortedRequestedAt.sortingColumn ===
                                  sortingColumns.account_manager
                                  ? Styles.active
                                  : Styles.sort
                              }`}
                              click={() =>
                                applySorting(sortingColumns.account_manager)
                              }
                            />
                          </div>

                          <div className="px-2">
                            <FilterDropdown
                              width="300px"
                              height="200px"
                              optionArray={listOfAccManager}
                              filters={accManagerFilter}
                              onSelect={(option: any) => {
                                setAccManagerNameFilter(option);
                              }}
                              toggleReset={accManagerFilter.length !== 0}
                              onReset={() => setAccManagerFilter([])}
                            >
                              <div
                                className={`d-flex align-items-center justify-content-center ${Styles.dropdownBack}`}
                              >
                                <ArrowDown classes={sharedStyle.filterArrow} />
                              </div>
                            </FilterDropdown>
                          </div>
                        </div>
                      </th>
                      <th
                        className={`${Styles.date}`}
                        style={{ padding: "15px 15px 15px 25px" }}
                      >
                        <div className="d-flex align-items-center">
                          <p className={`mb-0 ${sharedStyle.tableHeaderText}`}>
                            Requested On
                          </p>
                          <div className="px-2 mb-0">
                            <Sort
                              id="account-activation-page-sort"
                              classes={`${
                                sortedRequestedAt.sortType ===
                                  sortTypes.descending &&
                                sortedRequestedAt.sortingColumn ===
                                  sortingColumns.requested_at
                                  ? Styles.rotate
                                  : ""
                              } ${
                                sortedRequestedAt.sortType !== "" &&
                                sortedRequestedAt.sortingColumn ===
                                  sortingColumns.requested_at
                                  ? Styles.active
                                  : Styles.sort
                              }`}
                              onClick={sortTheRequestedAtDate}
                            />
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredAccNameList.length > 0 ? (
                      filteredAccNameList
                        .slice(indexOfFirstAccount, indexOfLastAccount)
                        .map((account: any, index: number) => {
                          const date = new Date(Number(account.requested_at));
                          const duration = getDuration(date);
                          const style = getDurationStyle(duration, 7, 3);

                          return (
                            <tr
                              key={index}
                              style={
                                index !== filteredAccNameList.length - 1
                                  ? { borderBottom: "1px solid #eceeef" }
                                  : { borderBottom: 0 }
                              }
                            >
                              <td className={Styles.account_name_column}>
                                <Link
                                  to={"/application/accounts/" + account.id}
                                  className={`mb-0 ${Styles.accountName}`}
                                >
                                  {account.name}
                                </Link>
                              </td>
                              <td className={Styles.country_code}>
                                <p
                                  className={`mb-0 ${sharedStyle.tableBodyText}`}
                                >
                                  {account.country_code}
                                </p>
                              </td>
                              <td className={Styles.account_manager_column}>
                                <p
                                  className={`mb-0 ${sharedStyle.tableBodyText}`}
                                >
                                  {account.kam_name}
                                </p>
                              </td>
                              <td className={Styles.date}>
                                {account.requested_at ? (
                                  <div
                                    className={`mb-0 ${Styles.duration}`}
                                    style={
                                      style === durationStyles.DANGER
                                        ? { color: "#d64747" }
                                        : style === durationStyles.WARNING
                                        ? { color: "#f4a63b" }
                                        : { color: "#31b975" }
                                    }
                                  >
                                    {duration}
                                  </div>
                                ) : null}
                                <p
                                  className={`mb-0 ${sharedStyle.tableBodyText}`}
                                >
                                  {account.requested_at
                                    ? `${monthName(
                                        date.getMonth()
                                      )} ${date.getDate()}, ${date.getFullYear()}`
                                    : "NA"}
                                </p>
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <tr
                        style={{ height: "400px" }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <td className="mb-0">No Data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="px-4 py-3 d-flex align-items-center">
                <p className={`mb-0 ${sharedStyle.numberOfRows}`}>
                  {
                    filteredAccNameList.slice(
                      indexOfFirstAccount,
                      indexOfLastAccount
                    ).length
                  }{" "}
                  rows per page
                </p>
                {filteredAccNameList.length > 0 && (
                  <div className="py-2 ml-auto">
                    <Pagination
                      activePageNumber={activePageNumber}
                      total={filteredAccNameList.length}
                      postPerPage={postPerPage}
                      navigateToPage={(value: number) => {
                        setActivePageNumber(value);
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountActivationPendingPage;
