import React from "react";
import Styles from "./checkbox.module.scss";

function Checkbox({ id, checked, disabled = null, label, ...props }: any) {
  return (
    <label className={`${Styles.container} ${disabled ? Styles.disabled : ""}`}>
      <input
        id={id}
        type="checkbox"
        {...props}
        checked={checked}
        disabled={disabled}
      />
      <span id={`${id}`} className={Styles.checkmark}></span>
    </label>
  );
}

export default Checkbox;
