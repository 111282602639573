import React from "react";

// constants
import { MEDIUM } from "../../../../../../shared/buttonSize";

// components
import Button from "../../../../../../shared/button/button";
import ErrorMessage from "../../../../../../shared/error-message/errorMessage";

// functions
import { checkIsEmpty } from "../../../../../../utils/validation";

// styles
import popUpStyles from "../../../../../../shared/popup.module.scss";
import sharedStyle from "../../../../../../shared/sharedStyle.module.scss";
import {
  cancelButtonHoverStyle,
  cancelButtonStyle,
  dangerButtonHoverStyle,
  dangerButtonStyle,
} from "../../../../../../shared/buttonStyles";
import { IdsGenerator } from "../../../../../../utils/idsGenerator";

function RejectReasonModal({
  title,
  onCancel,
  btnName,
  onReject,
  ...rest
}: any) {
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [error, setError] = React.useState("");
  const specialCharRegexp = /[!@#$%^&*()?":{}|<>]/;
  function checkReason() {
    if (checkIsEmpty(reason)) {
      setError("Reason Cannot be empty");
      return false;
    }
    if (specialCharRegexp.test(reason)) {
      setError("Enter a valid reason");
      return false;
    }
    if (reason.length < 50) {
      setError("Reason message should have minimum 50 chars");
      return false;
    }
    return true;
  }

  function onRejectProducts() {
    if (!checkReason()) {
      return;
    }
    const trimmedReason = reason.trim();
    setButtonLoading(true);

    const disableButtonLoading = () => {
      setButtonLoading(false);
    };

    onReject({ ...rest, trimmedReason, disableButtonLoading });
  }

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${popUpStyles.overlay}`}
    >
      <div className={`${popUpStyles.popUpContent}`} style={{ width: "600px" }}>
        <div className={`p-4 ${sharedStyle.cardHeaderBack}`}>
          <p className={`mb-0 ${sharedStyle.cardHeader}`}>{title}</p>
        </div>
        <div className="p-4">
          <div className="pt-2 pb-4">
            <h5 className="mb-0">Please provide a reason</h5>
          </div>
          <div className="form-group">
            <label
              htmlFor="rejectReason"
              className={`mb-0 ${sharedStyle.formLabel}`}
            >
              Reason
            </label>
            <textarea
              id={IdsGenerator({
                prefix: "reject reason modal",
                id: "input",
                sufix: "reason for reject",
              })}
              className={`${sharedStyle.commonInputClass} ${sharedStyle.formControl}`}
              style={{ boxShadow: "none", resize: "none", border: "none" }}
              rows={6}
              onChange={(event) => {
                setReason(event.target.value);
                setError("");
              }}
              onBlur={checkReason}
              maxLength={150}
            ></textarea>
            {error ? (
              <ErrorMessage>{error}</ErrorMessage>
            ) : (
              <p
                className="mb-0 p-2"
                style={{ fontSize: "12px", color: "#999" }}
              >
                Maximum Char {150 - reason.length}
              </p>
            )}
          </div>
        </div>
        <div
          className="py-4 my-2 d-flex align-items-center justify-content-center"
          style={{ borderTop: "1px solid #ECEEEF" }}
        >
          <div className="px-2">
            <Button
              id={IdsGenerator({
                prefix: "reject reason modal",
                id: "click",
                sufix: `${btnName || "cancel"}`,
              })}
              size={MEDIUM}
              style={cancelButtonStyle}
              hoveredStyle={cancelButtonHoverStyle}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </div>
          <div className="px-2">
            <Button
              id={IdsGenerator({
                prefix: "reject reason modal",
                id: "click",
                sufix: `${btnName || "reject"}`,
              })}
              disabled={error !== "" || reason === ""}
              isLoading={buttonLoading}
              size={MEDIUM}
              style={dangerButtonStyle}
              hoveredStyle={dangerButtonHoverStyle}
              onClick={() => onRejectProducts()}
            >
              {btnName || "Reject"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RejectReasonModal;
