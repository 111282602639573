// Hooks
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Component
import InternationalCustomers from "./international-customers/internationalCustomers";
import NationalCustomer from "./national-customers/nationalCustomers";
import KeyValuePair from "../../../../../shared/key-value-pair/keyValuePair";
import Loading from "../../../../../shared/loading/loading";
import { ToastContext } from "../../../../../context/toastContext";

// Styles
import sharedStyle from "../../../../../shared/sharedStyle.module.scss";

// constants
import { ERROR } from "../../../../../utils/toastType";
import { ACCENTCOLOR } from "../../../../../shared/colors";

// Functions
import { callGetApi, callPutApi } from "../../../../../api/axios";
import { IdsGenerator } from "../../../../../utils/idsGenerator";

function KycDetails({ org_status, is_allowed_to_write }: any) {
  const { id }: any = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useContext(ToastContext);
  const [kycDetails, setKycDetails] = useState<any>();
  const [virtualAccountDetails, setVirtualAccountDetails] = useState({
    account_id: "",
    account_number: "",
    bank_name: "",
    ifsc: "",
  });
  const [updatedVirtualAccountDetails, setUpdatedVirtualAccountDetails] =
    useState({
      account_number: "",
      bank_name: "",
      ifsc: "",
    });
  const [
    updatedInternationalVirtualAccountDetails,
    setUpdatedInternationalVirtualAccountDetails,
  ] = useState({
    account_number: "",
    bank_name: "",
    ifsc: "",
  });
  const [country_code, setCountry_code] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [onboardingStatus, setOnboardingStatus] = React.useState("");
  const [bankAccountInfo, setBankAccountInfo] = useState();
  const [gstinInfo, setGstinInfo] = useState();
  const [registrationDocInfo, setRegistrationDocInfo] = useState();
  const [authSignatoryInfo, setAuthSignatoryInfo] = useState();
  const [directorDocInfo, setDirectorDocInfo] = useState();
  useEffect(() => {
    async function getKycDetails() {
      setIsLoading(true);
      try {
        const response: any = await callGetApi(
          `organisations/${id}?data=kyc_details`
        );
        setIsLoading(false);
        const { country_code, kyc, virtual_account, name, onboarding_status } =
          response.data;
        setCountry_code(country_code);
        setBusinessName(name);
        setKycDetails(kyc);
        setOnboardingStatus(onboarding_status);
        setVirtualAccountDetails(virtual_account ?? {});
        // Alert for the edge case where kyc is done before onboarding
        // if (
        //   ["SERVICES_SUBSCRIBED", "ORG_CREATED", "SKIPPED"].includes(
        //     onboarding_status
        //   )
        // )
        //   return dispatch({
        //     type: "ADD_TOAST",
        //     payload: {
        //       id: Math.floor(Math.random() * 100),
        //       type: ERROR,
        //       time: true,
        //       message:
        //         "Test app not yet created, ask customer to complete the signup process.",
        //     },
        //   });
        // else if (onboarding_status === "TEST_APP_CREATED")
        //   return dispatch({
        //     type: "ADD_TOAST",
        //     payload: {
        //       id: Math.floor(Math.random() * 100),
        //       type: ERROR,
        //       time: true,
        //       message: "API Key not yet created",
        //     },
        //   });
      } catch (err: any) {
        setIsLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: err.response.data.err,
          },
        });
      }
    }

    getKycDetails();
  }, [id, dispatch]);
  useEffect(() => {
    if (kycDetails) {
      setBankAccountInfo(kycDetails?.bank_account);
      setDirectorDocInfo(kycDetails?.director_doc);
      setRegistrationDocInfo(kycDetails?.registration_doc);
      setAuthSignatoryInfo(kycDetails?.auth_signatory);
      setGstinInfo(kycDetails?.gstin);
    }
  }, [kycDetails]);

  // To update the onboarding status to ACCOUNT_ACTIVATED after KYC update
  async function onUpdateKYCDetails(value: any) {
    setKycDetails(value);
    if (
      onboardingStatus === "ONBOARDING_COMPLETED" ||
      onboardingStatus === "ACTIVATION_REQUESTED"
    ) {
      setIsLoading(true);
      try {
        await callPutApi(
          `/organisations/${id}?action=org_activate_account`,
          {}
        );
        setIsLoading(false);
      } catch (err: any) {
        setIsLoading(false);
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: err.response.data.err,
          },
        });
      }
    }
  }

  function updatedVirtualAccount(virtualAccountdeatils: any) {
    setUpdatedVirtualAccountDetails(virtualAccountdeatils);
  }

  //International
  function updateInternationalVirtualAccount(
    InternationalvirtualAccountdeatils: any
  ) {
    setUpdatedInternationalVirtualAccountDetails(
      InternationalvirtualAccountdeatils
    );
  }

  return (
    <div>
      {isLoading ? (
        <div
          style={{ height: "50vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loading loadingColor={ACCENTCOLOR} />
        </div>
      ) : (
        <div className="py-3 container-fluid">
          <div className="row">
            <div className="col-12 py-3">
              {country_code === "IN" ? (
                <NationalCustomer
                  bankAccountInfo={bankAccountInfo}
                  registrationDoc={registrationDocInfo}
                  auth_signatory={authSignatoryInfo}
                  gstin={gstinInfo}
                  isLoading={isLoading}
                  org_id={id}
                  org_status={org_status}
                  onUpdateKycDetails={onUpdateKYCDetails}
                  onAddVirtualAccount={(onAddVirtualAccount: any) =>
                    setVirtualAccountDetails(onAddVirtualAccount)
                  }
                  updatedVirtualAccount={updatedVirtualAccount}
                  onboarding_status={onboardingStatus}
                  is_allowed_to_write={is_allowed_to_write}
                />
              ) : (
                <InternationalCustomers
                  directorDoc={directorDocInfo}
                  auth_signatory={authSignatoryInfo}
                  registrationDoc={registrationDocInfo}
                  gstin={gstinInfo}
                  isLoading={isLoading}
                  org_id={id}
                  org_status={org_status}
                  onUpdateKycDetails={onUpdateKYCDetails}
                  updatedInternationalVirtualAccount={
                    updateInternationalVirtualAccount
                  }
                  onAddVirtualAccount={(onAddVirtualAccount: any) =>
                    setVirtualAccountDetails(onAddVirtualAccount)
                  }
                  onboarding_status={onboardingStatus}
                  is_allowed_to_write={is_allowed_to_write}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 py-3">
              <div className={sharedStyle.card}>
                <div className={`px-4 py-3 ${sharedStyle.cardHeaderBack}`}>
                  <div className="d-flex align-items-center">
                    <p className={`mb-0 ${sharedStyle.cardHeader}`}>
                      Virtual Account Details
                    </p>
                  </div>
                </div>
                <div className="p-4 container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <KeyValuePair
                        id={IdsGenerator({
                          prefix: "national customer",
                          id: "value",
                          sufix: "account number",
                        })}
                        className="py-3"
                        label="Account Number"
                        value={
                          virtualAccountDetails.account_number ||
                          virtualAccountDetails.account_id ||
                          updatedVirtualAccountDetails.account_number ||
                          updatedInternationalVirtualAccountDetails.account_number ||
                          "NA"
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <KeyValuePair
                        id={IdsGenerator({
                          prefix: "national customer",
                          id: "value",
                          sufix: "business name",
                        })}
                        className="py-3"
                        label="Business Name"
                        value={businessName ?? "NA"}
                      />
                    </div>
                    <div className="col-md-6">
                      <KeyValuePair
                        id={IdsGenerator({
                          prefix: "national customer",
                          id: "value",
                          sufix: "accoune ifsc",
                        })}
                        className="py-3"
                        label="Account IFSC"
                        value={
                          virtualAccountDetails.ifsc ||
                          updatedVirtualAccountDetails.ifsc ||
                          updatedInternationalVirtualAccountDetails.ifsc ||
                          "NA"
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <KeyValuePair
                        id={IdsGenerator({
                          prefix: "national customer",
                          id: "value",
                          sufix: "bank name",
                        })}
                        className="py-3"
                        label="Bank Name"
                        value={
                          virtualAccountDetails?.bank_name ||
                          updatedVirtualAccountDetails.bank_name ||
                          updatedInternationalVirtualAccountDetails.bank_name ||
                          "NA"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default KycDetails;
