import Styles from "./updateProductModal.module.scss";
import React, { useContext } from "react";
import Button from "../../../../shared/button/button";
import { MEDIUM } from "../../../../shared/buttonSize";
import {
  cancelButtonModernHoverStyle,
  cancelButtonModernStyle,
  primaryButtonHoverStyle,
  primaryButtonStyle,
} from "../../../../shared/buttonStyles";

import Input from "../../../../shared/input/input";
import ErrorMessage from "../../../../shared/error-message/errorMessage";
import { checkIsEmpty } from "../../../../utils/validation";
import { ERROR, SUCCESS } from "../../../../utils/toastType";
import { ToastContext } from "../../../../context/toastContext";
import { callPutApi } from "../../../../api/axios";
import Checkbox from "../../../../shared/checkbox/checkbox";
import Switch from "../../../../shared/toggle-switch/switch";
import { ApmContext } from "../../../../utils/apmProvider";

export default function UpdateProductModal({
  product,
  updateProduct,
  onCancel,
  currentProduct,
  is_allows_to_write,
}: any) {
  const email = localStorage.getItem("email");
  const { traceId, apmInstance, apmTraceId } = useContext(ApmContext);

  const [currentProductData, setCurrentProductData] = React.useState({
    arn: currentProduct.arn,
    name: currentProduct.name,
    auto_approval: currentProduct.auto_approval,
    display: currentProduct.display,
    category: product.name,
    show_while_onboarding: currentProduct.show_while_onboarding,
    default_free_transactions: currentProduct.default_free_transactions ?? 0,
    price_lower_bound: currentProduct.price_lower_bound,
    short_description:
      currentProduct?.short_description ?? "No description available",
    visibility: currentProduct.visibility,
    addons: currentProduct.addons,
    user_name: email,
  });
  const [error, setError] = React.useState({
    name: "",
    defaultFreeTransaction: "",
    shortDescription: "",
    lowerBound: "",
  });
  const [loading, setLoading] = React.useState(false);
  const dispatch = useContext(ToastContext);
  const disabledFiel = true;
  const numberRegexp = /[^\0-9]/;

  function checkNumbers(value: string) {
    if (checkIsEmpty(value)) {
      return "Should not be empty";
    }
    if (Number(value) < 0) {
      return `Amount can not be negative`;
    }
    if (numberRegexp.test(value)) {
      return "Enter a valid amount";
    }
    if (value[0] === "0" && value.length > 1 && value[1] !== ".") {
      return "Enter a valid amount";
    }
    if (!Number.isInteger(+value)) {
      return "No decimal values allowed";
    }
    return "";
  }

  function checkLowerbound(value: string) {
    if (checkIsEmpty(value)) {
      return "Should not be empty";
    }
    if (Number(value) < 0) {
      return `Amount can not be negative`;
    }
    return "";
  }

  async function addNewCategory() {
    setLoading(true);
    try {
      if (
        error.name !== "" ||
        error.defaultFreeTransaction !== "" ||
        error.shortDescription !== "" ||
        error.lowerBound !== ""
      ) {
        dispatch({
          type: "ADD_TOAST",
          payload: {
            id: Math.floor(Math.random() * 100),
            type: ERROR,
            message: "Please enter valid data!",
          },
        });
        setLoading(false);
        return;
      }
      const response: any = await callPutApi("/products", {
        ...currentProductData,
      });
      if (response?.data !== "") {
        updateProduct(currentProductData);
      }

      setLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: SUCCESS,
          message: response.data,
        },
      });
    } catch (err: any) {
      setLoading(false);
      dispatch({
        type: "ADD_TOAST",
        payload: {
          id: Math.floor(Math.random() * 100),
          type: ERROR,
          message:
            err.response.data.err + " TraceId is \n" + apmTraceId(apmInstance),
          copy: apmTraceId(apmInstance),
        },
      });
    }
  }
  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`${Styles.popUpContent} ${Styles.card} px-4`}>
        <div className={`pt-4`}>
          <p className={`${Styles.title}`}>Update Product</p>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <Input
              no_shadow="true"
              type="text"
              id="product-arn"
              miniLabel={true}
              disabled={disabledFiel}
              value={currentProductData.arn}
              labelname="ARN ID"
            />
          </div>

          <div className="col-lg-6 col-sm-12">
            <Input
              no_shadow="true"
              type="text"
              id="product-name"
              miniLabel={true}
              value={currentProductData.name}
              hasError={error.name}
              placeholder="Enter product name"
              labelname="Product Name"
              onChange={(event: any) => {
                const { value } = event.target;
                setCurrentProductData((Category: any) => ({
                  ...Category,
                  name: value,
                }));
                setError((error) => ({
                  ...error,
                  name: `${checkIsEmpty(value) ? "Should not be empty" : ""}`,
                }));
              }}
            />
            {error.name && <ErrorMessage>{error.name}</ErrorMessage>}
          </div>
        </div>

        <div className="row py-2">
          <div className="col-lg-4 col-sm-12">
            <Switch
              negativeLabel="Auto approval"
              miniLabel={true}
              id="switch-approval"
              isDisabled={disabledFiel}
              checked={currentProduct.auto_approval}
              onChange={(event: any) => {
                const { checked } = event.target;
                setCurrentProductData((Category: any) => ({
                  ...Category,
                  auto_approval: checked,
                }));
              }}
            />
          </div>

          <div className="col-lg-4 col-sm-12">
            <div className="pt-2 d-flex align-items-center">
              <div className="pl-2 pr-4">
                <Checkbox
                  id="header"
                  checked={currentProduct.visibility}
                  disabled={disabledFiel}
                  onChange={(event: any) => {
                    const { checked } = event.target;
                    setCurrentProductData((Category: any) => ({
                      ...Category,
                      visibility: checked,
                    }));
                  }}
                />
              </div>
              <div className="px-1">
                <p className={`mb-0 ${Styles.miniLabel}`}>
                  Visible on Dashboard
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-12">
            <div className="pt-2 d-flex align-items-center">
              <div className="pl-2 pr-4">
                <Checkbox
                  id="header"
                  checked={currentProduct.show_while_onboarding}
                  disabled={disabledFiel}
                  onChange={(event: any) => {
                    const { checked } = event.target;
                    setCurrentProductData((Category: any) => ({
                      ...Category,
                      show_while_onboarding: checked,
                    }));
                  }}
                />
              </div>
              <div className="px-1">
                <p className={`mb-0 ${Styles.miniLabel}`}>
                  Show while Onboarding
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="col-lg-3 col-sm-12">
            <Input
              no_shadow="true"
              type="number"
              min={0}
              id="default-free-transactions"
              miniLabel={true}
              value={currentProductData.default_free_transactions}
              hasError={error.defaultFreeTransaction}
              labelname="Default Free Transactions"
              onChange={(event: any) => {
                const { value } = event.target;

                setCurrentProductData((Category: any) => ({
                  ...Category,
                  default_free_transactions: value === "" ? "" : Number(value),
                }));

                setError((error) => ({
                  ...error,
                  defaultFreeTransaction: checkNumbers(value),
                }));
              }}
            />
            {error.defaultFreeTransaction && (
              <ErrorMessage>{error.defaultFreeTransaction}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-3 col-sm-0"></div>

          <div className="col-lg-3 col-sm-12">
            <Input
              no_shadow="true"
              type="number"
              id="lower-bound"
              hasError={error.lowerBound}
              miniLabel={true}
              value={currentProductData.price_lower_bound}
              labelname="Lower Bound"
              onChange={(event: any) => {
                const { value } = event.target;
                setCurrentProductData((Category: any) => ({
                  ...Category,
                  price_lower_bound: value === "" ? "" : Number(value),
                }));
                setError((error) => ({
                  ...error,
                  lowerBound: checkLowerbound(value),
                }));
              }}
            />
            {error.lowerBound && (
              <ErrorMessage>{error.lowerBound}</ErrorMessage>
            )}
          </div>

          <div className="col-lg-3 col-sm-12">
            <Input
              no_shadow="true"
              type="number"
              id="upper-bound"
              autoComplete="off"
              miniLabel={true}
              disabled={disabledFiel}
              labelname="Upper Bound"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Input
              style={{
                minHeight: "40px",
                maxHeight: "100px",
              }}
              no_shadow="true"
              textarea={true}
              rows={3}
              name="New category"
              id="new_category"
              autoComplete="off"
              miniLabel={true}
              value={currentProductData.short_description}
              hasError={error.shortDescription}
              labelname="Short Description"
              onChange={(event: any) => {
                const { value } = event.target;
                setCurrentProductData((Category: any) => ({
                  ...Category,
                  short_description: value,
                }));
                setError((error) => ({
                  ...error,
                  shortDescription: `${
                    checkIsEmpty(value) ? "Should not be empty" : ""
                  }`,
                }));
              }}
            />
            {error.shortDescription && (
              <ErrorMessage>{error.shortDescription}</ErrorMessage>
            )}
          </div>
        </div>

        <div className="py-4 mb-1 d-flex align-items-center justify-content-center">
          <div className="px-2">
            <Button
              id="update-product-modal-cancel"
              size={MEDIUM}
              style={cancelButtonModernStyle}
              hoveredStyle={cancelButtonModernHoverStyle}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </div>
          <div className="px-2">
            <Button
              id="update-product-modal-update"
              size={MEDIUM}
              isLoading={loading}
              disabled={!is_allows_to_write}
              style={primaryButtonStyle}
              hoveredStyle={primaryButtonHoverStyle}
              onClick={() => addNewCategory()}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
